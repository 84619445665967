import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Card, CardBody, Badge } from 'reactstrap';

function Stnk() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);

  const allowPermission = (menu) => {
    return !!(generatePermission('customer', menu) || generatePermission('customer', 'all'));
  };

  const allowList = allowPermission('carall') || allowPermission('carlist');
  const allowFilter = allowPermission('carall') || allowPermission('carsearch');
  const allowShow = allowPermission('carall') || allowPermission('cardetail');
  const allowExport = allowPermission('carall') || allowPermission('carexport');
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['customer_name', 'license_plate', 'status'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const statusOptions = [
    { value: '', label: 'Semua Plat Nomer' },
    { value: 'unverified', label: 'Unverified' },
    { value: 'checking', label: 'Menunggu Verifikasi' },
    { value: 'verified', label: 'Verified' },
  ];

  let dataFilter = [
    {
      id: 'filter_text_license_plate',
      type: 'text',
      name: 'license_plate',
      name_field: 'license_plate',
      value: tempFiltered?.license_plate ?? '',
      placeholder: 'License Plate',
    },
    {
      id: 'filter_text_vin_no',
      type: 'text',
      name: 'vin_no',
      name_field: 'vin_no',
      value: tempFiltered?.vin_no ?? '',
      placeholder: 'Nomor VIN',
    },
    {
      id: 'filter_text_customer_name',
      type: 'text',
      name: 'customer_name',
      name_field: 'customer_name',
      value: tempFiltered?.customer_name ?? '',
      placeholder: 'Customer Name',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: tempFiltered?.status ? statusOptions.filter((type) => type?.value === tempFiltered?.status)?.[0] : '',
      placeholder: 'Status',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Customer Name',
        selector: 'customer.name',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'License Plate',
        selector: 'license_plate',
        minWidth: '150px',
      },
      {
        name: 'Nomor VIN',
        selector: 'vin_no',
        minWidth: '150px',
      },
      {
        name: 'Status',
        cell: (row) => {
          if (row?.stnk_status === 'unverified') {
            return <span className="badge-custom badge-danger">Unverified</span>;
          } else if (row?.stnk_status === 'verified') {
            return <span className="badge-custom badge-green">Verified</span>;
          } else if (row?.stnk_status === 'checking') {
            return <span className="badge-custom badge-custom-grey">Menunggu Verifikasi</span>;
          } else {
            return <span className="badge-custom badge-custom-danger">No Status</span>;
          }
        },
        wrap: true,
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchCustomerCar() {
    setLoading(true);

    const params = getParamData();
    console.log(`params payload`, params);
    try {
      const response = await api.get(`v2/intools/user-cars/`, { params });
      setData(response?.data?.data?.user_cars ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
      handleDataExport(response?.data?.data?.user_cars ?? []);
    } catch (error) {
      console.log(error);
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
    }
  }

  const getParamData = () => {
    let params = {
      license_plate: filtered?.license_plate ?? null,
      vin_no: filtered?.vin_no ?? null,
      customer: filtered?.customer_name ?? null,
      status: filtered?.status ?? null,
      page: page,
      limit: pageLimit,
    };
    console.log(`get param data`, params);
    return params;
  };

  const handleDataExport = (data) => {
    let arrDataExport = [];

    data.forEach((item) => {
      const objDataExport = {
        customer: item?.customer?.name ?? '',
        license_plate: item?.license_plate ?? '-',
        vin_no: item?.vin_no ?? '-',
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = ['customer', 'license_plate', 'vin_no'];
    const headers = ['Customer Name', 'License Plate', 'Nomor VIN'];

    Helper.downloadCSV(dataExport, headers, keys);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetDataFilter();
  };

  const resetDataFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      license_plate: e?.license_plate ?? '',
      vin_no: e?.vin_no ?? '',
      status: e?.status.value ?? '',
      customer_name: e?.customer_name ?? '',
    };

    console.log(`setfiltered`, e);
    setFiltered(tempVal);
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchCustomerCar();
  };

  const handleMethodfetchCustomerCar = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchCustomerCar();
    }
  };

  const resetFilter = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const openCustomerCarDetail = (row) => {
    if (allowShow) {
      history.push(`/stnk/${row.id}`);
    }
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
    console.log(`tempfiltered`, tempFiltered);
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      console.log('url param', filtered);
      history.push(`/stnk/?${urlParam}`);
      fetchCustomerCar();
    }
    resetFilter();
  }, [filtered]);

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchCustomerCar();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetFilter();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <Wrapper title="List Customer Car" className="wrapperContent">
      <Card className="p-3">
        <CardBody className="p-0">
          <Col sm={12} md={12} className="p-0 mt-0 section-action">
            {allowExport && allowList && (
              <Button
                disabled={disabledExport || loading}
                className="button-action primary"
                onClick={handleDownloadCSV}
              >
                {'Export CSV'}
              </Button>
            )}
          </Col>
          {filters?.length !== 0 && allowFilter && (
            <Col sm={12} md={12} className="p-0 mt-2">
              <ActionFilterCustom
                filters={filters}
                loading={loading}
                onApplyFilters={(e) => handleSetFiltered(e)}
                onResetFilters={handleResetFilters}
              ></ActionFilterCustom>
            </Col>
          )}
          <Col sm={12} md={12} className="p-0 mt-2">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={data}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openCustomerCarDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>
    </Wrapper>
  );
}

export default Stnk;
