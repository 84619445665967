import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, FormGroup, Label } from 'reactstrap';
import { map } from 'lodash';
import Loading from '@components/loading/Loading';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import API from '@utils/API';
import APIS3 from '@utils/APIS3';
import qs from 'query-string';
import PlusIcon from '@assets/icons/plus.svg';
import UploadIcon from '@assets/icons/upload.svg';
import TrashIcon from '@assets/icons/trash-v3.svg';
import UploadSPKModal from '../modal/UploadSPKModal';
import moment from 'moment';
import CustomModal from '../modal/CustomModal';
import { Tooltip } from 'react-tooltip';
import SelectField from '@components/field/SelectField';
import Helper from '@helpers/Helper';

const SPKList = (props) => {
  const { handleResultNotification, toggleActiveTab, activetab } = props;
  const api = new API('v2');
  const apiS3 = new APIS3();
  const history = useHistory();

  const [dataApi, setDataApi] = useState([]);
  const [draftData, setDraftData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingATC, setIsLoadingATC] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [hasConfirmationModal, setHasConfirmationModal] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const [year, setYear] = useState({});
  const [month, setMonth] = useState({});

  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();

  const [hasModalUploadSPK, setHasModalUploadSPK] = useState(false);
  const [preDataUploadSPK, setPreDataUploadSPK] = useState({});

  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['search_spk', 'search_booking', 'search_name', 'search_license_plate', 'search_vin_no', 'start_date', 'end_date'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const yearOption = () => {
    const options = Helper.generateYearOptions(
      parseInt(moment().subtract(5, 'y').format('YYYY')),
      parseInt(moment().add(5, 'y').format('YYYY')),
      false
    );
    return options;
  };

  const monthOption = [
    { value: 1, label: 'Januari' },
    { value: 2, label: 'Februari' },
    { value: 3, label: 'Maret' },
    { value: 4, label: 'April' },
    { value: 5, label: 'Mei' },
    { value: 6, label: 'Juni' },
    { value: 7, label: 'Juli' },
    { value: 8, label: 'Agustus' },
    { value: 9, label: 'September' },
    { value: 10, label: 'Oktober' },
    { value: 11, label: 'November' },
    { value: 12, label: 'Desember' },
  ];

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const defaultColumns = [
    {
      name: 'SPK',
      selector: (row) =>
        row?.spk_number ?? (
          <Button
            color="primary"
            size="lg"
            className="btn-add-item d-flex align-items-center px-3 py-1"
            onClick={() => handleToggleUploadSPKFile(row)}
          >
            <img src={UploadIcon} className="mr-2" alt="" width={16} />
            Upload SPK
          </Button>
        ),
      width: '250px',
    },
    {
      name: 'Service Completed',
      selector: (row) => row?.service_complete ?? '-',
      width: '150px',
      wrap: true,
    },
    {
      name: 'Order Completed',
      selector: (row) => row?.order_complete ?? '-',
      width: '150px',
      wrap: true,
    },
    {
      name: 'Booking Code',
      selector: (row) => row?.booking_code ?? '-',
      width: '180px',
      wrap: true,
    },
    {
      name: 'Name',
      selector: (row) => row?.customer?.name ?? '-',
      width: '200px',
      wrap: true,
    },
    {
      name: 'Brand',
      selector: (row) => row?.customer_car?.brand ?? '-',
      width: '150px',
      wrap: true,
    },
    {
      name: 'Type',
      selector: (row) => row?.customer_car?.type ?? '-',
      width: '150px',
      wrap: true,
    },
    {
      name: 'License Plate',
      selector: (row) => row?.customer_car?.license_plate ?? '-',
      width: '120px',
      wrap: true,
    },
    {
      name: 'Nomor VIN',
      selector: (row) => row?.customer_car?.vin_no ?? '-',
      width: '120px',
      wrap: true,
    },
    {
      name: 'KM',
      selector: (row) => row?.customer_car?.mileage ?? '-',
      width: '100px',
      wrap: true,
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: '',
        selector: '',
        wrap: true,
        width: '110px',
        cell: (row) => (
          <>
            <Button
              data-tooltip-id={`upload_spk__atd_${row?.id}`}
              data-tooltip-content={`Tambah data booking ${row?.booking_code} ke draft invoice`}
              color="primary"
              size="sm"
              onClick={() => handleAddBookingToCart(row)}
              disabled={row?.disabled}
              style={{ borderRadius: 100, padding: '0.4rem 0.7rem' }}
            >
              <img src={PlusIcon} alt="" width={12} />
            </Button>
            <Tooltip id={`upload_spk__atd_${row?.id}`} />
          </>
        ),
        left: true,
      },
      ...defaultColumns,
    ],
    []
  );

  const draftColumns = useMemo(
    () => [
      {
        name: '',
        selector: '',
        wrap: true,
        width: '110px',
        cell: (row) => (
          <>
            <Button
              data-tooltip-id={`upload_spk__rtd_${row?.id}`}
              data-tooltip-content={`Hapus data booking ${row?.booking_code} dari draft invoice`}
              color="danger"
              size="sm"
              onClick={() => handleRemoveBookingFromCart(row)}
              disabled={row?.disabled}
              style={{ borderRadius: 100, padding: '0.4rem 0.6rem' }}
            >
              <img src={TrashIcon} alt="" width={16} />
            </Button>
            <Tooltip id={`upload_spk__rtd_${row?.id}`} />
          </>
        ),
        left: true,
      },
      ...defaultColumns,
    ],
    [draftData]
  );

  const noDataComponentSPK = useMemo(() => {
    return <div className="no-record p-4">Tidak Ada Hasil.</div>;
  }, []);

  const noDataComponentDraft = useMemo(() => {
    return (
      <div className="no-record text-center p-4">
        Tidak Ada Hasil. <br />
        Tambahkan booking dari SPK di table atas untuk melihat data.
      </div>
    );
  }, []);

  const dataFilter = [
    {
      id: 'filter_text_search_spk',
      type: 'text',
      name: 'search_spk',
      name_field: 'search_spk',
      value: tempFiltered?.search_spk ?? '',
      placeholder: 'SPK',
    },
    {
      id: 'filter_text_search_booking',
      type: 'text',
      name: 'search_booking',
      name_field: 'search_booking',
      value: tempFiltered?.search_booking ?? '',
      placeholder: 'Booking Code',
    },
    {
      id: 'filter_text_search_name',
      type: 'text',
      name: 'search_name',
      name_field: 'search_name',
      value: tempFiltered?.search_name ?? '',
      placeholder: 'Name',
    },
    {
      id: 'filter_text_search_license_plate',
      type: 'text',
      name: 'search_license_plate',
      name_field: 'search_license_plate',
      value: tempFiltered?.search_license_plate ?? '',
      placeholder: 'License Plate',
    },
    {
      id: 'filter_text_search_vin_no',
      type: 'text',
      name: 'search_vin_no',
      name_field: 'search_vin_no',
      value: tempFiltered?.search_vin_no ?? '',
      placeholder: 'Nomor VIN',
    },
    {
      id: 'filter_text_search_date',
      type: 'date',
      date_range: true,
      name: 'search_date',
      name_field: 'search_date',
      value:
        tempFiltered?.start_date && tempFiltered?.end_date
          ? { start_date: tempFiltered?.start_date, end_date: tempFiltered?.end_date }
          : '',
      placeholder: 'Order Completed',
    },
  ];

  const getAddedBookingId = () => {
    let dt = [];
    draftData?.length > 0
      ? map(draftData, (item) => {
          dt.push(item?.booking_code);
        })
      : [];

    return dt.toString();
  };

  const fetchSPKList = async (searchParams, currentPage, limit) => {
    const start_date = searchParams?.search_date?.start_date
      ? moment(searchParams?.search_date?.start_date).format('YYYY-MM-DD')
      : '';
    const end_date = searchParams?.search_date?.end_date
      ? moment(searchParams?.search_date?.end_date).format('YYYY-MM-DD')
      : '';
    const payload = {
      selected_bookings: getAddedBookingId() ?? '',
      spk: searchParams?.search_spk ?? '',
      name: searchParams?.search_name ?? '',
      booking_code: searchParams?.search_booking ?? '',
      license_plate: searchParams?.search_license_plate ?? '',
      vin_no: searchParams?.search_vin_no ?? '',
      start_date,
      end_date,
      page: currentPage,
      limit: limit ?? 10,
    };
    setLoading(true);

    await api
      .get(`v2/intools/invoices/spk/?${qs.stringify(payload)}`)
      .then((res) => {
        const datas = res.data;

        setDataApi(datas.data);
        setPagination(datas.pagination);
        validateCountPage(datas);
        // setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setFirstLoad(false);
      });
  };

  const validateCountPage = (response) => {
    if (page > response?.pagination?.total_page) {
      setPage(response?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangeSorting = (column, sortDirection) => {
    console.log('handleChangeSorting => ', column, sortDirection);
  };

  const handleChangePage = (e) => {
    setPagination({ ...pagination, page: e });
    setPage(e);
    fetchSPKList(filtered, e, pagination?.limit);
  };

  const handleChangeRowPerPage = (e) => {
    setPagination({ ...pagination, limit: e });
    fetchSPKList(filtered, page, e);
  };

  const handleAddBookingToCart = (item) => {
    let status = true;

    if (item?.spk_number) {
      setIsLoadingATC(true);
      setDraftData((prevData) => {
        map(prevData, (dt) => {
          if (dt?.booking_code !== item?.booking_code && dt?.customer?.name === item?.customer?.name) {
            status = true;
          } else {
            if (dt?.customer?.name !== item?.customer?.name) {
              handleResultNotification({
                status: true,
                type: 'fail',
                message: 'Gagal, nama perusahaan yang dipilih harus sama',
              });
            }
            status = false;
          }
        });

        if (status) {
          return [...prevData, item];
        } else {
          // handleResultNotification({
          //   status: true,
          //   type: 'fail',
          //   message: 'Gagal, menambahkan item booking ke draft, coba sesaat lagi atau hubungi admin.',
          // });
          return [...prevData];
        }
      });

      setIsLoadingATC(false);
    } else {
      const notification = {
        status: true,
        type: 'fail',
        message: 'Gagal, upload SPK untuk menambahkan booking ke draft',
      };

      handleResultNotification(notification);
    }
  };

  const handleRemoveBookingFromCart = (item) => {
    const findIndexById = draftData.findIndex((dt) => dt?.customer?.name === item?.customer?.name);

    if (findIndexById < 0) {
      const notification = {
        status: true,
        type: 'fail',
        message: 'Gagal menghapus item draft, item tidak ditemukan.',
      };

      handleResultNotification(notification);
      return;
    }

    draftData.splice(findIndexById, 1);
    setDraftData([...draftData]);
  };

  const generateBookingsPayload = (items) => {
    return map(items, (item) => {
      return {
        booking_id: item?.booking_id,
        booking_code: item?.booking_code,
        customer_name: item?.customer?.name,
        service_date: item?.service_complete,
        mileage: item?.customer_car?.mileage,
        spk_number: item?.spk_number,
      };
    });
  };

  const handleSubmit = async () => {
    const payload = {
      month: month?.value,
      year: year?.value,
      customer_id: draftData[0].customer.id,
      customer_name: draftData[0].customer.name,
      bookings: generateBookingsPayload(draftData),
    };

    setLoadingSubmit(true);

    await api
      .post('v2/intools/invoices/', payload)
      .then((res) => {
        const data = res.data.data;

        setDraftData([]);
        setLoadingSubmit(false);
        handleToggleConfirmation();
        handleResultNotification({
          status: true,
          type: 'success',
          message: `Berhasil membuat invoice ${data?.invoice_number}`,
        }).then(() => toggleActiveTab('invoice'));
      })
      .catch((err) => {
        const errMessage = err?.response?.data?.error?.message;
        handleResultNotification({
          status: true,
          type: 'failed',
          message: `Gagal membuat invoice, ${errMessage}`,
        });
        setLoadingSubmit(false);
      });
  };

  const setDefaultFilters = async () => {
    setFilters(dataFilter);
  };

  const handleApplyFilters = async (params) => {
    await setFiltered(params);
    fetchSPKList(params, 1);
    setPage(1);
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetDataFilter();
  };

  const resetDataFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      start_date: e?.search_date?.start_date ? moment(e?.search_date?.start_date).format('YYYY-MM-DD 00:00:00') : '',
      end_date: e?.search_date?.end_date ? moment(e?.search_date?.end_date).format('YYYY-MM-DD 23:59:59') : '',
    };
    console.log(`setfiltered`, e);
    setFiltered(tempVal);
  };

  const validateSPKFile = async (file) => {
    const availableFormat = ['jpg', 'png', 'pdf', 'jpeg'];
    const fileSizeError = file?.size > 10000000;
    const fileType = file?.type?.split('/');
    const fileExtentionError = !availableFormat.includes(fileType[1]);
    let status = true;

    let notification = {
      status: true,
      type: 'fail',
      message: 'Gagal, upload SPK untuk menambahkan booking ke draft',
    };

    if (fileSizeError) {
      notification.message = 'Ukuran maksimal file adalah 10MB';
      status = false;
    } else if (fileExtentionError) {
      notification.message = 'Ekstensi file tidak sesuai. ekstensi file yang diperbolehkan adalah jpg/jpeg/png/pdf';
      status = false;
    } else {
      notification.message = '';
      status = true;
    }

    return status;
  };

  const handleToggleConfirmation = () => {
    setHasConfirmationModal(() => {
      const status = !hasConfirmationModal;
      if (status) {
        setConfirmationType(draftData?.length > 0 ? 'create' : 'no data');
      } else {
        setConfirmationType('');
      }

      return status;
    });
  };

  const handleChangeMonth = (e) => {
    setMonth(e);
  };

  const handleChangeYear = (e) => {
    setYear(e);
  };

  const handleToggleUploadSPKFile = (dt) => {
    setHasModalUploadSPK((prevData) => {
      const status = !prevData;

      setPreDataUploadSPK(dt);
      return status;
    });
  };

  const callbackSubmitModalSPK = () => {
    fetchSPKList(filtered, page);
    handleToggleUploadSPKFile({});
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 00:00:00') : '',
        end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 23:59:59') : '',
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      if (activetab === 'spk') {
        fetchSPKList(filtered, page, pagination?.limit);
      }
    }
  }, [filtered]);

  useEffect(() => {
    if (activetab === 'spk') fetchSPKList(filtered, page, pagination?.limit);
  }, [activetab, draftData]);

  useEffect(() => {
    if (activetab !== 'spk') handleResetFilters(), setDraftData([]);
  }, [activetab]);

  useEffect(() => {
    if (!hasConfirmationModal) {
      const year = {
        value: parseInt(moment().format('YYYY')),
        label: parseInt(moment().format('YYYY')),
      };
      const month = monthOption?.filter((item) => item?.value == moment().format('M'));
      setYear(year);
      setMonth(month[0]);
    }
  }, [hasConfirmationModal]);

  useEffect(() => {
    setDefaultFilters();
  }, []);

  useEffect(() => {
    if (page && pagination?.limit) {
      if (!firstLoad && page > pagination?.total_page) fetchSPKList(filtered, page, pagination?.limit);
    }
  }, [page, pagination?.limit]);

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {filters?.length !== 0 && (
              <Col sm={12} md={12} className="p-0 mt-4">
                <ActionFilterCustom
                  filters={filters}
                  loading={isLoading}
                  onApplyFilters={(e) => handleSetFiltered(e)}
                  onResetFilters={() => {
                    handleResetFilters();
                    fetchSPKList();
                  }}
                ></ActionFilterCustom>
              </Col>
            )}

            <Col sm={12} md={12} className="p-0 mt-4">
              <DataTable
                persistTableHead
                highlightOnHover
                sortServer
                noHeader={true}
                // title="List SPK"
                progressPending={isLoading}
                data={dataApi}
                className="table dt-responsive table-custom"
                noDataComponent={noDataComponentSPK}
                progressComponent={<Loading />}
                customStyles={customStylesHeaderTable}
                onSort={handleChangeSorting}
                // onRowClicked={(row) => handleDetailBrand(row)}
                columns={columns}
              />
            </Col>
            <Col sm={12} md={12} className="p-0 mt-2">
              {dataApi?.length > 0 && !isLoading && (
                <CustomPagination
                  page={page}
                  pageLimit={pagination?.limit}
                  totalRows={dataApi?.length ?? 0}
                  totalAllRows={pagination?.total_rows ?? 0}
                  totalPages={pagination?.total_page}
                  handleChangePage={handleChangePage}
                  handleChangeRowPerPage={handleChangeRowPerPage}
                ></CustomPagination>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col sm={12} md={12} className="p-0 mt-4">
              <h3>Invoice Draft</h3>
              <DataTable
                persistTableHead
                highlightOnHover
                // sortServer
                noHeader={true}
                // title="List Added SPK"
                progressPending={isLoadingATC}
                data={draftData}
                className="table dt-responsive table-custom"
                noDataComponent={noDataComponentDraft}
                progressComponent={<Loading />}
                customStyles={customStylesHeaderTable}
                // onSort={handleChangeSorting}
                // onRowClicked={(row) => handleDetailBrand(row)}
                columns={draftColumns}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row>
        <Col className="d-flex justify-content-end">
          <Button color="purple" className="d-flex align-items-center" onClick={handleToggleConfirmation}>
            <img src={PlusIcon} alt="" width={12} className="mr-2" />
            Buat Invoice
          </Button>
        </Col>
      </Row>

      <CustomModal modal={hasConfirmationModal} toggle={handleToggleConfirmation}>
        <div className="align-items-center">
          <span className="body">
            {confirmationType === 'create' ? (
              <div className="text-center">
                <div className="header">Pengaturan</div>
                <div className="my-3 text-left">
                  <span className="text-bold">Nomor Receipt</span>
                </div>
                <div className="content">
                  <FormGroup row>
                    <Label lg={4} className="text-left">
                      Bulan :
                    </Label>
                    <SelectField
                      colWidth={8}
                      name="Bulan"
                      placeholder="month"
                      closeMenuOnSelect={true}
                      value={month}
                      options={monthOption}
                      onChange={handleChangeMonth}
                    />
                  </FormGroup>
                  <FormGroup row>
                    <Label lg={4} className="text-left">
                      Tahun :
                    </Label>
                    <SelectField
                      colWidth={8}
                      name="Tahun"
                      placeholder="year"
                      value={year}
                      options={yearOption()}
                      onChange={handleChangeYear}
                    />
                  </FormGroup>
                </div>
              </div>
            ) : (
              <div className="text-center">Pilih dulu data bookingnya yaa!</div>
            )}
          </span>
          <div className="mt-3 text-center">
            {confirmationType === 'create' ? (
              <>
                <Button color="primary" onClick={handleToggleConfirmation} className="mr-2" disabled={loadingSubmit}>
                  Batalkan
                </Button>
                <Button color="primary" outline onClick={handleSubmit} disabled={loadingSubmit}>
                  Simpan
                </Button>
              </>
            ) : (
              <Button color="primary" onClick={handleToggleConfirmation} className="mr-2" disabled={loadingSubmit}>
                Ok!
              </Button>
            )}
          </div>
        </div>
      </CustomModal>

      <UploadSPKModal
        isOpen={hasModalUploadSPK}
        toggle={handleToggleUploadSPKFile}
        preData={preDataUploadSPK}
        callbackSubmit={callbackSubmitModalSPK}
        handleResultNotification={handleResultNotification}
      />
    </>
  );
};

export default SPKList;
