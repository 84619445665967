import React, { useEffect, useState } from 'react';
import TrashIcon from '@assets/icons/trash.svg';
import PenIcon from '@assets/icons/pen.svg';
import { map } from 'lodash';
import { Alert } from 'reactstrap';
import API from '@utils/API';
import PackageLineItems from './BookingPackageLineItems';
import BookingDetailPackageEditForms from './BookingDetailPackageEditForms';
import BookingDetailItemEditForms from './BookingDetailItemEditForms';
import BookingDetailCustomEditForms from './BookingDetailCustomEditForms';
import newTabIcon from '@assets/icons/new-tab.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BookingCreateDetailOrderCard = (props) => {
  const {
    detailOrder,
    index,
    orderType,
    title,
    items,
    quantity,
    selectedWorkshop,
    selectedCar,
    selectedUser,
    accountType,
    isECommerce,
    packageWorkshopId,
    packageWorkshopIds,
    disableAll,
    onEdit,
    onDelete,
    handleResultNotification,
    markUpNDiscount,
    calculatePromo,
    promoPackageStatusEdit,
    handleChangePromoPackageStatusEdit,
    selectedWorkshops,
    handlePriceChange,
    overrideList,
  } = props;

  const api = new API('v2');
  const [categories, setCategories] = useState([]);
  const [hasEditPackage, setHasEditPackage] = useState(false);
  const [hasEditItems, setHasEditItems] = useState(false);
  const [hasEditCustom, setHasEditCustom] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [selectedDataOrderType, setSelectedDataOrderType] = useState();
  const [selectedDataIndex, setSelectedDataIndex] = useState();

  const getCategories = async (type) => {
    await api
      .get(`v2/intools/categories/inventory/?type=${type}&workshop_id=${selectedWorkshop?.id ?? ''}`)
      .then((res) => {
        const transformedData = map(res.data.data, (item) => {
          return {
            value: item.category_id ?? item.id,
            label: item.name,
          };
        });

        setCategories(transformedData);
      })
      .catch((err) => console.log(err));
  };

  const handleOnEdit = (value, orderType, index) => {
    setSelectedDataIndex(index);
    setSelectedDataOrderType(orderType);
    setSelectedData(value);

    if (!value?.formType) getCategories(orderType == 'package' ? 'package' : 'items');
    setHasEditItems((orderType == 'product' || orderType == 'service') && !value?.formType ? true : false);
    setHasEditCustom((orderType == 'product' || orderType == 'service') && value?.formType ? true : false);
    setHasEditPackage(orderType == 'package' ? true : false);
  };

  const toogleEdit = () => {
    setSelectedDataIndex();
    setSelectedDataOrderType();
    setSelectedData();
    if (hasEditPackage) setHasEditPackage(false);
    if (hasEditItems) setHasEditItems(false);
    if (hasEditCustom) setHasEditCustom(false);
  };

  const handleChangeDetailOrder = (value) => {
    onEdit(selectedDataOrderType, selectedDataIndex, value);
  };

  const isShowEdit =
    selectedWorkshops <= 1 && selectedWorkshop && (orderType == 'package' && !packageWorkshopIds.includes(selectedWorkshop?.id )) && orderType == 'package'
      ? false
      : true;

  return (
    <React.Fragment>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between my-2">
          <div className="d-flex flex-column">
            <span style={{ fontSize: 14, fontWeight: 700 }}>{title}</span>

            <div>
              {quantity ? (
                <span className="text-muted">{`${quantity?.formatMoneyString ?? Number(quantity)}x `}</span>
              ) : null}
              {items?.type !== 'package' ? (
                <span>
                  {items?.name?.attr?.is_fbo ? (
                    <span className="dropdown-tag">FBO</span>
                  ) : items?.name?.attr?.is_wi ? (
                    <span className="dropdown-tag__price-origin">WI</span>
                  ) : (
                    <span className="dropdown-tag__price-origin no-wi">NO WI</span>
                  )}
                  {`${items?.name?.label ?? items?.name?.display_name}`}
                  {!items?.name?.is_compatible && <PackageLineItems items={items} />}
                </span>
              ) : (
                map(items?.package_details, (item) => (
                  <div className="d-flex flex-column">
                    <span>
                      {items?.name?.attr?.is_fbo ? (
                        <span className="dropdown-tag">FBO</span>
                      ) : items?.name?.attr?.is_wi ? (
                        <span className="dropdown-tag__price-origin">WI</span>
                      ) : (
                        <span className="dropdown-tag__price-origin no-wi">NO WI</span>
                      )}
                      {item?.value?.name}
                      {!item?.value?.is_compatible && <PackageLineItems items={item} />}
                    </span>
                  </div>
                ))
              )}
            </div>
            <span>
              {items?.promoData?.campaign_id && (
                <span className="text-bold">
                  Kode Promo: {items?.promoData?.voucher_code ?? ''}{' '}
                  <span className="promo-name">({items?.promoData?.campaign_name ?? '-'})</span>
                  <span>
                    <Link to={`/campaigns/campaign/detail/${items?.promoData?.campaign_id ?? 411}`} target="_blank">
                      <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                    </Link>
                  </span>
                </span>
              )}
            </span>
          </div>
          <div className="d-flex flex-row">
            {isShowEdit && selectedWorkshop && selectedWorkshops.length <= 1 && (
              <img
                src={PenIcon}
                alt=""
                style={{ padding: 8, border: '1px solid #D6D8E7', borderRadius: 4, height: 'fit-content' }}
                className="cursor-pointer mr-1"
                onClick={() => handleOnEdit(items, orderType, index)}
              />
            )}

            <img
              src={TrashIcon}
              alt=""
              style={{ padding: 8, border: '1px solid #D6D8E7', borderRadius: 4, height: 'fit-content' }}
              className="cursor-pointer"
              onClick={() => onDelete(orderType, index)}
            />
          </div>
        </div>
        {selectedWorkshops.length <= 1 &&
          selectedWorkshop &&
          !packageWorkshopIds?.includes(selectedWorkshop?.id ) &&
          orderType == 'package' && <Alert color="danger">Paket tidak sesuai workshop</Alert>}
      </div>

      {selectedWorkshops.length <= 1 && selectedWorkshop && (
        <>
          <BookingDetailPackageEditForms
            selectedData={selectedData}
            modal={hasEditPackage}
            categories={categories}
            selectedWorkshop={selectedWorkshop}
            selectedCar={selectedCar}
            toggle={toogleEdit}
            selectedUser={selectedUser}
            handleChangeDetailOrder={handleChangeDetailOrder}
            calculatePromo={calculatePromo}
            promoPackageStatusEdit={promoPackageStatusEdit}
            handleChangePromoPackageStatusEdit={handleChangePromoPackageStatusEdit}
          ></BookingDetailPackageEditForms>
          <BookingDetailItemEditForms
            overrideList={overrideList}
            handlePriceChange={handlePriceChange}
            selectedData={selectedData}
            modal={hasEditItems}
            categories={categories}
            selectedWorkshop={selectedWorkshop}
            selectedCar={selectedCar}
            selectedUser={selectedUser}
            accountType={accountType}
            isECommerce={isECommerce}
            disableAll={disableAll}
            toggle={toogleEdit}
            handleChangeDetailOrder={handleChangeDetailOrder}
            handleResultNotification={handleResultNotification}
            markUpNDiscount={markUpNDiscount}
            calculatePromo={calculatePromo}
            promoPackageStatusEdit={promoPackageStatusEdit}
            handleChangePromoPackageStatusEdit={handleChangePromoPackageStatusEdit}
          ></BookingDetailItemEditForms>
          <BookingDetailCustomEditForms
            overrideList={overrideList}
            handlePriceChange={handlePriceChange}
            selectedData={selectedData}
            modal={hasEditCustom}
            selectedUser={selectedUser}
            selectedWorkshop={selectedWorkshop}
            detailOrder={detailOrder}
            disableAll={disableAll}
            toggle={toogleEdit}
            handleChangeDetailOrder={handleChangeDetailOrder}
            markUpNDiscount={markUpNDiscount}
          ></BookingDetailCustomEditForms>
        </>
      )}
    </React.Fragment>
  );
};

export default BookingCreateDetailOrderCard;
