import React from 'react'
import InputField from '@components/field/InputField';
import { Controller, useFieldArray } from 'react-hook-form';
import { Card, CardBody, CardHeader, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Helper from '@helpers/Helper';
import ChevronUp from '@assets/icons/chevron-up.svg';
import ChevronDown from '@assets/icons/chevron-down.svg';


const PackageDataDetailProductCustomerPrice = ({ 
    watch,
    control,
    productFormId,
    setValue,
    getValues,
    setError,
    disableForm,
    loadingSumbit,
    errorSectionForm,
    componentIndex,
    productIndex,
    fieldKeyName,
    keyClassIdentifier,
    collapseName,
    formState: { errors },
}) => {
    const getClassSectionError = (param) => {
    const errorProduct = errorSectionForm.filter((val) => val == param);
    if (errorProduct?.length !== 0) {
        return 'out-group-item-invalid';
    } else {
        return 'out-group-item';
    }
    };

    const watchForm = (name) => {
        return watch(name, false);
      };

    const { fields, append, remove } = useFieldArray({
        control,
        name: `components[${componentIndex}].products[${productIndex}].${fieldKeyName}`,
    });

    const showHideProduct = (index) => {
        const is_show = getValues(`components[${componentIndex}].products[${index}].is_show_${fieldKeyName}`);
        setValue(`components[${componentIndex}].products[${index}].is_show_${fieldKeyName}`, !is_show);
      };

    const validationPrice = (value, label) => {
        const msgError = label + ' max Rp 99.999.999';
        let validation = {
          valid: value?.formatMoney > 99999999 ? false : true,
          errorMsg: value?.formatMoney > 99999999 ? msgError : '',
        };
        return validation;
      };

    const validationWsInventory = (value, label) => {
        const formatPrice = value?.price?.formatMoneyString;
        const msgError = label + 'workshop inventory tidak ditemukan'; 
        let validation = {
            valid: true,
            errorMsg: '',
        };
        if (formatPrice === '-') {
            validation = {
                valid: false,
                errorMsg: msgError,
            }
            return validation;  
        }
        if (fieldKeyName === 'workshop_price_config'){
            const wsInventory = value?.support_data?.workshop_inventory_id;
            if (wsInventory === null || wsInventory === undefined) {
                validation = {
                    valid: false,
                    errorMsg: msgError,
                }
                return validation;
            }
        }   

        return validation;
    };

    const setErrorMessage = (nameFiled, type, message) => {
        setError(nameFiled, { type: type, message: message }, { shouldFocus: true });
    };

    return (
        <>
            <br />
            <ListGroup key={`${keyClassIdentifier}-${productFormId}`}>
                <ListGroupItem
                    key={`${keyClassIdentifier}-${productFormId}`}
                    className={getClassSectionError(`components[${componentIndex}]products[${productIndex}]`)}
                >
                    <div className="fly-title" onClick={() => showHideProduct(productIndex)}>
                        {collapseName}
                    <img
                        src={
                        watchForm(`components[${componentIndex}].products[${productIndex}].is_show_${fieldKeyName}`) ? ChevronUp : ChevronDown
                        }
                        alt=""
                    />
                    </div>
                    {
                        watchForm(`components[${componentIndex}].products[${productIndex}].is_show_${fieldKeyName}`) && 
                        (
                        <>
                        {fields.map((field, priceIndex) => {
                            return(
                                <div className="in-group-item" key={field?.id}>
                                    <Controller
                                        name={`components[${componentIndex}].products[${productIndex}].${fieldKeyName}[${priceIndex}]`}
                                        rules={{
                                            validate: () => {
                                              const value = getValues(`components[${componentIndex}].products[${productIndex}].${fieldKeyName}[${priceIndex}].price`)
                                              const validation = validationPrice(
                                                value,
                                                field?.key_name
                                              );
                                            //   const validationInventory = validationWsInventory(value, field?.key_name);
                                            //   return validation?.valid || validation?.errorMsg || validationInventory?.valid || validationInventory?.errorMsg;
                                              return validation?.valid || validation?.errorMsg
                                            },
                                          }}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return  (
                                                <Row className="mb-3">
                                                    <Label lg={3}>
                                                        {value?.key_name}
                                                    </Label>
                                                    <InputField
                                                        colWidth={9}
                                                        inputType={'text'}
                                                        disabled={disableForm || loadingSumbit}
                                                        placeholder={'Price'}
                                                        value={value?.price?.formatMoneyString ?? ''}
                                                        invalid={error?.message ? true : false}
                                                        errorMessage={error?.message ?? ''}
                                                        onChange={(e) => {
                                                            // Convert input value
                                                            let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                                    
                                                            // Validate Price
                                                            const validation = validationPrice(convertMoney, value?.key_name);
                                                            // const validationInventory = validationWsInventory(value, value?.key_name);
                                                    
                                                            // Set Error in Form if Validation Fails
                                                            setErrorMessage(
                                                                `components[${componentIndex}].products[${productIndex}].${fieldKeyName}[${priceIndex}]`,
                                                                { type: 'manual', message: validation?.errorMsg }
                                                            );

                                                            // setErrorMessage(
                                                            //     `components[${componentIndex}].products[${productIndex}].${fieldKeyName}[${priceIndex}]`,
                                                            //     { type: 'manual', message: validationInventory?.errorMsg }
                                                            // );

                                                            // Create Updated Object
                                                            const newObj = { 
                                                                key_name: value?.key_name,
                                                                price: convertMoney,
                                                                support_data: value?.support_data,
                                                            };

                                                            // Update Form State Properly
                                                            setValue(
                                                            `components[${componentIndex}].products[${productIndex}].${fieldKeyName}[${priceIndex}]`,
                                                            newObj,
                                                            );
                                                    
                                                            // Ensure `onChange` is also properly triggered
                                                            onChange(newObj);
                                                        }}
                                                    />
                                                </Row>
                                            )
                                        }
                                    }
                                    />
                                </div>
                            )
                        })}
                        </>
                        )
                    }

                </ListGroupItem>
            </ListGroup>
        </>
    );
};  

export default PackageDataDetailProductCustomerPrice;