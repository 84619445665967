import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import Loading from '@components/loading/Loading';
import DetailField from '@components/field/DetailField';
import StnkBadge from '@components/badge/StnkBadge';
import Wrapper from '@components/wrapper/Wrapper';
import NoData from '@components/other/NoData';
import CarHealth from './carhealth/CarHealth';
import API from '@utils/API';
import map from 'lodash/map';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import { customStylesHeaderTable } from '@utils/Constants';
import userPermission from '@utils/userPermission';
import NoImage from '@assets/images/no-image.jpeg';
import moment from 'moment';
import NewTabIcon from '@assets/icons/new-tab.svg';

function StnkDetail() {
  const history = useHistory();
  const { generatePermission } = userPermission();
  const { id } = useParams();
  const api = new API('v2');

  const [data, setData] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [imageLightbox, setImageLightbox] = useState('');
  const [allowEdit, setAllowEdit] = useState(false);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Customer Car',
      active: false,
      url: '/stnk',
    },
    {
      id: 2,
      label: 'Detail Customer Car',
      active: true,
      url: '',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        cell: (row) => (row?.created_at ? moment(row?.created_at).format('MMM DD YYYY , HH:mm') : '-'),
        selector: (row) => row?.created_at,
        sortable: true,
        sortField: 'created_at',
        wrap: true,
        minWidth: '160px',
      },
      {
        name: 'Action',
        selector: (row) => row?.operation,
        cell: (row) => row?.operation,
        sortable: true,
        sortField: 'operation',
        wrap: true,
        minWidth: '25px',
      },
      {
        name: 'Created by',
        selector: (row) => row?.created_by,
        cell: (row) => row?.created_by,
        sortable: true,
        sortField: 'created_by',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Notes After',
        selector: (row) => row?.note,
        cell: (row) => row?.note,
        sortable: true,
        sortField: 'note',
        minWidth: '350px',
      },
    ],
    [false]
  );

  useEffect(() => {
    loadStnkDetail(id);
  }, []);

  const fetchLogHistory = async (userCarId) => {
    setLoadingHistory(true);
    try {
      const res = await api.get(`v2/intools/activity-logs/user-car/${userCarId}?limit=${pageLimit}&page=${page}`);
      if (+res?.status === 200) {
        const data = res?.data;
        setDataTable(data?.data);
        setTotalRows(data?.pagination?.total_rows);
        setTotalPages(data?.pagination?.total_page);
        setLoadingHistory(false);
      }
    } catch (err) {
      console.log('err', err);
      setLoadingHistory(false);
    }
  };

  const openStnkEdit = (id) => {
    if (allowEdit) {
      history.push(`/stnk/${id}/car-detail/update`);
    }
  };

  const permissionEdit = () => {
    const isEdit =
      (generatePermission('customer', 'caredit') || generatePermission('customer', 'carall')) &&
      data?.stnk_status == 'unverified'
        ? true
        : false;
    const isVerification =
      generatePermission('customer', 'carverification') || generatePermission('customer', 'carall');
    const editAllow = isEdit || isVerification ? true : false;

    setAllowEdit(editAllow);
  };

  async function loadStnkDetail() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/user-cars/${id}`);
      setData(response?.data?.data ?? null);
      setLoading(false);
    } catch (error) {
      setData(null);
      setLoading(false);
    }
  }

  const handleShowLightbox = (img) => {
    setImageLightbox(img);
    setShowLightbox(true);
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  useEffect(() => {
    if (data?.id) {
      fetchLogHistory(data?.id);
    }
  }, [data?.id, page, pageLimit]);

  useEffect(() => {
    permissionEdit();
  }, [data]);

  const previewImageSNTK = (
    <Row>
      <Col lg={6}>
        {data?.stnk?.url ? (
          <div className="galeri-container">
            <div className="box" onClick={() => handleShowLightbox(data?.stnk?.url)}>
              <img src={data?.stnk?.url} />
            </div>
          </div>
        ) : (
          <div className="galeri-container">
            <div className="box">
              <img src={NoImage} />
            </div>
          </div>
        )}
      </Col>
    </Row>
  );

  const previewImagesCar = (
    <Row>
      <Col lg={6}>
        {data?.car_images.length !== 0 ? (
          <div className="galeri-container">
            {map(data?.car_images, (item) => {
              return (
                <div className="box" onClick={() => handleShowLightbox(item?.url)}>
                  <img src={item?.url} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="galeri-container">
            <div className="box">
              <img src={NoImage} />
            </div>
          </div>
        )}
      </Col>
    </Row>
  );

  // render main content page
  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        {showLightbox && <Lightbox mainSrc={imageLightbox} onCloseRequest={() => setShowLightbox(false)} />}
        <Wrapper title="Detail Customer Car" breadcrumbData={breadcrumb}>
          <Row>
            <Col md={6} sm={12}>
              <Card>
                <CardHeader className="bg-primary text-white mt-0">Car Details</CardHeader>
                <CardBody>
                  <DetailField title="Status" value={<StnkBadge status={get(data, 'stnk_status')} />} />
                  <DetailField
                    title="Mobil"
                    value={`${data?.car_specification?.car_model?.brand?.name} ${
                      data?.car_specification?.car_model?.model_name
                    } - ${data?.fuel ?? ''}`}
                  />
                  <DetailField title="Transmisi" value={get(data, 'transmission.label')} />
                  {data?.customer?.fleet_customer && (
                    <DetailField
                      title="Tags"
                      value={get(data, 'tags')?.length > 0 ? get(data, 'tags')?.join(', ') : '-'}
                    />
                  )}
                  <DetailField title="License Plate" value={get(data, 'license_plate')} />
                  <DetailField title="Year" value={get(data, 'year')} />
                  <DetailField title="Vin No" value={get(data, 'vin_no')} />
                  <DetailField title="Membership" value={get(data, 'membership')} />
                  <DetailField
                    title="Expired Membership"
                    value={
                      get(data, 'membership_expired_at')
                        ? moment(get(data, 'membership_expired_at')).format('DD/MM/YYYY')
                        : '-'
                    }
                  />
                  <DetailField title="STNK" value={previewImageSNTK} />
                  <DetailField title="Mobil" value={previewImagesCar} />
                  {allowEdit && (
                    <Button
                      color="primary"
                      className="mr-2 mb-3 float-right"
                      onClick={() => openStnkEdit(get(data, 'id'))}
                    >
                      Edit
                    </Button>
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="bg-primary text-white mt-0">Car Log History</CardHeader>
                <CardBody>
                  <Col sm={12} md={12} className="p-0 mt-4">
                    <DataTable
                      clearSelectedRows={loading}
                      persistTableHead
                      highlightOnHover
                      sortServer
                      noHeader={true}
                      progressPending={loading}
                      data={dataTable}
                      className="table dt-responsive table-custom"
                      noDataComponent={<NoData />}
                      progressComponent={<Loading />}
                      customStyles={customStylesHeaderTable}
                      columns={columns}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-2">
                    {dataTable?.length >= 1 && !loading && (
                      <CustomPagination
                        page={page}
                        pageLimit={pageLimit}
                        totalRows={dataTable?.length ?? 0}
                        totalAllRows={totalRows}
                        totalPages={totalPages}
                        handleChangePage={handleChangePage}
                        handleChangeRowPerPage={handleChangeRowPerPage}
                        short
                      />
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} sm={12}>
              <Card>
                <CardHeader className="bg-primary text-white mt-0">Customer Detail</CardHeader>
                <CardBody>
                  <DetailField title="ID" value={get(data, 'customer.id')} />
                  <DetailField 
                    title="Name" 
                    value={
                      <>
                        {get(data, 'customer.name')}
                        <a
                          href={`/customer/${get(data, 'customer.id')}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img className="button-pen ml-2" src={NewTabIcon} />
                        </a>
                      </>
                    } 
                    />
                  <DetailField title="Email" value={get(data, 'customer.email')} />
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="bg-primary text-white mt-0">Car Model</CardHeader>
                <CardBody>
                  <DetailField title="Brand" value={get(data, 'car_specification.car_model.brand.name')} />
                  <DetailField title="Model" value={get(data, 'car_specification.car_model.model_name')} />
                  <DetailField title="Tyre" value={get(data, 'car_specification.tyre')} />
                  <DetailField title="Variant" value={get(data, 'car_specification.variant')} />
                  <DetailField title="Fuel Type" value={get(data, 'fuel')} />
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="bg-primary text-white mt-0">Car Health</CardHeader>
                <CardBody>{data && <CarHealth stnkID={id}></CarHealth>}</CardBody>
              </Card>
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default StnkDetail;
