import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import SelectField from '@components/field/SelectField';
import Loading from '@components/loading/Loading';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import API from '@utils/API';
import { find, isEmpty, map } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import PreviewIcon from '@assets/icons/preview.svg';
import DownloadIcon from '@assets/icons/download.svg';
import TrashIcon from '@assets/icons/trash-v3.svg';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';
import PDFMake from 'pdfmake/build/pdfmake';
import PDFFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import Helper from '@helpers/Helper';
import CustomModal from '../modal/CustomModal';
import { Tooltip } from 'react-tooltip';
import ConfigDocumentModal from '../modal/ConfigDocumentModal';

PDFMake.vfs = PDFFonts.pdfMake.vfs;

const InvoiceList = (props) => {
  const { handleResultNotification, activetab } = props;
  const api = new API('v2');
  const { id } = useParams();
  const history = new useHistory();

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [activeAction, setActiveAction] = useState('');
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [invoiceStatusOptions, setInvoiceStatusOptions] = useState([]);
  const [changedStatusInvoiceData, setChangedStatusInvoiceData] = useState({});
  const [selectedStatusInvoice, setSelectedStatusInvoice] = useState({});
  const [loadingChangeInvoiceStatus, setLoadingChangeInvoiceStatus] = useState(false);
  const [hasChangeStatusModal, setHasChangeStatusModal] = useState(false);
  const [hasModalDeleteConfirmation, setHasModalDeleteConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [modalDownload, setModalDownload] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedListInvoice, setSelectedListInvoice] = useState([]);

  const statusOptions = [
    {
      id: 1,
      label: 'Created',
      order: 1,
      value: 1,
    },
    {
      id: 2,
      label: 'Ready to Send',
      order: 2,
      value: 2,
    },
    {
      id: 4,
      label: 'Sent to Courier',
      order: 4,
      value: 4,
    },
    {
      id: 3,
      label: 'Received By Customer',
      order: 3,
      value: 3,
    },
  ];
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['search_invoice', 'search_name', 'search_receipt'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const customExpandableComponentStyles = {
    cells: {
      style: {
        fontWeight: '400 !important',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'No.',
        selector: (row) => row?.sequent_number ?? '-',
        width: '50px',
      },
      {
        name: 'No. Summary Invoice',
        selector: (row) => row?.invoice_number ?? '-',
        sortable: true,
        sortField: 'no_invoice',
        wrap: true,
      },
      {
        name: 'Nama',
        selector: (row) => row?.customer?.name ?? '-',
        wrap: true,
      },
      {
        name: 'Status',
        selector: (row) => (
          <div className="d-flex flex-column">
            <span>{row?.status}</span>
            {row?.status?.toLowerCase() !== 'sent' && (
              <Button
                color="link"
                size="sm"
                style={{
                  textDecoration: 'underline',
                  fontWeight: 600,
                  padding: 0,
                  width: 'fit-content',
                }}
                onClick={(e) => handleToggleChangeStatusModal(e, row)}
              >
                Change Status
              </Button>
            )}
          </div>
        ),
      },
      {
        name: 'Action',
        selector: (row) => (
          <span className="section-action">
            {row?.invoice_status?.label === 'Sent' ? null : (
              <>
                <Button
                  data-tooltip-id={`delete_btn__${row?.id}`}
                  data-tooltip-content={`Delete Invoice ${row?.invoice_number ?? '-'}`}
                  color="danger"
                  onClick={(e) => handleToggleDeleteConfirmation(e, row, 'item')}
                  style={{ color: '#fff' }}
                >
                  {actionLoading && activeAction === 'delete' ? (
                    <Spinner size={'sm'} />
                  ) : (
                    <img src={TrashIcon} alt="" width={16} />
                  )}
                </Button>
                <Tooltip id={`delete_btn__${row?.id}`} />
              </>
            )}
            <Button
              data-tooltip-id={`download_btn__${row?.id}`}
              data-tooltip-content={`Download Invoice ${row?.invoice_number ?? '-'}`}
              color="primary"
              className="mr-1"
              onClick={(e) => handleDownloadDocs(e, row)}
            >
              {actionLoading && activeAction === 'download' && row?.id === invoiceLoading ? (
                <Spinner size={'sm'} />
              ) : (
                <img src={DownloadIcon} alt="" width={16} />
              )}
            </Button>
            <Button
              data-tooltip-id={`preview_btn__${row?.id}`}
              data-tooltip-content={`Preview Invoice ${row?.invoice_number ?? '-'}`}
              color="primary"
              className="mr-1"
              onClick={(e) => handlePreviewDocs(e, row)}
            >
              {actionLoading && activeAction === 'preview' && row?.id === invoiceLoading ? (
                <Spinner size={'sm'} />
              ) : (
                <img src={PreviewIcon} alt="" width={16} />
              )}
            </Button>
            <Button
              color="button-action add"
              className="mr-1"
              disabled={actionLoading}
              onClick={(e) => handleShowModalDownload(e, row)}
            >
              <span className="mr-2">Dokumen Gabungan</span>
              {actionLoading && activeAction === 'generated' && row?.id == selectedInvoice?.id ? (
                <span>
                  <Spinner color="light" size="sm">
                    Loading...
                  </Spinner>
                </span>
              ) : (
                <img src={DownloadIcon} alt="" width={16} />
              )}
            </Button>
            <Tooltip id={`preview_btn__${row?.id}`} />
            <Tooltip id={`download_btn__${row?.id}`} />
          </span>
        ),
        wrap: true,
        center: true,
        width: '400px',
      },
    ],
    [data, actionLoading, activeAction]
  );

  const subColumns = useMemo(
    () => [
      {
        name: '',
        width: '48px',
      },
      {
        name: 'Booking Code',
        selector: (row) => row?.booking_code ?? '-',
      },
      {
        name: 'Name',
        selector: (row) => row?.customer_name ?? '-',
      },
      {
        name: 'Service Date',
        selector: (row) => row?.service_date ?? '-',
      },
      {
        name: 'KM',
        selector: (row) => row?.mileage ?? '-',
      },
      {
        name: 'No. Receipt',
        selector: (row) => row?.receipt_number ?? '-',
        wrap: true,
      },
      {
        name: 'Action',
        selector: (row) =>
          row?.invoice_status?.label === 'Sent' ? null : (
            <>
              <Button
                data-tooltip-id={`delete_booking_btn__${row?.booking_code}`}
                data-tooltip-content={`Delete Booking Code ${row?.booking_code ?? '-'}`}
                color="danger"
                onClick={(e) => {
                  handleToggleDeleteConfirmation(e, row, 'subItem');
                }}
                style={{ color: '#fff' }}
              >
                <img src={TrashIcon} alt="" width={16} />
              </Button>
              <Tooltip id={`delete_booking_btn__${row?.booking_code}`} />
            </>
          ),
        center: true,
        wrap: true,
      },
    ],
    [data]
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const dataFilter = [
    {
      id: 'filter_text_search_invoice',
      type: 'text',
      name: 'search_invoice',
      name_field: 'search_invoice',
      value: tempFiltered?.search_invoice ?? '',
      placeholder: 'No. Summary Invoice',
    },
    {
      id: 'filter_text_search_name',
      type: 'text',
      name: 'search_name',
      name_field: 'search_name',
      value: tempFiltered?.search_name ?? '',
      placeholder: 'Nama Perusahaan',
    },
    {
      id: 'filter_text_search_receipt',
      type: 'text',
      name: 'search_receipt',
      name_field: 'search_receipt',
      value: tempFiltered?.search_receipt ?? '',
      placeholder: 'No. Receipt',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
  ];

  const fetchInvoiceList = async (searchParams, currentPage, limit) => {
    const payload = {
      invoice_number: searchParams?.search_invoice ?? '',
      customer_name: searchParams?.search_name ?? '',
      status: searchParams?.status?.id ?? '',
      booking_code: searchParams?.search_receipt ?? '',
      page: currentPage,
      limit: limit ?? 10,
    };
    setLoading(true);

    await api
      .get(`/v2/intools/invoices/?${qs.stringify(payload)}`)
      .then((res) => {
        const datas = res.data.data;
        const transformedDatas = map(datas, (item, index) => {
          const sequent_number = index + 1;

          const modifyBookingDetails = map(item?.bookings, (subItem) => {
            return {
              ...subItem,
              invoice_id: item?.id,
              invoice_number: item?.invoice_number,
              invoice_status: item?.invoice_status,
              temp_bookings_data: item?.bookings,
            };
          });

          return {
            ...item,
            sequent_number,
            status: item?.invoice_status?.label,
            bookings: modifyBookingDetails,
          };
        });
        setData(transformedDatas);
        setPagination(res.data.pagination);
        validateCountPage(res);
        // setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setFirstLoad(false);
      });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const fetchStatusList = async () => {
    await api
      .get('v2/intools/invoices/invoice_status/')
      .then((res) => {
        const data = res?.data?.data;
        const dataOptions = data.map((item) => ({
          ...item,
          value: item?.id,
        }));
        setInvoiceStatusOptions(data);
      })
      .catch((err) =>
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal mendapatkan data, reload halaman dalam beberapa saat lagi atau hubungi admin`,
        })
      );
  };

  const handleChangeSorting = (column, sortDirection) => {
    console.log('handleChangeSorting => ', column, sortDirection);
  };

  const handleChangePage = (e) => {
    setPagination({ ...pagination, page: e });
    setPage(e);
    fetchInvoiceList(filtered, e, pagination?.limit);
  };

  const handleChangeRowPerPage = (e) => {
    setPagination({ ...pagination, limit: e });
    fetchInvoiceList(filtered, page, e);
  };

  const handleClickRow = (item) => {
    history.push(`/invoice/inv/${item?.id}`);
  };

  const ExpanableComponent = ({ data, ...props }) => (
    <DataTable
      {...props}
      data={data?.bookings}
      noHeader
      columns={subColumns}
      // onRowClicked={(row) => handleClickUserRow(row?.id)}
      customStyles={customExpandableComponentStyles}
    />
  );

  const setDefaultFilters = async () => {
    // const filteredSearch = qp?.search ?? '';

    // let paramsFiltered = {};
    // if (qp?.search) await assign(paramsFiltered, { search: filteredSearch });
    // let filterData = dataFilter;
    // filterData[0].value = filteredSearch;

    // setFilters(filterDatar);
    setFilters(dataFilter);
    // setFiltered(paramsFiltered);
  };

  const handleApplyFilters = async (params) => {
    await setFiltered(params);
    fetchInvoiceList(params);
    // if (!isEmpty(qp)) {
    //   const payload = {
    //     ...qp,
    //     search: params?.search,
    //   };

    //   history.push({ search: qs.stringify(payload) });
    // }
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetDataFilter();
  };

  const resetDataFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      search_invoice: e?.search_invoice ?? '',
      search_name: e?.search_name ?? '',
      search_receipt: e?.search_receipt ?? '',
    };
    console.log(`setfilteredd`, e);
    setFiltered(tempVal);
  };

  const fetchInvoiceDetail = async (item, action) => {
    setActiveAction(action);
    setActionLoading(true);
    let tempData = {};
    await api
      .get(`v2/intools/invoices/${item?.id}/summary/`)
      .then((res) => {
        tempData = res.data.data;
        return res?.data?.data;
      })
      .catch((err) => {
        handleResetFilters({
          status: true,
          type: 'fail',
          message: 'Gagal, upload SPK untuk menambahkan booking ke draft',
        });
        return {};
      })
      .finally(() => {
        setActionLoading(false);
        setActiveAction('');
      });

    return { ...tempData, invoice_number: item?.invoice_number };
  };

  const receiptToNumber = (receipt_number) => {
    const romanValues = { I: 1, II: 2, III: 3, IV: 4, V: 5, VI: 6, VII: 7, VIII: 8, IX: 9, X: 10 };
    const receiptNumberSection = receipt_number.split('-');
    const receiptNumberStartSection = receiptNumberSection?.length !== 2 ? [] : receiptNumberSection[0].split(' ');
    const receiptNumberEndSection = receiptNumberSection?.length !== 2 ? [] : receiptNumberSection[1].split('/');
    const incrementValue =
      receiptNumberStartSection?.length !== 2
        ? 0
        : Number.isInteger(Number(receiptNumberStartSection[1]))
        ? Number(receiptNumberStartSection[1])
        : 0;
    const romanValue = receiptNumberEndSection?.length !== 2 ? 0 : romanValues[receiptNumberEndSection[0]] ?? 0;
    const yearValue =
      receiptNumberEndSection?.length !== 2
        ? 0
        : Number.isInteger(Number(receiptNumberEndSection[1]))
        ? Number(receiptNumberEndSection[1])
        : 0;

    return incrementValue + romanValue + yearValue;
  };

  const sortingInvoiceByReceiptNumber = (data) => {
    if (data?.invoices && data?.invoices?.length !== 0) {
      data?.invoices.sort((a, b) => receiptToNumber(a?.receipt_number) - receiptToNumber(b?.receipt_number));
    }

    return data;
  };

  const handleGeneratePDF = (e, action, rowItem) => {
    const checkNewPage = (value) => {
      const getDecimal = +value / 12;
      const decimalPart = getDecimal - Math.floor(getDecimal);
      if (decimalPart > 0.6 && getDecimal > 1) {
        return true;
      } else {
        return false;
      }
    };

    fetchInvoiceDetail(rowItem, action).then((dt) => {
      sortingInvoiceByReceiptNumber(dt);
      const totalBookingsLength = dt?.invoices?.length ?? 0;

      const invoiceSummaryList = map(dt?.invoices, (item, index) => {
        const sequent_number = index + 1;
        return [
          { text: sequent_number, style: 'textCenter' },
          item?.spk_number ?? '-',
          Helper.arrayToString(item?.booking_codes) ?? '-',
          item?.receipt_number ?? '-',
          item?.license_plate ?? '-',
          item?.vehicle_type ?? '-',
          { text: `Rp ${Helper.formatMoney(item?.payment_amount ?? 0)}`, style: 'textRight' },
        ];
      });

      const invoiceSummary = [
        ['No.', 'No. SPK', 'Booking Code', 'No. Receipt', 'License Plate', 'Car Type', 'Subtotal'],
        ...invoiceSummaryList,
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
        ],
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: 'Payment Amount', style: 'textRight', colSpan: 2 },
          { text: '', border: [false] },
          { text: `Rp ${Helper.formatMoney(dt?.total_pay)}`, style: 'textRight' },
        ],
      ];

      const bookingList = map(dt?.invoices, (item, index) => {
        const totalDocsLength = dt?.invoices?.length ?? 0;
        const totalItemsLength = item?.items?.length ?? 0;
        const itemTypeSort = item?.items.sort((a, b) =>
          a.item_type > b.item_type ? 1 : b.item_type > a.item_type ? -1 : 0
        );

        const itemsSort = itemTypeSort.sort((a, b) =>
          a.type > b.type && a.booking_package_id == null && b.booking_package_id == null
            ? 1
            : b.type > a.type && a.booking_package_id == null && b.booking_package_id == null
            ? -1
            : 0
        );

        let rowspan = 1;
        const idOccurrences = {};
        // Loop through the array and count occurrences of each booking_package_id
        itemsSort.forEach((item) => {
          const bookingPackageId = item?.booking_package_id;
          if (item?.booking_package_id !== null) {
            if (idOccurrences[bookingPackageId]) {
              idOccurrences[bookingPackageId]++;
            } else {
              idOccurrences[bookingPackageId] = 1;
            }
          }
        });

        const idOccurrencesArray = Object.entries(idOccurrences).map(([id, count]) => ({ id: parseInt(id), count }));
        const booking = map(itemsSort, (subitem, subindex) => {
          const sequent_number = subindex + 1;
          let product_type =
            subitem?.item_type === 'package' ? 'package' : subitem?.type ? Helper.convertItemType(subitem.type) : '-';
          let service_desc = '';
          if (subitem?.item_type === 'package') {
            idOccurrencesArray.forEach((item) => {
              if (subitem?.booking_package_id === item.id) {
                rowspan = item.count;
              }
            });
            service_desc = [
              { text: subitem?.name },
              { text: subitem?.voucher_label ? ` (${subitem?.voucher_label})` : '', italics: !!subitem?.voucher_label },
              { text: subitem?.note ? '\nKeterangan : ' + (subitem?.note ?? '') : '' },
              { text: `\n${subitem?.quantity} x Rp ${Helper.formatMoney(subitem?.total_base_price)}` },
              { text: `\n\n${Helper.convertItemType(subitem.type)}` },
              { text: `\n${subitem?.item_desc}` },
            ];
          } else {
            rowspan = 1;
            service_desc = [
              { text: subitem?.name ?? '-' },
              { text: subitem?.voucher_label ? ` (${subitem?.voucher_label})` : '', italics: !!subitem?.voucher_label },
              { text: subitem?.remark ? '\nKeterangan : ' + (subitem?.remark ?? '') : '' },
              {
                text: `\n${subitem?.quantity} x Rp ${Helper.formatMoney(+subitem?.base_price ?? 0)}`,
              },
            ];
          }
          return [
            { text: sequent_number, style: 'textCenter' },
            {
              text:
                product_type?.length > 1
                  ? product_type?.charAt(0)?.toUpperCase() + product_type?.slice(1)
                  : product_type,
              rowSpan: rowspan,
              alignment: 'center',
            },
            {
              text: service_desc,
            },
            { text: `Rp ${Helper.formatMoney(subitem?.discount)}`, style: 'textRight' },
            { text: `Rp ${Helper.formatMoney(subitem?.voucher)}`, style: 'textRight' },
            { text: `Rp ${Helper.formatMoney(subitem?.sub_total)}`, style: 'textRight' },
          ];
        });

        const bookingTotal = () => {
          let dataTotal = [
            [
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
            ],
            [
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: 'Total', alignment: 'right', colSpan: 2 },
              { text: '', border: [false] },
              { text: `Rp ${Helper?.formatMoney(item?.grand_total ?? 0)}`, alignment: 'right' },
            ],
            [
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: 'DPP', alignment: 'right', colSpan: 2 },
              { text: '', border: [false] },
              { text: `Rp ${Helper?.formatMoney(item?.dpp ?? 0)}`, alignment: 'right' },
            ],
            [
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: 'PPN', alignment: 'right', colSpan: 2 },
              { text: '', border: [false] },
              { text: `Rp ${Helper?.formatMoney(item?.tax ?? 0)}`, alignment: 'right' },
            ],
            [
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: 'PPH', alignment: 'right', colSpan: 2 },
              { text: '', border: [false] },
              { text: `Rp ${Helper?.formatMoney(item?.service_tax ?? 0)}`, alignment: 'right' },
            ],
            [
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: '', border: [false] },
              { text: 'Payment Amount', alignment: 'right', colSpan: 2 },
              { text: '', border: [false] },
              { text: `Rp ${Helper?.formatMoney(item?.payment_amount ?? 0)}`, alignment: 'right' },
            ],
          ];

          return dataTotal;
        };

        return [
          {
            table: {
              body: [
                ['No. Invoice', ':', item?.receipt_number],
                ['Booking Code', ':', Helper.arrayToString(item?.booking_codes)],
              ],
            },
            style: 'tableStyle',
            layout: 'noBorders',
            margin: [0, 0, 0, 16],
          },
          {
            table: {
              body: [
                [
                  {
                    table: {
                      widths: [75, 2, 120],
                      body: [
                        [{ text: 'Customer Detail', style: { bold: true } }, '', ''],
                        ['Name', ':', item?.customer_name ?? '-'],
                        ['Car Type', ':', item?.vehicle_type ?? '-'],
                        ['License Plate', ':', item?.license_plate ?? '-'],
                        ['Nomor Rangka/VIN', ':', item?.vin_no ?? '-'],
                        ['Contact Number', ':', item?.contact_number ?? '-'],
                        ['KM Service', ':', item?.mileage ?? '-'],
                      ],
                    },
                    style: 'tableStyle',
                    layout: 'noBorders',
                    margin: [0, 0, 16, 0],
                  },
                  {
                    table: {
                      widths: [80, 2, 180],
                      body: [
                        [{ text: 'Payment Detail', style: { bold: true } }, '', ''],
                        ['SPK No.', ':', item?.spk_number ?? '-'],
                        ['Payment Method', ':', item?.payment_method],
                        ['Order Completed', ':', item?.order_completed ?? '-'],
                      ],
                    },
                    style: 'tableStyle',
                    layout: 'noBorders',
                  },
                ],
              ],
            },
            style: 'tableStyle',
            layout: 'noBorders',
            margin: [0, 0, 0, 16],
          },
          { text: 'Service Detail', style: 'fontSize10', bold: 'true' },
          {
            table: {
              widths: [25, 50, 175, 70, 70, 70],
              body: [['No. ', 'Type', 'Service Description', 'Discount', 'Voucher', 'Subtotal'], ...booking],
            },
            style: 'tableStyle',
            margin: [0, 16],
          },
          {
            table: {
              widths: [24, 50, '*', 70, 80, 85],
              body: [...bookingTotal()],
            },
            style: 'tableStyle',
            margin: [0, 16],
            pageBreak: totalItemsLength > 12 ? 'before' : '',
          },
          {
            text: 'for transfer payment method, please make your payment to BCA PT. Oto Klix Indonesia: 0352-988-918',
            style: 'fontSize10',
            pageBreak: totalItemsLength % 12 == 0 ? 'before' : '',
          },
          {
            text: ' \nTim Finance \nPT Oto Klix Indonesia',
            style: 'fontSize10',
            alignment: 'center',
            margin: [0, 64],
            pageBreak: totalDocsLength === index + 1 ? '' : 'after',
          },
        ];
      });

      const pdfJson = {
        info: {
          title: `Invoice ${dt?.invoice_number}`,
          author: 'PT Oto Klix Indonesia',
          subject: `Invoice ${dt?.invoice_number}`,
          keywords: `Invoice ${dt?.invoice_number}`,
        },
        content: [
          {
            image: 'logo',
            width: 100,
            margin: [0, 0, 0, 8],
          },
          {
            text: 'PT Oto Klix Indonesia',
            style: {
              alignment: 'center',
              fontSize: 12,
            },
            margin: [0, 0, 0, 4],
            bold: true,
          },
          {
            text:
              'Jl. Radio Dalam Raya No.22, RT.8/RW.4, Gandaria Utara, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12140',
            style: {
              alignment: 'center',
              fontSize: 10,
            },
            bold: true,
          },
          {
            table: {
              body: [
                ['Bill to', ':', dt?.customer?.name],
                ['', '', dt?.customer?.address ?? '-'],
                ['Nomor Tanda Terima', ':', dt?.invoice_number ?? '-'],
                ['Date', ':', moment().format('DD MMMM YYYY')],
              ],
            },
            style: 'tableStyle',
            margin: [0, 16],
            layout: 'noBorders',
          },
          {
            table: {
              widths: [20, 110, 90, 60, 60, 50, '*'],
              body: invoiceSummary,
            },
            style: 'tableStyle',
            margin: [0, 16],
          },
          {
            text: 'Please settle your payment to BCA PT Oto Klix Indonesia : 0352-988-918',
            style: 'fontSize10',
            margin: [0, 80, 0, 8],
            pageBreak: checkNewPage(totalBookingsLength) ? 'before' : '',
          },
          {
            table: {
              widths: ['auto', 200, 'auto'],
              heights: [80, 'auto', 'auto'],
              body: [
                [
                  { text: 'PT Oto Klix Indonesia', style: 'textCenter' },
                  '',
                  { text: dt?.customer?.name, style: 'textCenter' },
                ],
                [{ text: '', style: 'textCenter' }, '', { text: '_____________________', style: 'textCenter' }],
                [
                  { text: '(Tim Finance)', style: 'textCenter' },
                  '',
                  { text: '(_____________________)', style: 'textCenter' },
                ],
              ],
            },
            style: 'tableStyle',
            margin: [0, 16],
            layout: 'noBorders',
          },
          {
            text: 'Catatan:',
            style: 'fontSize10',
          },
          {
            type: 'dashed',
            ul: [
              'Setelah ditandatangani, mohon agar dapat discan dan dikirim ke christa.natalia@otoklix.com dan finance-hq@otoklix.com. Apabila ada pertanyaan lebih lanjut, silakan menghubungi kami melalui email tersebut.',
              'Total Faktur di atas sudah dipotong Pph 23 khusus yang berkaitan dengan layanan. Bukti potong mohon diberikan setiap bulan melalui email tax@otoklix.com.',
              'Mohon melakukan pembayaran sesuai dengan tagihan di atas.',
            ],
            style: 'fontSize10',
            pageBreak: 'after',
          },
          ...bookingList,
        ],
        images: {
          logo: BASE64_OTOKLIX_LOGO,
        },
        styles: {
          tableStyle: {
            border: '',
            fontSize: 10,
          },
          textCenter: {
            alignment: 'center',
          },
          textRight: {
            alignment: 'right',
          },
          fontSize10: {
            fontSize: 10,
          },
        },
        footer: function (currentPage, pageCount) {
          return [
            {
              text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
              alignment: 'center',
              bold: true,
              color: '#d6d8e7',
              fontSize: 10,
            },
          ];
        },
      };

      const pdfGenerator = PDFMake.createPdf(pdfJson);
      if (action === 'preview') {
        pdfGenerator.open();
      } else if (action === 'download') {
        const status = find(invoiceStatusOptions, { id: 2 });
        pdfGenerator.download(`Invoice ${dt?.invoice_number}.pdf`);
      }
    });
  };

  const fetchConfigDocList = async (data) => {
    setActiveAction('generated');
    setActionLoading(true);
    setSelectedInvoice(data);

    await api
      .get(`v2/intools/invoices/${data?.id}/document_configs/`)
      .then((res) => {
        let response = res.data.data;
        setSelectedData(response);
        setModalDownload(true);
        setActiveAction('');
        setActionLoading(false);
      })
      .catch((err) => {
        setActiveAction('');
        setActionLoading(false);
        setSelectedInvoice({});
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal mendapatkan data, reload halaman dalam beberapa saat lagi atau hubungi admin`,
        });
      });
  };

  const handleShowModalDownload = (e, row) => {
    fetchConfigDocList(row);
  };

  const handleCloseModalDownload = () => {
    setModalDownload(false);
    setSelectedData([]);
  };

  const handleChangeDataConfig = (e) => {
    setSelectedData(e);
  };

  const handlePreviewDocs = (e, row) => {
    setInvoiceLoading(row?.id);
    handleGeneratePDF(e, 'preview', row);
  };

  const handleDownloadDocs = (e, row) => {
    setInvoiceLoading(row?.id);
    handleGeneratePDF(e, 'download', row);
  };

  const handleDeleteDocs = async (e, row) => {
    let payload = {};

    if (confirmationType === 'item') {
      payload = {
        invoices_id: row?.id,
      };

      fetchDeleteInvoice(e, payload, row);
      return;
    } else if (confirmationType === 'subItem') {
      payload = {
        invoice_details: generateInvoiceDetailIdList(row?.invoice_detail_id, row?.temp_bookings_data),
      };

      fetchDeleteBooking(e, payload, row);
      return;
    }
  };

  const fetchDeleteInvoice = async (e, payload, row) => {
    await api
      .delete(`v2/intools/invoices/`, { data: { ...payload } })
      .then((res) => {
        handleResultNotification({
          status: true,
          type: 'success',
          message: `Sukses menghapus invoice ${row?.invoice_number}`,
        }).then(() => {
          fetchInvoiceList(filtered);
          handleToggleDeleteConfirmation(e, {}, '');
          handleResultNotification({
            status: true,
            type: 'success',
            message: `Berhasil menghapus invoice ${row?.invoice_number}`,
          });
          const status = find(invoiceStatusOptions, { id: 4 });
          // handleChangeSubmitInvoiceStatus(e, { status, invoice_id: row?.id });
        });
      })
      .catch((err) => {
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal menghapus invoice ${row?.invoice_number}, coba lagi dalam beberapa saat`,
        });
      });
  };

  const fetchDeleteBooking = async (e, payload, row) => {
    await api
      .put(`v2/intools/invoices/${row?.invoice_id}/summary/`, payload)
      .then((res) => {
        fetchInvoiceList(filtered);
        handleToggleDeleteConfirmation(e, {}, '');
        handleResultNotification({
          status: true,
          type: 'success',
          message: `Berhasil menghapus booking ${row?.booking_code}, pada invoice ${row?.invoice_number}`,
        });
      })
      .catch((err) => {
        if (err?.response)
          handleResultNotification({
            status: true,
            type: 'fail',
            message: `Gagal menghapus booking ${row?.booking_code}, pada invoice ${row?.invoice_number}, coba lagi dalam beberapa saat`,
          });
      });
  };

  const generateInvoiceDetailIdList = (id, tempdt) => {
    return map(tempdt, (item) => {
      return {
        invoice_detail_id: item?.invoice_detail_id,
        is_deleted: item?.invoice_detail_id === id,
      };
    });
  };

  const handleChangeInvoiceStatus = (value) => {
    // Hack order system of Invoice Status
    let valueLabel = +value?.id;
    if (value?.label == 'Received By Customer') {
      valueLabel = +value?.id + 2;
    }

    if (+selectedStatusInvoice?.id > valueLabel) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: `Tidak dapat mengubah ke status sebelumnya`,
      });
    } else {
      let newState = {
        ...changedStatusInvoiceData,
        status: value?.label,
      };
      setChangedStatusInvoiceData(newState);
    }
  };

  const handleChangeSubmitBulkInvoiceStatus = async (statusId, list) => {
    setLoadingChangeInvoiceStatus(true);
    const payload = {
      invoice_ids: list,
      invoice_status_id: statusId,
    };

    await api
      .put(`v2/intools/invoices/status`, payload)
      .then((res) => {
        handleResultNotification({
          status: true,
          type: 'success',
          message: `Sukses merubah status`,
        });
        fetchInvoiceList(filtered, page);
        setLoadingChangeInvoiceStatus(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        const errRes = err?.response?.data?.error?.message ?? '';
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal ! ${errRes}`,
        });
        setLoadingChangeInvoiceStatus(false);
      });
  };

  const handleChangeBulkInvoiceStatus = (e) => {
    if (+selectedListInvoice.length > 0) {
      const mappingDataListInvoice = selectedListInvoice.map((item) => +item?.id);
      handleChangeSubmitBulkInvoiceStatus(e?.id, mappingDataListInvoice);
    } else {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: `Pilih minimal 1 data untuk melakukan update status`,
      });
    }
  };

  const handleOnSelectedRow = (e) => {
    let tempSelectedList = [];
    if (e?.selectedRows) {
      tempSelectedList = [...e?.selectedRows];
      setSelectedListInvoice(tempSelectedList);
    }
  };

  const handleChangeSubmitInvoiceStatus = async (e, dt) => {
    setLoadingChangeInvoiceStatus(true);
    const payload = {
      new_status_id: dt?.status?.id ?? findStatusByLabel?.id,
    };

    await api
      .post(`v2/intools/invoices/${dt?.invoice_id ?? changedStatusInvoiceData?.id}/update_status/`, payload)
      .then((res) => {
        fetchInvoiceList(filtered, page);
        if (isEmpty(dt)) handleToggleChangeStatusModal(e, {});
        setLoadingChangeInvoiceStatus(false);
      })
      .catch((err) => {
        setLoadingChangeInvoiceStatus(false);
        const errRes = err?.response?.data?.error?.message ?? '';
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal ! ${errRes}`,
        });
      });
  };

  const handleToggleChangeStatusModal = (e, row) => {
    e.stopPropagation();
    setSelectedStatusInvoice(row?.invoice_status);
    setHasChangeStatusModal(() => {
      const newStatus = !hasChangeStatusModal;
      if (!newStatus) {
        setChangedStatusInvoiceData({});
      } else {
        setChangedStatusInvoiceData(row);
      }

      return newStatus;
    });
  };

  const handleToggleDeleteConfirmation = (e, row, type) => {
    if (e) e.stopPropagation();
    setConfirmationType(type);
    setHasModalDeleteConfirmation(() => {
      const newStatus = !hasModalDeleteConfirmation;

      setChangedStatusInvoiceData(row);
      return newStatus;
    });
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      if (activetab === 'invoice') {
        fetchInvoiceList(filtered, page, pagination?.limit);
      }
    }
    //resetBookings();
  }, [filtered]);

  useEffect(() => {
    if (activetab === 'invoice') {
      handleResetFilters();
      fetchStatusList();
      fetchInvoiceList(filtered, page, pagination?.limit);
    }
  }, [activetab]);

  useEffect(() => {
    setDefaultFilters();
  }, []);

  useEffect(() => {
    if (page && pagination?.limit) {
      if (!firstLoad && page > pagination?.total_page) fetchInvoiceList(filtered);
    }
  }, [page, pagination?.limit]);

  const findStatusByLabel = find(invoiceStatusOptions, { label: changedStatusInvoiceData?.status });
  const findStatusById = find(invoiceStatusOptions, { id: changedStatusInvoiceData?.status?.id });
  const availableInvoiceStatusOptions = find(invoiceStatusOptions, { id: 3 });

  return (
    <>
      <Card>
        <CardBody>
          <Row className="justify-content-end">
            <SelectField
              colWidth={2}
              disabled={loadingChangeInvoiceStatus}
              placeholder="Bulk Update Status"
              customFull={true}
              customControlColorTextSelected="#000000"
              value={findStatusByLabel}
              closeMenuOnSelect={true}
              options={statusOptions}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.id}
              onChange={handleChangeBulkInvoiceStatus}
              blurInputOnSelect={true}
            />
          </Row>
          <Row>
            {filters?.length !== 0 && (
              <Col sm={12} md={12} className="p-0 mt-4">
                <ActionFilterCustom
                  filters={filters}
                  loading={isLoading}
                  onApplyFilters={handleSetFiltered}
                  onResetFilters={() => {
                    handleResetFilters();
                    fetchInvoiceList();
                  }}
                ></ActionFilterCustom>
              </Col>
            )}

            <Col sm={12} md={12} className="p-0 mt-4">
              <DataTable
                persistTableHead
                highlightOnHover
                sortServer
                noHeader
                selectableRows
                selectableRowsNoSelectAll={false}
                onSelectedRowsChange={(e) => {
                  handleOnSelectedRow(e);
                }}
                // title="List SPK"
                progressPending={isLoading}
                data={data}
                className="table dt-responsive table-custom invoice-data-table"
                noDataComponent={noDataComponent}
                progressComponent={<Loading />}
                customStyles={customStylesHeaderTable}
                onSort={handleChangeSorting}
                // onRowClicked={(row) => handleClickRow(row)}
                columns={columns}
                expandableRows
                expandableRowExpanded={(row) => row.default_expanded}
                expandableRowsComponent={<ExpanableComponent columns={columns} />}
                expandableInheritConditionalStyles
              />
            </Col>
            <Col sm={12} md={12} className="p-0 mt-2">
              {data?.length > 0 && !isLoading && (
                <CustomPagination
                  page={page}
                  pageLimit={pagination?.limit}
                  totalRows={data?.length ?? 0}
                  totalAllRows={pagination?.total_rows ?? 0}
                  totalPages={pagination?.total_page}
                  handleChangePage={handleChangePage}
                  handleChangeRowPerPage={handleChangeRowPerPage}
                ></CustomPagination>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal
        className="modal-dialog-centered"
        isOpen={hasChangeStatusModal}
        toggle={(e) => handleToggleChangeStatusModal(e, {})}
      >
        <ModalHeader>Ubah Status Invoice {changedStatusInvoiceData?.invoice_number}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup row>
                <Label lg={3}>Invoice Status</Label>
                <SelectField
                  colWidth={9}
                  disabled={loadingChangeInvoiceStatus}
                  value={findStatusByLabel}
                  closeMenuOnSelect={true}
                  options={statusOptions}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.id}
                  onChange={handleChangeInvoiceStatus}
                  blurInputOnSelect={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            outline
            onClick={(e) => handleToggleChangeStatusModal(e, {})}
            disabled={loadingChangeInvoiceStatus}
          >
            Batal
          </Button>
          <Button color="primary" onClick={handleChangeSubmitInvoiceStatus} disabled={loadingChangeInvoiceStatus}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>

      <CustomModal
        modal={hasModalDeleteConfirmation}
        toggle={(e) => handleToggleDeleteConfirmation(e, {}, confirmationType)}
      >
        <div className="d-flex flex-column align-items-center">
          <span style={{ fontSize: 16 }} className="text-center">{`Yakin ingin menghapus ${
            confirmationType === 'item' ? 'invoice' : 'booking'
          } ${
            confirmationType === 'item'
              ? changedStatusInvoiceData?.invoice_number
              : changedStatusInvoiceData?.booking_code
          }?`}</span>
          <div className="mt-3">
            <Button
              color="primary"
              onClick={(e) => handleToggleDeleteConfirmation(e, {}, confirmationType)}
              className="mr-2"
              disabled={loadingChangeInvoiceStatus}
            >
              Batal
            </Button>
            <Button
              color="danger"
              onClick={(e) => handleDeleteDocs(e, changedStatusInvoiceData)}
              disabled={loadingChangeInvoiceStatus}
              outline
            >
              Hapus
            </Button>
          </div>
        </div>
      </CustomModal>

      <ConfigDocumentModal
        dataConfig={selectedData}
        handleChangeDataConfig={(data) => handleChangeDataConfig(data)}
        modal={modalDownload}
        modalWidth="500"
        toggle={handleCloseModalDownload}
        handleResultNotification={handleResultNotification}
        invoiceData={selectedInvoice}
      />
    </>
  );
};

export default InvoiceList;
