// ComparePriceModal.js
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button, Row, ModalHeader, ModalFooter } from 'reactstrap';
import ComparePriceContent from '../compare-price/ComparePriceContent';
import closeIcon from '@assets/icons/close.svg';
import '../../styles/compare_price_modal.scss';
import overrideIcon from '@assets/icons/override-price.svg';
import { getCalculatePrice } from '../../store/booking';
import { useDispatch } from 'react-redux';

const ComparePriceModal = (props) => {
  const [modal, setModal] = useState(false);
  const {
    handleMultipleWorkshopAddCart,
    setSelectedWorkshop,
    cart,
    multipleCalculateCartData,
    selectedWorkshops,
    setSelectedWorkshops,
    tempBookingData,
    setIsSelectedWorkshopPrice,
    calculatePricePayload,
    handleAddMultipleCalculateCartData,
    setCalculatePricePayload,
    overrideList,
    setUpdatedCalculatePayloadCart,
    b2bDraft,
    setOverrideQty,
    overrideQty,
    handlePriceChange,
  } = props;

  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const [isUnlockOverrideField, setUnlockOverrideField] = useState(false);
  const [updatedCalculatePayload, setUpdatedCalculatePayload] = useState(calculatePricePayload);
  const [wiProductObj, setWiProductObj] = useState({});

  const hasNonEmptyValue =
    cart &&
    typeof cart === 'object' &&
    Object.values(cart).some((value) => (Array.isArray(value) ? value.length > 0 : !!value));

  const isDisabled = selectedWorkshops.length <= 0 || !hasNonEmptyValue;

  const closeButton = <img src={closeIcon} height={16} alt="Close" onClick={toggle} style={{ cursor: 'pointer' }} />;

  const handleQtyChange = (productId, qty) => {
    setOverrideQty((prevObj) => ({
      ...prevObj,
      [productId]: qty,
    }));
  };

  const onSubmitCalculate = () => {
    calculatePricePayload?.workshops?.forEach((workshop) => {
      const overrideWorkshop = overrideList.find((override) => override.workshop_id === workshop.id);

      if (overrideWorkshop) {
        workshop?.items?.forEach((item) => {
          const overrideItem = overrideList.find(
            (override) => override.product_id === item.product_id && override.workshop_id === workshop.id
          );

          if (overrideItem) {
            // const isSamePrice = item.price === overrideItem.price;
            item.price = overrideItem.price;
            item.is_override_sell = overrideItem.isOverrideSell;
            if (overrideItem?.nettPrice !== null) {
              // const isSameNettPrice =
              //   item.nett_price === overrideItem?.nettPrice?.value ||
              //   item?.nett_price?.value === overrideItem?.nettPrice?.value;
              item.nett_price = overrideItem.nettPrice;
              item.is_override_nett = overrideItem.isOverrideNett;
              item.is_splited_tax = overrideItem.isSplitedTax;
              item.workshop_price_after_tax = overrideItem.nettPriceAfterTax || overrideItem?.nettPrice?.value
            }
          }
        });
      }

      if (overrideQty !== undefined) {
        workshop.items.forEach((item) => {
          if (item.product_id !== 'mock_item' && item?.product_id) {
            item.qty = parseInt(overrideQty[item.product_id]) || item.qty;
          }
        });
      }
    });
    dispatch(getCalculatePrice(calculatePricePayload))
      .then((res) => {
        const data = res?.payload?.data;
        handleAddMultipleCalculateCartData(data);
        setCalculatePricePayload(calculatePricePayload);
        setUpdatedCalculatePayloadCart(calculatePricePayload);
        if (selectedWorkshops.length == 1) {
          onSubmit(selectedWorkshops[0]?.id);
        }
      })
      .catch((err) => {
        console.log('FAILED -> ', err);
      });
  };

  const handleSubmitOverride = () => {
    if (!isUnlockOverrideField) {
      setUnlockOverrideField(true);
    } else {
      setUnlockOverrideField(false);
      onSubmitCalculate();
    }
  };

  const onSubmit = (workshopId, mode = 'calculate-override') => {
    const itemObj = {
      package: [],
      product: [],
      service: [],
    };

    if (cart.product && Array.isArray(cart.product)) {
      cart.product.forEach((product) => {
        const workshopProducts = wiProductObj[workshopId] || {};
        if (workshopProducts[product.name.product_id] || workshopProducts[product.name.id]) {
          if (product?.formType === 'custom') {
            itemObj.product.push(product);
          } else if (product.name.product_id !== 'mock_item') {
            itemObj.product.push({
              ...product,
              is_wi: workshopProducts[product.name.product_id].is_wi,
              price: workshopProducts[product.name.product_id].price,
              final_sell_price: workshopProducts[product.name.product_id].final_sell_price,
              total_price: workshopProducts[product.name.product_id].total_price,
              nettPrice: workshopProducts[product.name.product_id].nett_price,
              isOverrideNett: workshopProducts[product.name.product_id].isOverrideNett,
              isOverrideSell: workshopProducts[product.name.product_id].isOverrideSell,
              isSplitedTax: workshopProducts[product.name.product_id].is_splited_tax,
              originalPrice: workshopProducts[product.name.product_id].original_price,
              originalNettPrice: workshopProducts[product.name.product_id].original_nett_price,
              sellPrice: {
                ...product.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[product.name.product_id].price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[product.name.product_id].price,
              },
              nettPrice: {
                ...product.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[product.name.product_id].nett_price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[product.name.product_id].nett_price,
              },
              name: {
                ...product.name,
                price: workshopProducts[product.name.product_id].price,
                nett_price: workshopProducts[product.name.product_id].nett_price,
                calculated_price: workshopProducts[product.name.product_id].total_price,
                attr: {
                  ...product.name.attr,
                  is_wi: workshopProducts[product.name.product_id].is_wi,
                },
              },
            });
          }
        }
      });
    }

    if (cart.service && Array.isArray(cart.service)) {
      cart.service.forEach((service) => {
        const workshopProducts = wiProductObj[workshopId] || {};
        if (workshopProducts[service.name.product_id] || workshopProducts[service.name.id]) {
          if (service.formType === 'custom') {
            itemObj.service.push(service);
          } else if (service.name.product_id !== 'mock_item') {
            itemObj.service.push({
              ...service,
              is_wi: workshopProducts[service.name.product_id].is_wi,
              price: workshopProducts[service.name.product_id].price,
              final_sell_price: workshopProducts[service.name.product_id].final_sell_price,
              total_price: workshopProducts[service.name.product_id].total_price,
              isOverrideNett: workshopProducts[service.name.product_id].isOverrideNett,
              isOverrideSell: workshopProducts[service.name.product_id].isOverrideSell,
              isSplitedTax: workshopProducts[service.name.product_id].is_splited_tax,
              originalPrice: workshopProducts[service.name.product_id].original_price,
              originalNettPrice: workshopProducts[service.name.product_id].original_nett_price,
              sellPrice: {
                ...service.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[service.name.product_id].price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[service.name.product_id].price,
              },
              nettPrice: {
                ...service.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[service.name.product_id].nett_price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[service.name.product_id].nett_price,
              },
              name: {
                ...service.name,
                price: workshopProducts[service.name.product_id].price,
                nett_price: workshopProducts[service.name.product_id].nett_price,
                calculated_price: workshopProducts[service.name.product_id].total_price,
                attr: {
                  ...service.name.attr,
                  is_wi: workshopProducts[service.name.product_id].is_wi,
                },
              },
            });
          }
        }
      });
    }

    if (cart.package && Array.isArray(cart.package)) {
      cart.package.forEach((pkg) => {
        // ultimate tandain
        if (pkg.name && pkg.name.workshop_ids.includes(workshopId)) {
        // if (pkg.name && pkg.name.workshop_id === workshopId) {
          const updatedPackage = {
            ...pkg,
            workshop_id: workshopId,
          };
          itemObj.package.push(updatedPackage);
        }
      });
    }

    const workshop = selectedWorkshops.find((workshop) => workshop.id === workshopId);
    setSelectedWorkshops([]);
    setSelectedWorkshop(workshop);
    handleMultipleWorkshopAddCart(itemObj);
    setSelectedWorkshops([workshop]);
    setIsSelectedWorkshopPrice(true);

    if (mode === 'select-workshop') {
      toggle();
    }
  };

  // useEffect(() => {
  //   console.log(calculatePricePayload, 'calculatePricePayloadNew');
  //   dispatch(getCalculatePrice(calculatePricePayload))
  //     .then((res) => {
  //       const data = res?.payload?.data;
  //       handleAddMultipleCalculateCartData(data);
  //     })
  //     .catch((err) => {
  //       console.log('FAILED -> ', err);
  //     });
  // },[calculatePricePayload])

  return (
    <div>
      {!isDisabled && (
        <Button id="create-booking" color="blue" onClick={toggle}>
          Perbandingan Harga
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} centered size="xl">
        <ModalHeader
          toggle={toggle}
          style={{ backgroundColor: 'white', height: '60px' }}
          cssModule={{ 'modal-title': 'w-100 text-center' }}
          close={closeButton}
          className="modal-header-compare-workshop modal-header-custom-compare-workshop modal-title-compare-workshop"
        >
          <div className="justify-content-center">
            <p style={{ fontWeight: 700, fontSize: 20 }}>Perbandingan Harga</p>
          </div>
        </ModalHeader>
        <ModalBody className="modal-body-scroll-compare-workshop modal-content-compare-workshop modal-body-compare-workshop">
          <div className="float-end">
            {selectedWorkshops.length > 1 && (
              <Button id="create-booking" className="float-right" color="blue" onClick={handleSubmitOverride}>
                {/* <span>
                  {' '}
                  <img src={overrideIcon} height={16} alt="Close" color="white" style={{ cursor: 'pointer' }} />
                </span> */}
                <span> {!isUnlockOverrideField ? 'Ubah Harga' : 'Simpan'}</span>
              </Button>
            )}
          </div>
          <ComparePriceContent
            onSubmit={onSubmit}
            wiProductObj={wiProductObj}
            setWiProductObj={setWiProductObj}
            handleQtyChange={handleQtyChange}
            b2bDraft={b2bDraft}
            setUpdatedCalculatePayload={setUpdatedCalculatePayload}
            handleAddMultipleCalculateCartData={handleAddMultipleCalculateCartData}
            calculatePricePayload={calculatePricePayload}
            isUnlockOverrideField={isUnlockOverrideField}
            setUnlockOverrideField={setUnlockOverrideField}
            setIsSelectedWorkshopPrice={setIsSelectedWorkshopPrice}
            tempBookingData={tempBookingData}
            setSelectedWorkshops={setSelectedWorkshops}
            multipleCalculateCartData={multipleCalculateCartData}
            cart={cart}
            handleMultipleWorkshopAddCart={handleMultipleWorkshopAddCart}
            setSelectedWorkshop={setSelectedWorkshop}
            selectedWorkshops={selectedWorkshops}
            toggle={toggle}
            handlePriceChange={handlePriceChange}
            overrideList={overrideList}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ComparePriceModal;
