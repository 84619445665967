import React, { useEffect, useMemo, useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import BookingCustomerDetailForms from '@components/booking/BookingCustomerDetailForms';
import { monasLocation, GMAPS_API_KEY } from '@utils/Constants';
import BookingDetailServices from '@components/booking/BookingDetailServices';
import Geocode from 'react-geocode';
import CreateUserModal from '@components/modal/CreateUserModal';
import { parsePhoneNumber } from 'libphonenumber-js';
import API from '@utils/API';
import APIS3 from '@utils/APIS3';
import { assign, isEmpty, map } from 'lodash';
import ModalChooseWorkshop from '@components/modal/ModalChooseWorkshop';
import moment from 'moment';
import Helper from '@helpers/Helper';
import { useHistory, useParams } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import { HourOptions } from '@utils/Constants';
import BookingWorkshop from '@components/booking/BookingWorkshop';
import BookingWorkshopDetail from '@components/booking/BookingWorkshopDetail';
import BookingCreateDetailDataStudio from '@components/booking/BookingCreateDetailDataStudio';
import BookingSummary from '@components/booking/BookingSummary';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import ValidCoordinates from 'is-valid-coordinates';
import { useDispatch, useSelector } from 'react-redux';
import '@styles/create_booking_button.scss';
import ComparePriceModal from '@components/modal/ComparePriceModal';
import { createBookingB2BDraft, setIsDisableCreateBookingButton, updateBookingB2BDraft } from '../../store/booking';
import CustomModal from '@components/modal/CustomModal';

const BookingCreate = () => {
  const bookingStore = useSelector((state) => state.booking);

  const dispatch = useDispatch();

  const api = new API('v2');
  const apiS3 = new APIS3();
  const history = useHistory();
  const { generatePermission } = userPermission();
  const { id, b2bDraft } = useParams();

  const [accountType, setAccountType] = useState('b2c');
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);
  const [errorUser, setErrorUser] = useState('');
  const [selectedCar, setSelectedCar] = useState(null);
  const [loadingSearchCar, setLoadingSearchCar] = useState(false);
  const [errorCar, setErrorCar] = useState('');
  const [carMilleage, setCarMilleage] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: '0',
  });
  const [errorCarMilleage, setErrorCarMilleage] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [errorDetailAddress, setErrorDetailAddress] = useState('');
  const [freshDeskLink, setFreshDeskLink] = useState('');
  const [selectedBookingSource, setSelectedBookingSource] = useState(null);
  const [errorBookingSource, setErrorBookingSource] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [bookingDateTime, setBookingDateTime] = useState();
  const [errorDate, setErrorDate] = useState('');
  const [selectedTime, setSelectedTime] = useState();
  const [errorTime, setErrorTime] = useState('');
  const [mapValue, setMapValue] = useState();
  const [errorMap, setErrorMap] = useState('');
  const [selectedWorkshop, setSelectedWorkshop] = useState();
  const [errorWorkshop, setErrorWorkshop] = useState('');
  const [spkFile, setSPKFile] = useState();
  const [spkFileKey, setSPKFileKey] = useState();
  const [spkFileUrl, setSPKFileUrl] = useState();
  const [loadingUploadSPK, setLoadingUploadSPK] = useState(false);
  const [errorSPKFile, setErrorSPKFile] = useState('');
  const [spkNumber, setSPKNumber] = useState('');
  const [errorSPKNumber, setErrorSPKNumber] = useState('');
  const [promo, setPromo] = useState('');
  const [promoStatus, setPromoStatus] = useState();
  const [promoPackageStatus, setPromoPackageStatus] = useState();
  const [promoPackageStatusEdit, setPromoPackageStatusEdit] = useState();
  const [promoCodeResult, setPromoCodeResult] = useState({});
  const [promoData, setPromoData] = useState({});
  const [userOtopoints, setUserOtopoints] = useState(0);
  const [userTotalOtopointsAvailable, setTotalOtoPointsAvailable] = useState(0);
  const [usedOtopoints, setUsedOtopoints] = useState(0);
  const [useOtopoints, setUseOtopoints] = useState(false);
  const [disableOtopoints, setDisableOtopoints] = useState(true);
  const [internalNotes, setInternalNotes] = useState('');
  const [errorInternalNotes, setErrorInternalNotes] = useState('');
  const [cart, setCart] = useState();
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);
  const [errorCart, setErrorCart] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [ppn, setPPN] = useState(0);
  const [pph, setPPH] = useState(0);
  const [dpp, setDPP] = useState(0);
  const [rounding, setRounding] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [amount, setAmount] = useState(0);
  const [markUpProduct, setMarkUpProduct] = useState(0);
  const [markUpService, setMarkUpService] = useState(0);
  const [discProduct, setDiscProduct] = useState(0);
  const [discService, setDiscService] = useState(0);
  const [loadingCalculate, setLoadingCalculate] = useState(false);

  const [carOptions, setCarOptions] = useState([]);
  const [bookingSourceOptions, setBookingSourceOptions] = useState([]);

  const [disableECommerce, setDisableEcommerce] = useState(true);
  const [disableCar, setDisableCar] = useState(true);
  const [disableTime, setDisableTime] = useState(true);
  const [disableAll, setDisableAll] = useState(true);

  const [hasGMapModal, setHasGMapModal] = useState(false);
  const [isPUDO, setIsPUDO] = useState(false);
  const [isECommerce, setIsECommerce] = useState(false);
  const [isDuplicateBooking, setIsDuplicateBooking] = useState(false);
  const [hasModalChooseWorkshop, setHasModalChooseWorkshop] = useState(false);

  const [phoneNumberInit, setPhoneNumberInit] = useState('');
  const [modalCreate, setModalCreate] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalHasError, setModalHasError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [modalSubmitLoading, setModalSubmitLoading] = useState(false);
  const [modalErrorColor, setModalErrorColor] = useState('info');
  const [submitModalSuccess, setSubmitModalSuccess] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);

  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);

  const [loading, setLoading] = useState(false);
  const [detailFleet, setDetailFleet] = useState();
  const [isFleetMode, setIsFleetMode] = useState(false);

  const [selectedWorkshops, setSelectedWorkshops] = useState([]);

  const [processRemoveCart, setProcessRemoveCart] = useState(false);

  const [itemPackages, setItemPackages] = useState([]);

  const [createBookingButtonDropdown, setCreateBookingButtonDropdown] = useState(false);

  const [tempBookingData, setTempBookingData] = useState({
    carDetails: null,
    bookingCode: '',
    bookingDateTime: null,
    customer: null,
  });

  const [multipleCalculateCartData, setMultipleCalculateCartData] = useState([]);

  const [isB2BDraft, setIsB2BDraft] = useState(false);
  const [b2bDraftData, setB2BDraftData] = useState();
  const [isInitializedB2BDraftCart, setIsInitializedB2BDraftCart] = useState(false);
  const [isInitializedB2BDraftWorkshops, setIsInitializedB2BDraftWorkshops] = useState(false);

  const [multipleWorkshopCart, setMultipleWorkshopCart] = useState({
    package: [],
    product: [],
    service: [],
  });

  const [isSelectedWorkshopPrice, setIsSelectedWorkshopPrice] = useState(false);
  const [isSelecetedWorkshopPriceModalOpen, setIsSelecetedWorkshopPriceModalOpen] = useState(false);
  const [calculatePricePayload, setCalculatePricePayload] = useState();
  const [overrideList, setOverrideList] = useState([]);
  const [bookingStatusCondition, setBookingStatusCondition] = useState('Butuh Persetujuan');
  const [updatedCalculatePayload, setUpdatedCalculatePayload] = useState();
  const [wiProductObj, setWiProductObj] = useState({});
  const [selectedTemporaryWorkshop, setSelectedTemporaryWorkshop] = useState(null);
  const [isSubmitBooking, setIsSubmitBooking] = useState(false);
  const [isSubmitB2BDraft, setIsSubmitB2BDraft] = useState(false);
  const [bookingStatusConditionSlug, setBookingStatusConditionSlug] = useState('review');
  const [totalAmountB2B, setTotalAmountB2B] = useState(0);
  const [overrideQty, setOverrideQty] = useState();
  const [isBookingUpdateChanges, setIsBookingUpdateChanges] = useState(false);

  const toggleCreateBookingButtonDropdown = () => setCreateBookingButtonDropdown(!createBookingButtonDropdown);

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowShow = allowPermission('all') || allowPermission('createall') || allowPermission('createshow');
  const allowSave = allowPermission('all') || allowPermission('createall') || allowPermission('createsave');
  const allowBookingCustomForm =
    allowPermission('all') || allowPermission('createall') || allowPermission('createcustom');

  const breadcrumb = [
    {
      id: 1,
      label: 'Booking / Order',
      active: false,
      url: '/bookings',
    },
    {
      id: 2,
      label: 'Buat',
      active: true,
      url: '',
    },
  ];

  const handleAddMultipleCalculateCartData = (multipleData) => {
    setMultipleCalculateCartData(multipleData);
  };

  const handleResultNotification = async (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    let timeoutTime = type === 'success' ? 3000 : 30000;

    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
      return status;
    }, timeoutTime);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  const toggleGMapModal = () => setHasGMapModal(!hasGMapModal);

  const loadUserDetail = async (key, callback) => {
    let phoneNumber = key.replace(/^0/, '');
    phoneNumber = phoneNumber.replace(/(^\+[0-9]{0,2})/g, '');
    setLoadingSearchUser(true);
    if (phoneNumber) {
      return api
        .get(`v2/intools/customers/?query=${phoneNumber}`)
        .then((res) => {
          const data = res.data.data.customers;
          let dataArray = [];
          map(data, (item) => {
            dataArray.push({
              value: item.id,
              label: `${!item.is_enterprise ? `${item.phone_number} - ` : ''}${item.name}${
                item.is_enterprise && item.nickname ? ` (${item.nickname})` : ''
              }`,
              isB2B: !!item.is_enterprise,
              ...item,
            });
          });
          setLoadingSearchUser(false);
          callback(dataArray);
          return dataArray;
        })
        .catch((err) => {
          console.log(err);
          setLoadingSearchUser(false);
        });
    }
  };

  const loadUserCars = (id) => {
    const params = {
      customer_id: id,
      limit: 10,
    };

    setLoadingSearchCar(true);
    return api
      .get(`v2/intools/user_car/`, { params })
      .then((response) => {
        setCarOptions(response.data.data.user_cars);
        setDisableCar(false);
        setLoadingSearchCar(false);
        return response.data.data;
      })
      .catch(() => {
        setLoadingSearchCar(false);
      });
  };

  const loadUserCarOptions = (query) => {
    if (selectedUser?.id) {
      const params = {
        customer_id: selectedUser?.id,
        limit: 10,
        query,
      };

      return api
        .get(`v2/intools/user_car/`, { params })
        .then((response) => {
          return response.data.data.user_cars;
        })
        .catch(() => {});
    }
  };

  const loadBookingSource = async (type) => {
    setSelectedBookingSource(null);

    await api
      .get(`v2/intools/bookings/sources?type=${type}`)
      .then((res) => {
        const transformData = map(res.data.data, (item) => {
          return {
            value: item.id,
            label: `${item.source_type === 'ecommerce' ? 'E-Commerce - ' : ''}${item.name}`,
            ...item,
          };
        });

        setBookingSourceOptions(transformData);
        // if (transformData.length < 2) setSelectedBookingSource(transformData[0]);
        setSelectedBookingSource(transformData[0]);
      })
      .catch((err) => console.log(err));
  };

  const loadUpdatedWorkshopOperationalHour = async (
    date = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    time = `${selectedTime?.value ?? HourOptions[0]?.value}`
  ) => {
    await api
      .get(`/v2/intools/workshops/${selectedWorkshop?.id}/date-check/?booking_date=${date}&booking_time=${time}:00`)
      .then((res) => {
        const data = res?.data?.data;

        setSelectedWorkshop((prevData) => {
          return {
            ...prevData,
            operational_time: data,
          };
        });
        if (errorWorkshop && !data?.is_closed) setErrorWorkshop('');
      })
      .catch((err) => {
        console.log('failed get updated workshop operational hour =>', err?.response);
      });
  };

  const loadCustomerDetails = async (id) => {
    const response = await api.get(`v2/intools/customers/details?customer_id=${id}`);
    const data = response.data.data;

    delete data.blacklisted_workshops;
    setSelectedUser({
      ...data,
      value: data.id,
      label: `${!data.b2b_customer ? `${data.phone_no} - ` : ''}${data.username}${
        data.b2b_customer && data.nickname ? ` (${data.nickname})` : ''
      }`,
      isB2B: !!data.b2b_customer,
    });
    setTempBookingData((prevState) => ({
      ...prevState,
      customer: {
        ...data,
        value: data.id,
        label: `${!data.b2b_customer ? `${data.phone_no} - ` : ''}${data.username}${
          data.b2b_customer && data.nickname ? ` (${data.nickname})` : ''
        }`,
        isB2B: !!data.b2b_customer,
      },
    }));
    setUserOtopoints(data?.loyalty_points > 0 ? data?.loyalty_points : 0);
    setMarkUpProduct(data?.product_markup ?? 0);
    setMarkUpService(data?.service_markup ?? 0);
    setDiscProduct(data?.product_discount ?? 0);
    setDiscService(data?.service_discount ?? 0);
    return data;
  };

  const loadDetailFleet = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/bookings/${id}/`);
      setDetailFleet(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      history.push('/bookings');
    }
  };

  const loadDetailB2BDraft = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/bookings/${id}/`);
      setB2BDraftData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      history.push('/bookings');
    }
  };

  const handleDetailFleet = () => {
    if (detailFleet?.booking_source?.slug == 'fleet' && detailFleet?.booking_status?.slug == 'review') {
      setIsFleetMode(true);
      setDefaultDataFleet();
    } else {
      setIsFleetMode(false);
      history.push('/bookings');
    }
  };

  const handleDetailB2BDraft = () => {
    if (b2bDraftData?.booking_source?.slug == 'b2b' && b2bDraftData?.b2b_draft?.is_draft) {
      setIsB2BDraft(true);
      setDefaultB2BDraftData();
    } else {
      setIsB2BDraft(false);
      history.push('/bookings');
    }
  };

  const handleDetailB2BDraftBookingStatus = () => {
    if (b2bDraftData?.booking_status?.slug == 'waiting') {
      setBookingStatusCondition('Menunggu Kedatangan');
    }
  };

  const setDefaultDataFleet = () => {
    const customer = detailFleet?.customer ?? {};
    const labelUserData = `${customer?.customer_type !== 'b2b' ? `${customer.phone_no} - ` : ''}${customer.name}${
      customer?.customer_type == 'b2b' && customer?.nickname ? ` (${customer.nickname})` : ''
    }`;

    const defaultUserData = {
      value: customer?.id ?? null,
      label: labelUserData,
      isB2B: customer?.customer_type == 'b2b' ? true : false,
      id: customer?.id ?? null,
      phone_number: customer?.phone_no ?? '',
      loyalty_points: detailFleet?.otopoints_balance ?? 0,
      is_enterpris: customer?.customer_type == 'b2b' ? true : false,
      name: customer.name ?? '',
      nickname: customer.nickname ?? '',
      email: customer.email ?? '',
      is_verified: null,
      flag_status: customer?.flag_status ?? '',
    };

    const defaultCarData = detailFleet?.user_car ?? {};
    const splitTime = detailFleet?.booking_time ? detailFleet?.booking_time.split('.') : [];
    const timeValue =
      splitTime?.length == 2
        ? { value: `${splitTime[0]}:${splitTime[1]}`, label: `${splitTime[0]}:${splitTime[1]}` }
        : null;
    const formattedDateTime = Helper.convertToGeneralDateTime(detailFleet?.booking_datetime);

    handleChangeUser(defaultUserData);
    handleChangeCar(defaultCarData);
    handleChangeCarMilleage({ target: { value: `${detailFleet?.user_car?.mileage ?? 0}` } });
    setMapValue({
      latitude: ValidCoordinates.latitude(Number(detailFleet?.service_type['customer-address']?.lat))
        ? Number(detailFleet?.service_type['customer-address']?.lat)
        : monasLocation.lat,
      longitude: ValidCoordinates.longitude(Number(detailFleet?.service_type['customer-address']?.lon))
        ? Number(detailFleet?.service_type['customer-address']?.lon)
        : monasLocation.lng,
      street_address: detailFleet?.service_type['customer-address']?.address1 ?? 'Monas, Jakarta Pusat',
    });

    handleChangeAddressDetail({ target: { value: detailFleet?.service_type['customer-address']?.label ?? '' } });
    handleChangeFreshDeskLink({ target: { value: detailFleet?.freshdesk_ticket_link ?? '' } });
    handleChangeDate({ target: { value: formattedDateTime.split(' ')[0] } });
    handleChangeTime(timeValue);
    setBookingDateTime(detailFleet?.booking_datetime);
    setTempBookingData((prevState) => ({
      ...prevState,
      bookingDateTime: detailFleet?.booking_datetime,
      bookingCode: detailFleet?.booking_code,
    }));
  };

  const setDefaultB2BDraftData = () => {
    const customer = b2bDraftData?.customer ?? {};
    const labelUserData = `${customer?.customer_type !== 'b2b' ? `${customer.phone_no} - ` : ''}${customer.name}${
      customer?.customer_type == 'b2b' && customer?.nickname ? ` (${customer.nickname})` : ''
    }`;

    const defaultUserData = {
      value: customer?.id ?? null,
      label: labelUserData,
      isB2B: customer?.customer_type == 'b2b' ? true : false,
      id: customer?.id ?? null,
      phone_number: customer?.phone_no ?? '',
      loyalty_points: b2bDraftData?.otopoints_balance ?? 0,
      is_enterpris: customer?.customer_type == 'b2b' ? true : false,
      name: customer.name ?? '',
      nickname: customer.nickname ?? '',
      email: customer.email ?? '',
      is_verified: null,
      flag_status: customer?.flag_status ?? '',
    };

    const defaultCarData = b2bDraftData?.user_car ?? {};
    const splitTime = b2bDraftData?.booking_time ? b2bDraftData?.booking_time.split('.') : [];
    const timeValue =
      splitTime?.length == 2
        ? { value: `${splitTime[0]}:${splitTime[1]}`, label: `${splitTime[0]}:${splitTime[1]}` }
        : null;
    const formattedDateTime = Helper.convertToGeneralDateTime(b2bDraftData?.booking_datetime);

    handleChangeUser(defaultUserData);
    handleChangeCar(defaultCarData);
    handleChangeCarMilleage({ target: { value: `${b2bDraftData?.user_car?.mileage ?? 0}` } });
    setMapValue({
      latitude: ValidCoordinates.latitude(Number(b2bDraftData?.service_type['customer-address']?.lat))
        ? Number(b2bDraftData?.service_type['customer-address']?.lat)
        : monasLocation.lat,
      longitude: ValidCoordinates.longitude(Number(b2bDraftData?.service_type['customer-address']?.lon))
        ? Number(b2bDraftData?.service_type['customer-address']?.lon)
        : monasLocation.lng,
      street_address: b2bDraftData?.service_type['customer-address']?.address1 ?? 'Monas, Jakarta Pusat',
    });

    handleChangeAddressDetail({ target: { value: b2bDraftData?.service_type['customer-address']?.label ?? '' } });
    handleChangeFreshDeskLink({ target: { value: b2bDraftData?.freshdesk_ticket_link ?? '' } });
    handleChangeDate({ target: { value: formattedDateTime.split(' ')[0] } });
    handleChangeTime(timeValue);
    setBookingDateTime(b2bDraftData?.booking_datetime);
    setTempBookingData((prevState) => ({
      ...prevState,
      bookingDateTime: b2bDraftData?.booking_datetime,
      bookingCode: b2bDraftData?.booking_code,
    }));
  };

  const handleSetBookingSource = () => {
    if (bookingSourceOptions.length && (isFleetMode || isB2BDraft)) setSelectedBookingSource(bookingSourceOptions[1]);
  };

  const handleChangeUser = (value) => {
    loadCustomerDetails(value?.id);
    setAccountType(value.isB2B ? 'b2b' : 'b2c');
    setUserOtopoints(value.loyalty_points ?? 0);
    loadUserCars(value.value);
    setDisableEcommerce(false);
    resetField('change user', value);

    if (value.loyalty_points < 1) {
      setDisableOtopoints(true);
    } else {
      setDisableOtopoints(false);
    }
    if (value.isB2B) {
      let e = {
        target: {
          checked: false,
        },
      };
      handleClickECommerce(e, 'change user');
    }
    if (value && errorUser) setErrorUser('');
  };

  const handleChangeCar = (value) => {
    setSelectedCar(value);
    setDisableAll(false);
    setTempBookingData((prevState) => ({
      ...prevState,
      carDetails: value,
    }));
    if (value && errorCar) setErrorCar('');
  };

  const handleChangeCarMilleage = (e) => {
    const { value } = e.target;
    let milleageValue = value.replace(/[^0-9+()]/g, '');

    setCarMilleage(Helper.formatMoneyOnField(milleageValue));
    if (milleageValue?.length > 7) {
      setErrorCarMilleage('Format kilometer maksimal adalah 7 karakter');
    } else {
      setErrorCarMilleage('');
    }
  };

  const handleChangeAddressDetail = (e) => {
    const { value } = e.target;

    setDetailAddress(value);
    if (value?.length > 0 && errorDetailAddress) setErrorDetailAddress('');
  };

  const handleChangeFreshDeskLink = (e) => {
    const { value } = e.target;

    setFreshDeskLink(value);
  };

  const handleChangeBookingSource = (value) => {
    setSelectedBookingSource(value);
    if (isECommerce) setDisableAll(false);
    if (value && errorBookingSource) setErrorBookingSource('');
  };

  const handleClickMap = () => {
    if (!disableAll && !isFleetMode) {
      toggleGMapModal();
    }
  };

  const handleChangeMap = (e) => {
    setMapValue(e);
    if (e?.latitude !== monasLocation?.latitude && e?.longitude !== monasLocation?.longitude) {
      setErrorMap('');
    }
  };

  const handleClickPUDO = (e) => {
    const { checked } = e.target;
    setIsPUDO(checked);

    if (!checked) {
      setErrorMap('');
      setErrorDetailAddress('');
    }
    if (isECommerce) setIsECommerce(false);
  };

  const handleClickECommerce = (event, source) => {
    const { checked } = event.target;
    setIsECommerce(checked);
    if (source !== 'change user')
      loadBookingSource(checked ? 'ecommerce' : selectedUser?.isB2B ? 'b2b' : 'default,otobuddy');
    if (isPUDO) setIsPUDO(false);
    if (checked) setDisableAll(false);
    if (!checked && !selectedCar) setDisableAll(true);

    const e = {
      target: {
        checked: false,
      },
    };
    if (useOtopoints) handleChangeOtopoints(e);
  };

  const handleChangeDate = (e) => {
    const { value } = e.target;

    setSelectedDate(value);
    setDisableTime(false);
    validateDateTime(value, selectedTime, 'date');
  };

  const handleChangeTime = (value) => {
    setSelectedTime(value);
    validateDateTime(selectedDate, value, 'time');
  };

  const validateDateTime = async (date = selectedDate, time = selectedTime, type) => {
    let status = true;

    if (type === 'date') if (date && errorDate) setErrorDate('');
    if (type === 'time') if (time && errorTime) setErrorTime('');
    if (selectedWorkshop) loadUpdatedWorkshopOperationalHour(date, time?.value);
    setBookingDateTime(Helper.formatBookingDateTime(date, time?.value));
    setTempBookingData((prevState) => ({
      ...prevState,
      bookingDateTime: Helper.formatBookingDateTime(date, time?.value),
    }));
    return status;
  };

  const handleChangeWorkshop = (value, type = 'add') => {
    if (type === 'add') {
      setSelectedWorkshop(value);
      toggleHasModalChooseWorkshop();
      if (cart?.product?.length > 0) getProductUpdate(value?.slug, cart?.product, 'part');
      if (cart?.service?.length > 0) getProductUpdate(value?.slug, cart?.service, 'work');
      if (value && errorWorkshop) setErrorWorkshop('');
      if (!selectedCar && isECommerce) {
        setErrorCar('No. Plat Mobil harus diisi');
        setErrorDate('Tanggal harus diisi');
        setErrorTime('Waktu harus diisi');
      }
    }

    if (type === 'delete') {
      setSelectedWorkshop();
      if (errorWorkshop) setErrorWorkshop('');
    }
  };

  const generatePackageIdAndFBOFlag = (data) => {
    const productId = map(data, (item) => {
      if (!item?.formType) {
        return { product_id: item?.name?.id ?? item?.name?.product_id, is_fbo: item?.name?.is_fbo };
      }
    });

    return productId.filter((x) => x) || [];
  };

  const getProductUpdate = async (slug, data, type = 'product') => {
    const url = `v2/intools/products/workshop/${slug}`;
    const payload = {
      products: generatePackageIdAndFBOFlag(data),
      is_enterprise_customer: selectedUser?.isB2B === 'b2b' ? true : false,
      variant_car_id: selectedCar?.car_details?.id,
      type,
      category_id: '',
      customer_id: selectedUser?.id,
      is_ecommerce: isECommerce,
    };

    const renderType = () => {
      switch (type) {
        case 'part':
          return 'product';
        case 'work':
          return 'service';
        default:
          break;
      }
    };

    await api
      .post(url, payload)
      .then((res) => {
        const data = res?.data?.data || [];
        const transformedData = map(data, (item) => {
          const arrayToFilter = cart[renderType()];
          const productIndex = map(arrayToFilter, (cartItem) => {
            return cartItem?.name?.product_id + '||' + cartItem?.name?.is_fbo;
          }).indexOf(item?.product_id + '||' + item?.is_fbo);
          let temporaryData = arrayToFilter[productIndex];
          temporaryData.is_wi = item?.attr?.is_wi;
          temporaryData.isOverrideSell = false;
          temporaryData.name = {
            label: item?.display_name,
            value: item?.id ?? item?.product_id,
            ...item,
          };
          temporaryData.sellPrice = {
            formatIsMoney: false,
            formatMoney: item?.price,
            formatMoneyString: Helper.formatMoney(item?.price),
            value: item?.price,
          };

          temporaryData.nettPrice = {
            formatIsMoney: false,
            formatMoney: item?.nett_price,
            formatMoneyString: Helper.formatMoney(item?.nett_price),
            value: item?.nett_price,
          };

          return temporaryData;
        });

        if (payload.products.length == transformedData.length) {
          setCart({ ...cart, [renderType()]: transformedData });
          setIsUpdatingCart(true);
          setTimeout(() => {
            setIsUpdatingCart(false);
          }, 1000);
        }
      })
      .catch((err) => console.log(err?.response?.data));
  };

  const toggleHasModalChooseWorkshop = () => setHasModalChooseWorkshop(!hasModalChooseWorkshop);

  const onClickCreateNewUser = (e) => {
    setPhoneNumberInit('');
    setModalType(e);
    setModalHasError(false);
    setModalCreate(!modalCreate);
  };

  const handleModalSubmit = (modalType, forms, carVariant) => {
    if (modalType == 'new-car') {
      const newCarPayload = {
        license_plate: forms.carLicense.toUpperCase(),
        variant_id: forms.carVariant,
        transmission: forms.carTransmission,
        year: forms.carYear,
        vin_number: forms.carVinNumber,
        stnk_image: forms.stnk_image,
        customer_id: selectedUser.id,
      };
      if (selectedUser?.isB2B) assign(newCarPayload, { tags: forms?.tags });
      postNewCar(newCarPayload, carVariant);
    } else if (modalType == 'new-user') {
      const generatePhoneNumber = parsePhoneNumber(forms.phonenumber, 'ID');
      const idPhoneNumber = generatePhoneNumber.number;

      const newUserPayload = {
        phone_number: idPhoneNumber,
        customer_name: forms.fullname.replace(/\s+$/, ''), //remove space if there is a space after the last name
        email: forms.email,
        user_type: forms.userType,
      };
      postNewUser(newUserPayload, forms, carVariant);
    }
  };

  const postNewUser = async (payload, forms, carVariant) => {
    await api
      .post(`v2/intools/user-accounts/register/`, payload)
      .then((response) => {
        const userValue = response.data.data;
        loadCustomerDetails(userValue?.id).then((dt) => {
          loadBookingSource(dt?.b2b_customer ? 'b2b' : 'default,otobuddy');
        });
        setSelectedUser({ ...userValue, label: userValue?.name, value: userValue?.id });
        setTempBookingData((prevState) => ({
          ...prevState,
          customer: { ...userValue, label: userValue?.name, value: userValue?.id },
        }));
        setDisableEcommerce(false);
        setModalMessage(true, 'success', 'User was successfully created.');

        const newCarPayload = {
          license_plate: forms.carLicense.toUpperCase(),
          variant_id: forms.carVariant,
          transmission: forms.carTransmission,
          year: forms.carYear,
          customer_id: response.data.data?.id,
          vin_number: forms.carVinNumber,
        };

        postNewCar(newCarPayload, carVariant);
      })
      .catch((err) => {
        const message = err.response?.data?.error?.message;

        setModalMessage(true, 'danger', `Oops! something went wrong. Please try again! ${message}`);
      });
  };

  const postNewCar = async (payload, carVariant) => {
    await api
      .post(`v2/intools/user_car/`, payload)
      .then((response) => {
        const carData = response?.data?.data;
        const carValue = {
          id: carData?.id,
          license_plate: carData?.license_plate,
          transmission: carData?.transmission,
          is_selected: carData?.is_selected,
          year: carData?.year,
          car_details: carVariant,
          stnk_image: carData?.stnk_image,
          vin_number: carData?.vin_no,
          vin_no: carData?.vin_no,
        };

        setCarOptions([...carOptions, carValue]);
        setSelectedCar(carValue);
        setModalMessage(true, 'success', 'Car was successfully created.');
        setSubmitModalSuccess(true);
        setModalType(null);
        setModalCreate(false);
        setDisableAll(false);
        setDisableCar(false);
        setTempBookingData((prevState) => ({
          ...prevState,
          carDetails: carValue,
        }));
      })
      .catch((err) => {
        const message = err.response?.data?.error?.message;

        if (message) setModalMessage(true, 'danger', `Oops! something went wrong. Please try again! ${message}`);
      });
  };

  const setModalMessage = (status, color, message) => {
    setModalHasError(status);
    setModalErrorColor(color);
    setModalErrorMessage(message);
    setModalSubmitLoading(false);
  };

  const handleChangeSPKName = (e) => {
    const { value } = e.target;

    if (value.length > 100) {
      setErrorSPKNumber('Maksimal karakter 100');
    } else if (!value && spkFile?.name) {
      setSPKNumber(value);
      setErrorSPKNumber('Nomor SPK harus diisi');
    } else if (value && !spkFile?.name) {
      setSPKNumber(value);
      setErrorSPKFile('Dokumen SPK harus diisi');
    } else {
      setSPKNumber(value);
      setErrorSPKNumber('');
      setErrorSPKFile('');
    }
  };

  const handleIsSelectedWorkshopPriceModal = () => {
    setIsSelecetedWorkshopPriceModalOpen(true);
  };

  const groupedData = useMemo(() => {
    return multipleCalculateCartData?.reduce((acc, item) => {
      const { workshop_id, workshop_name } = item;

      acc[workshop_id] = acc[workshop_id] || {
        workshop_name,
        workshop_id,
        is_pkp: selectedWorkshops.find((ws) => ws.id == workshop_id)?.is_pkp ?? false,
        items: [],
        // Customer Data
        customer_tax: item.customer.tax,
        customer_dpp: item.customer.price_before_tax,
        customer_pph: item.customer.service_tax,
        customer_total: item.customer.total_amount,
        // Workshop Data
        workshop_tax: item.workshop.tax,
        workshop_dpp: item.workshop.price_before_tax,
        workshop_pph: item.workshop.service_tax,
        workshop_total: item.workshop.total_amount,
      };

      const addItem = (source, targetKey) => {
        if (source.items) {
          source.items.forEach((sourceItem) => {
            if (sourceItem?.product_id !== 'mock_item' && (sourceItem.uid || sourceItem.package_id)) {
              const groupKey = sourceItem.package_id ? sourceItem.package_name : sourceItem.uid;
              let existingGroup = acc[workshop_id].items.find((i) => i.groupKey === groupKey);

              if (!existingGroup) {
                existingGroup = {
                  groupKey,
                  package_name: sourceItem.package_id ? sourceItem.package_name : null,
                  package_id: sourceItem.package_id ?? null,
                  product_id: sourceItem.product_id ?? null,
                  items: [],
                  customer_final_sell_price: 0, // Ensure this is initialized
                  workshop_final_sell_price: 0, // Ensure this is initialized
                  isWi: sourceItem?.is_wi,
                  isPackage: 'package_id' in sourceItem,
                  isCustom: sourceItem?.is_custom,
                  isOverrideNett: sourceItem?.is_override_nett ?? false,
                  isOverrideSell: sourceItem?.is_override_sell ?? false,
                  price: sourceItem.price,
                  nettPrice: sourceItem.nett_price,
                  remarks: sourceItem.note,
                  isSplitedTax: sourceItem?.is_splited_tax ?? false,
                };
                acc[workshop_id].items.push(existingGroup);
              }

              setWiProductObj((prev) => ({
                ...prev,
                [workshop_id]: {
                  ...(prev[workshop_id] || {}),
                  [sourceItem.uid]: {
                    name: sourceItem.name,
                    is_wi: sourceItem.is_wi,
                    price: sourceItem.price,
                    final_sell_price: sourceItem.final_sell_price,
                    total_price: sourceItem.total_price,
                    nett_price: sourceItem.nett_price,
                    isOverrideNett: sourceItem?.is_override_nett ?? false,
                    isOverrideSell: sourceItem?.is_override_sell ?? false,
                    isSplitedTax: sourceItem?.is_splited_tax ?? false,
                    remarks: sourceItem?.note ?? '',
                  },
                },
              }));

              if (targetKey === 'customer_final_sell_price') {
                existingGroup.customer_final_sell_price =
                  (existingGroup.customer_final_sell_price || 0) + (sourceItem.final_sell_price || 0);
              } else if (targetKey === 'workshop_final_sell_price') {
                existingGroup.workshop_final_sell_price =
                  (existingGroup.workshop_final_sell_price || 0) + (sourceItem.total_workshop_price || 0);
              }

              const itemIndex = existingGroup.items.findIndex((i) => i.name === sourceItem.name);
              if (itemIndex !== -1) {
                existingGroup.items[itemIndex][targetKey + 'Item'] = sourceItem.final_sell_price;
              } else {
                existingGroup.items.push({
                  name: sourceItem.name,
                  [targetKey + 'Item']: sourceItem.final_sell_price,
                });
              }
            }
          });
        }
      };

      addItem(item.customer, 'customer_final_sell_price');
      addItem(item.workshop, 'workshop_final_sell_price');

      return acc;
    }, {});
  }, [multipleCalculateCartData]);

  const autoUpdateCart = (workshopId) => {
    const itemObj = {
      package: [],
      product: [],
      service: [],
    };

    if (cart.product && Array.isArray(cart.product)) {
      cart.product.forEach((product) => {
        const workshopProducts = wiProductObj[workshopId] || {};
        if (
          workshopProducts[product.name.product_id] ||
          workshopProducts[product.name.id] ||
          workshopProducts[product.name.uid]
        ) {
          if (product?.formType === 'custom') {
            itemObj.product.push({
              ...product,
              is_wi: workshopProducts[product.name.uid].is_wi,
              price: workshopProducts[product.name.uid].price,
              final_sell_price: workshopProducts[product.name.uid].final_sell_price,
              total_price: workshopProducts[product.name.uid].total_price,
              nettPrice: workshopProducts[product.name.uid].nett_price,
              isOverrideNett: workshopProducts[product.name.uid].isOverrideNett,
              isOverrideSell: workshopProducts[product.name.uid].isOverrideSell,
              isSplitedTax: workshopProducts[product.name.uid].is_splited_tax,
              sellPrice: {
                ...product.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[product.name.uid].price,
                formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                }).format(workshopProducts[product.name.uid].price)}`,
                isNoPriceAvailable: false,
                value: workshopProducts[product.name.uid].price,
              },
              name: {
                ...product.name,
                price: {
                  ...product.price,
                  formatIsMoney: true,
                  formatMoney: workshopProducts[product.name.uid].price,
                  formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                  }).format(workshopProducts[product.name.uid].price)}`,
                  isNoPriceAvailable: false,
                  value: workshopProducts[product.name.uid].price,
                },
                nettPrice: {
                  ...product.nettPrice,
                  formatIsMoney: true,
                  formatMoney: workshopProducts[product.name.uid].nett_price,
                  formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                  }).format(workshopProducts[product.name.uid].nett_price)}`,
                  isNoPriceAvailable: false,
                  value: workshopProducts[product.name.uid].nett_price,
                },
                calculated_price: workshopProducts[product.name.uid].total_price,
                attr: {
                  ...product.name.attr,
                  is_wi: workshopProducts[product.name.uid].is_wi,
                },
              },
              nett_price: workshopProducts[product.name.uid].nett_price,
            });
          } else if (product.name.product_id !== 'mock_item') {
            itemObj.product.push({
              ...product,
              is_wi: workshopProducts[product.name.uid].is_wi,
              price: workshopProducts[product.name.uid].price,
              final_sell_price: workshopProducts[product.name.uid].final_sell_price,
              total_price: workshopProducts[product.name.uid].total_price,
              nettPrice: workshopProducts[product.name.uid].nett_price,
              isOverrideNett: workshopProducts[product.name.uid].isOverrideNett,
              isOverrideSell: workshopProducts[product.name.uid].isOverrideSell,
              isSplitedTax: workshopProducts[product.name.uid].is_splited_tax,
              sellPrice: {
                ...product.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[product.name.uid].price,
                formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                }).format(workshopProducts[product.name.uid].price)}`,
                isNoPriceAvailable: false,
                value: workshopProducts[product.name.uid].price,
              },
              nettPrice: {
                ...product.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[product.name.uid].nett_price,
                formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                }).format(workshopProducts[product.name.uid].nett_price)}`,
                isNoPriceAvailable: false,
                value: workshopProducts[product.name.uid].nett_price,
              },
              name: {
                ...product.name,
                price: workshopProducts[product.name.uid].price,
                nett_price: workshopProducts[product.name.uid].nett_price,
                calculated_price: workshopProducts[product.name.uid].total_price,
                attr: {
                  ...product.name.attr,
                  is_wi: workshopProducts[product.name.uid].is_wi,
                },
              },
            });
          }
        }
      });
    }

    if (cart.service && Array.isArray(cart.service)) {
      cart.service.forEach((service) => {
        const workshopProducts = wiProductObj[workshopId] || {};
        if (
          workshopProducts[service.name.product_id] ||
          workshopProducts[service.name.id] ||
          workshopProducts[service.name.uid]
        ) {
          if (service.formType === 'custom') {
            const customServiceObject = workshopProducts[service.name.id] ?? workshopProducts[service.name.uid];
            itemObj.service.push({
              ...service,
              is_wi: customServiceObject.is_wi,
              price: customServiceObject.price,
              final_sell_price: customServiceObject.final_sell_price,
              total_price: customServiceObject.total_price,
              nettPrice: customServiceObject.nett_price,
              isOverrideNett: customServiceObject.isOverrideNett,
              isOverrideSell: customServiceObject.isOverrideSell,
              isSplitedTax: customServiceObject.is_splited_tax,
              sellPrice: {
                ...service.sellPrice,
                formatIsMoney: true,
                formatMoney: customServiceObject.price,
                formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                }).format(customServiceObject.price)}`,
                isNoPriceAvailable: false,
                value: customServiceObject.price,
              },
              name: {
                ...service.name,
                price: {
                  ...service.price,
                  formatIsMoney: true,
                  formatMoney: customServiceObject.price,
                  formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                  }).format(customServiceObject.price)}`,
                  isNoPriceAvailable: false,
                  value: customServiceObject.price,
                },
                nettPrice: {
                  ...service.nettPrice,
                  formatIsMoney: true,
                  formatMoney: customServiceObject.nett_price,
                  formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                  }).format(customServiceObject.nett_price)}`,
                  isNoPriceAvailable: false,
                  value: customServiceObject.nett_price,
                },
                calculated_price: customServiceObject.total_price,
              },
              nett_price: customServiceObject.nett_price,
            });
          } else if (service.name.product_id !== 'mock_item') {
            itemObj.service.push({
              ...service,
              is_wi: workshopProducts[service.name.uid].is_wi,
              price: workshopProducts[service.name.uid].price,
              final_sell_price: workshopProducts[service.name.uid].final_sell_price,
              total_price: workshopProducts[service.name.uid].total_price,
              isOverrideNett: workshopProducts[service.name.uid].isOverrideNett,
              isOverrideSell: workshopProducts[service.name.uid].isOverrideSell,
              isSplitedTax: workshopProducts[service.name.uid].is_splited_tax,
              sellPrice: {
                ...service.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[service.name.uid].price,
                formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                }).format(workshopProducts[service.name.uid].price)}`,
                isNoPriceAvailable: false,
                value: workshopProducts[service.name.uid].price,
              },
              nettPrice: {
                ...service.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[service.name.uid].nett_price,
                formatMoneyString: `IDR ${new Intl.NumberFormat('id-ID', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                }).format(workshopProducts[service.name.uid].nett_price)}`,
                isNoPriceAvailable: false,
                value: workshopProducts[service.name.uid].nett_price,
              },
              name: {
                ...service.name,
                price: workshopProducts[service.name.uid].price,
                nett_price: workshopProducts[service.name.uid].nett_price,
                calculated_price: workshopProducts[service.name.uid].total_price,
                attr: {
                  ...service.name.attr,
                  is_wi: workshopProducts[service.name.uid].is_wi,
                },
              },
            });
          }
        }
      });
    }

    if (cart.package && Array.isArray(cart.package)) {
      cart.package.forEach((pkg) => {
        if (pkg.name && pkg.name.workshop_ids.includes(workshopId)) {
          const updatedPackage = {
            ...pkg,
            workshop_id: workshopId,
          };
          itemObj.package.push(updatedPackage);
        }
      });
    }

    setCart((prevCart) => {
      const updatedCart = {
        ...prevCart,
        product: itemObj.product,
        service: itemObj.service,
        package: itemObj.package,
      };
      return updatedCart;
    });
  };

  const handleChangeSPKFile = (e) => {
    const file = e.target.files[0];

    if (file)
      validateSPKFile(file).then(() => {
        handleUploadSPKFile(file);
      });
  };

  const handleUploadSPKFile = async (file) => {
    setLoadingUploadSPK(true);
    const paramsPresignedUpload = {
      namespace: 'booking',
      identifier: `${file?.name}-${moment().format('YYYYMMDDDHHmmss')}`,
      file_name: file?.name,
    };
    api
      .post(`v2/intools/presigned-upload/`, paramsPresignedUpload)
      .then((response) => {
        const urlUpload = response?.data?.data?.presigned_url ?? '';
        const keyFile = response?.data?.data?.key ?? '';

        if (urlUpload !== '') {
          uploadDocument(urlUpload, file, file?.name, keyFile);
        } else {
          handleResultNotification({
            status: true,
            type: 'failed',
            message: `Gagal, Fail get url upload file`,
          });
          console.log('failed get url of presigned document');
          setLoadingUploadSPK(false);
        }
      })
      .catch((e) => {
        console.log('failed upload presigned document');
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification({
          status: true,
          type: 'failed',
          message: `Gagal, ${message}`,
        });
        setLoadingUploadSPK(false);
      });
  };

  const validateSPKFile = async (file) => {
    const availableFormat = ['jpg', 'png', 'pdf', 'jpeg'];
    const fileSizeError = file?.size > 10000000;
    const fileType = file?.type?.split('/');
    const fileExtentionError = !availableFormat.includes(fileType[1]);
    let status = true;
    let errMsg = '';

    if (fileSizeError) {
      const msg = 'Ukuran maksimal file adalah 10MB';
      setErrorSPKFile(msg);
      errMsg = msg;
      status = false;
    } else if (fileExtentionError) {
      const msg = 'Ekstensi file tidak sesuai. ekstensi file yang diperbolehkan adalah jpg/jpeg/png/pdf';
      setErrorSPKFile(msg);
      errMsg = msg;
      status = false;
    } else if (!spkNumber && file?.name) {
      const msg = 'No. SPK harus diisi';
      setErrorSPKNumber(msg);
      errMsg = msg;
    } else if (spkNumber && !file?.name) {
      const msg = 'Dokumen SPK harus diisi';
      setErrorSPKNumber(msg);
      errMsg = msg;
    } else {
      setErrorSPKFile('');
      errMsg = '';
      status = true;
    }

    return { status, msg: errMsg };
  };

  const handleDeleteSPKFile = () => {
    setSPKFile();
    if (spkNumber) {
      setErrorSPKFile('Dokumen SPK harus diisi');
    } else {
      setErrorSPKFile('');
    }
    setErrorSPKNumber('');
  };

  const handleChangePromo = (e) => {
    const { value } = e.target;

    setPromo(value?.toUpperCase() ?? '');
    if (value?.length < 1) {
      callUseOtopointsFunc('promo null');
    }
    setPromoCodeResult({});
    setPromoStatus();
    setPromoData({});
  };

  const handleChangePromoPackageStatus = () => {
    setPromoPackageStatus();
  };

  const handleChangePromoPackageStatusEdit = () => {
    setPromoPackageStatusEdit();
  };

  const mappingPackage = (type) => {
    let packagesId = [];
    let servicesId = [];
    const updatedCart = updatedCalculatePayload ?? calculatePricePayload;

    const updatedItems = (updatedCart?.workshops ?? [])
      .flatMap((workshop) => workshop?.items ?? [])
      .filter((item, index, self) => index === self.findIndex((i) => i?.uid === item?.uid));

    const packageMapping =
      cart?.package?.length > 0
        ? map(cart.package, (item) => {
            let package_details = [];
            let note = ``;
            map(item.package_details, (packageDetails) => {
              package_details.push(Number(packageDetails?.value?.package_details_id));
              note = packageDetails?.note;
            });
            if (type === 'mappingSubmit') {
              return {
                note: note,
                package_qty: item?.package_qty,
                package_id: item?.name?.id,
                is_overide_sell: false,
                overide_sell_price: item?.sellPrice?.formatMoney,
                package_details,
              };
            }

            if (type === 'mappingPromo') {
              if (item?.promoData?.voucher_code !== undefined) {
                return {
                  package_id: item?.name?.id,
                  promo_code: item?.promoData?.voucher_code,
                  package_details,
                };
              }
            }

            if (type === 'mappingPromoSubmit') {
              if (item?.promoData?.voucher_code) {
                return {
                  package_id: item?.name?.id,
                  promo_code: item?.promoData?.voucher_code,
                };
              }
              return {};
            }
          })
        : [];

    const productMapping =
      cart?.product?.length > 0
        ? map(cart.product, (item) => {
            const updatedItem = updatedItems.find((x) => x?.uid == item?.name?.uid);
            if (item?.formType !== 'custom') {
              if (type === 'mappingSubmit') {
                return {
                  product_id: item?.name?.product_id,
                  is_fbo: item?.name?.is_fbo,
                  is_overide_sell_price: item?.isOverrideSell,
                  is_overide_nett_price: item?.isOverrideNett,
                  overide_sell_price: item?.sellPrice?.formatMoney,
                  overide_nett_price: item?.isSplitedTax ? item?.priceBeforeTax : item?.nettPrice?.formatMoney ?? 0,
                  qty: Number(item?.qty) ?? 0,
                  is_splited_tax: item?.isSplitedTax ?? updatedItem?.is_splited_tax,
                  is_workshop_inventory: item?.is_wi,
                  workshop_price: item?.isSplitedTax ? item?.priceBeforeTax : item?.nettPrice?.formatMoney,
                  workshop_price_after_tax: item?.isSplitedTax ? item?.nettPrice?.formatMoney : 0,
                  remark: item?.remarks,
                };
              }

              if (type === 'mappingPromo') {
                packagesId.push(Number(item?.name?.product_id));
              }

              if (type === 'mappingPromoSubmit') {
                if (item?.promoData?.voucher_code) {
                  return {
                    product_id: item?.name?.product_id,
                    promo_code: item?.promoData?.voucher_code,
                  };
                }
                return {};
              }
            }
          })
        : [];

    const serviceMapping =
      cart?.service?.length > 0
        ? map(cart.service, (item) => {
            const updatedItem = updatedItems.find((x) => x?.uid == item?.name?.uid);
            if (item?.formType !== 'custom') {
              if (type === 'mappingSubmit') {
                return {
                  product_id: item?.name?.product_id,
                  is_fbo: item?.name?.is_fbo,
                  is_overide_sell_price: item?.isOverrideSell,
                  is_overide_nett_price: item?.isOverrideNett,
                  overide_sell_price: item?.sellPrice?.formatMoney,
                  overide_nett_price: item?.isSplitedTax ? item?.priceBeforeTax : item?.nettPrice?.formatMoney ?? 0,
                  qty: Number(item?.qty),
                  is_splited_tax: item?.isSplitedTax ?? updatedItem?.is_splited_tax,
                  is_workshop_inventory: item?.is_wi,
                  workshop_price: item?.isSplitedTax ? item?.priceBeforeTax : item?.nettPrice?.formatMoney,
                  workshop_price_after_tax: item?.isSplitedTax ? item?.nettPrice?.formatMoney : 0,
                  remark: item?.remarks,
                };
              }

              if (type === 'mappingPromo') {
                servicesId.push(Number(item?.name?.product_id));
              }

              if (type === 'mappingPromoSubmit') {
                if (item?.promoData?.voucher_code) {
                  return {
                    product_id: item?.name?.product_id,
                    promo_code: item?.promoData?.voucher_code,
                  };
                }
                return {};
              }
            }
          })
        : [];

    const generateProductType = (type) => {
      switch (type) {
        case 'product':
          return 'part';
        case 'service':
          return 'work';
        default:
          break;
      }
    };

    const customMapping = () => {
      let customMappingList = [];
      cart?.service?.length > 0
        ? map(cart.service, (item) => {
            const updatedItem = updatedItems.find((x) => x?.uid == item?.name?.uid);
            if (item?.formType === 'custom') {
              customMappingList.push({
                type: generateProductType(item?.type),
                name: item?.name?.label,
                category_id: item?.categories?.value,
                qty: Number(item?.qty),
                workshop_price: item?.isSplitedTax ? item?.name?.priceBeforeTax : item?.name?.nettPrice?.formatMoney,
                workshop_price_after_tax: item?.isSplitedTax ? item?.name?.nettPrice?.formatMoney : 0,
                nett_price: item?.isSplitedTax ? item?.name?.priceBeforeTax : item?.nettPrice ?? 0,
                sell_price: item?.name?.price?.formatMoney,
                mark_up_percentage: item?.uprate?.value,
                discount_percentage: item?.discount?.value,
                is_splited_tax: item?.isSplitedTax ?? updatedItem?.is_splited_tax,
              });
            }
          })
        : [];

      cart?.product?.length > 0
        ? map(cart.product, (item) => {
            const updatedItem = updatedItems.find((x) => x?.uid == item?.name?.uid);
            if (item?.formType === 'custom') {
              customMappingList.push({
                type: generateProductType(item?.type),
                name: item?.name?.label,
                category_id: item?.categories?.value,
                qty: Number(item?.qty),
                workshop_price: item?.isSplitedTax ? item?.name?.priceBeforeTax : item?.name?.nettPrice?.formatMoney,
                workshop_price_after_tax: item?.isSplitedTax ? item?.name?.nettPrice?.formatMoney : 0,
                nett_price: item?.isSplitedTax ? item?.name?.priceBeforeTax : item?.nettPrice ?? 0,
                sell_price: item?.name?.price?.formatMoney,
                mark_up_percentage: item?.uprate?.value,
                discount_percentage: item?.discount?.value,
                is_splited_tax: item?.isSplitedTax ?? updatedItem?.is_splited_tax,
              });
            }
          })
        : [];

      return customMappingList.filter((x) => x !== undefined);
    };

    return {
      package: packageMapping.filter((x) => x !== undefined),
      product:
        type === 'mappingSubmit' || type === 'mappingPromoSubmit'
          ? productMapping.filter((x) => x !== undefined)
          : packagesId.filter((x) => x !== undefined),
      service:
        type === 'mappingSubmit' || type === 'mappingPromoSubmit'
          ? serviceMapping.filter((x) => x !== undefined)
          : servicesId.filter((x) => x !== undefined),
      custom: type === 'mappingSubmit' ? customMapping() : customMapping()?.length > 0 ? true : false,
    };
  };

  const callUseOtopointsFunc = (source = 'N/A') => {
    console.log('call use otopoints source -> ', source);
    if (useOtopoints) {
      setUseOtopoints(false);
    }
  };

  const checkPromoCode = (type) => {
    if (!disableAll && promoData?.voucher_code != promo) {
      api
        .get(`v2/intools/campaigns/voucher-campaign/${promo}/`)
        .then((response) => {
          const data = response?.data?.data ?? {};
          setPromoData(data);
          if (type === 'cart') calculatePromo();
        })
        .catch(() => {
          setPromoData({});
        });
    }
  };

  const calculatePromo = (items, type) => {
    if (!disableAll) {
      const params = {
        whole_cart_promo: !items?.package_id ? promo : '',
        user_car_id: selectedCar?.id,
        workshop_slug_name: selectedWorkshop
          ? selectedWorkshop?.slug
          : selectedWorkshops.length == 1
          ? selectedWorkshops[0].slug
          : null,
        customer_id: selectedUser?.id,
        packages: mappingPackage('mappingPromo').package,
        product_id: mappingPackage('mappingPromo').product,
        service_id: mappingPackage('mappingPromo').service,
        custom_products_filled: mappingPackage('mappingPromo').custom,
        customer_settlement_price: amount,
        tax_calculation: itemPackages,
        promo_context: {
          whole_cart: !items?.package_id ? promo : '',
        },
      };
      if (useOtopoints) {
        params.customer_settlement_price += usedOtopoints;
      }
      if (items?.package_id && type === 'package') {
        params.promo_context.package = items?.package_id ? items : {};
      }

      if (items && type === 'item') {
        params.promo_context.item = items ?? {};
      }

      if (!params.customer_id || !params.workshop_slug_name) {
        return;
      }

      api
        .post('v2/intools/promos/calculate', params)
        .then((response) => {
          const data = response?.data?.data;
          if (items?.package_id || items?.product_id) {
            if (items?.source === 'add') {
              setPromoPackageStatus({
                color: 'success',
                message: `Berhasil pakai promo ${data.promo_code} - ${data.promo_name}`,
                source: items?.source,
              });
            } else {
              setPromoPackageStatusEdit({
                color: 'success',
                message: `Berhasil pakai promo ${data.promo_code} - ${data.promo_name}`,
                source: items?.source,
              });
            }
          } else {
            if (data === 'promo_code is None!') {
              setPromoCodeResult(null);
              setPromoStatus({ color: 'danger', message: data });
            } else {
              setPromoCodeResult(data);
              setPromoStatus({
                color: 'success',
                message: `Berhasil pakai promo ${data.promo_code} - ${data.promo_name}`,
              });
            }
            callUseOtopointsFunc('success check promo code');
          }
        })
        .catch((error) => {
          if (items?.package_id || items?.product_id) {
            if (items?.source == 'add') {
              setPromoPackageStatus({
                color: 'danger',
                message: error?.response?.data?.error?.message,
                source: items?.source,
              });
            } else {
              setPromoPackageStatusEdit({
                color: 'danger',
                message: error?.response?.data?.error?.message,
                source: items?.source,
              });
            }
          } else {
            setPromoCodeResult({});
            setPromoStatus({ color: 'danger', message: error?.response?.data?.error?.message });
            callUseOtopointsFunc('failed check promo code');
          }
        });
    }
  };

  const handleChangeOtopoints = (e) => {
    const { checked } = e.target;
    setUseOtopoints(checked);
  };

  const handleChangeInternalNotes = (e) => {
    const { value } = e.target;

    setInternalNotes(value);

    if (value?.length > 500) {
      setErrorInternalNotes('Maksimal karakter internal notes adalah 500');
    } else {
      setErrorInternalNotes('');
    }
  };

  const handleAddCart = (items) => {
    setOverrideList((prev) => {
      let updatedList = [...prev];
      for (const item of items.product ?? []) {
        const prevObj = prev.find((e) => e.uid === item.name?.uid ?? 'nan');
        if (prevObj) {
          const index = updatedList.findIndex((e) => e.uid === prevObj.uid);

          updatedList[index] = { ...prevObj, isOverrideNett: item.isOverrideNett, isOverrideSell: item.isOverrideSell };
        }
      }

      for (const item of items.service ?? []) {
        const prevObj = prev.find((e) => e.uid === item.name?.uid ?? 'nan');
        if (prevObj) {
          const index = updatedList.findIndex((e) => e.uid === prevObj.uid);

          updatedList[index] = { ...prevObj, isOverrideNett: item.isOverrideNett, isOverrideSell: item.isOverrideSell };
        }
      }

      return updatedList;
    })

    setCart(items);
    if (errorCart) setErrorCart('');
  };

  const handleMultipleWorkshopAddCart = (items) => {
    setMultipleWorkshopCart(items);
    setCart(items);
    if (errorCart) setErrorCart('');
  };

  const handleB2BDraftAddCart = (items) => {
    handleMultipleWorkshopAddCart(items);
    if (errorCart) setErrorCart('');
  };

  const validateCart = async (data) => {
    let status = true;

    if (data?.package?.length < 1 && data?.product?.length < 1 && data?.service?.length < 1) {
      setErrorCart('Harus ada paket, item produk atau jasa yang dipilih');
      status = false;
    } else {
      setErrorCart('');
    }

    return status;
  };

  const handleChangeDuplicateBooking = (e) => {
    const { checked } = e.target;

    setIsDuplicateBooking(checked);
  };

  const handlePreSubmitCreateBooking = async () => {
    if (spkFile && spkFile?.type) {
      if (!errorSPKFile) {
        const paramsPresignedUpload = {
          namespace: 'booking',
          identifier: selectedCar.id,
          file_name: spkFile?.name,
        };
        api
          .post(`v2/intools/presigned-upload/`, paramsPresignedUpload)
          .then((response) => {
            const urlUpload = response?.data?.data?.presigned_url ?? '';
            const keyFile = response?.data?.data?.key ?? '';

            if (urlUpload !== '') {
              uploadDocument(urlUpload, spkFile?.file, spkFile?.name, keyFile);
            } else {
              handleResultNotification({
                status: true,
                type: 'failed',
                message: `Gagal, Fail get url upload file`,
              });
              console.log('failed get url of presigned document');
            }
          })
          .catch((e) => {
            console.log('failed upload presigned document');
            const message = `API Error : ${e?.response?.data?.error?.message}`;
            handleResultNotification({
              status: true,
              type: 'failed',
              message: `Gagal, ${message}`,
            });
          });
      } else {
        let notification = {
          status: true,
          type: '',
          message: '',
        };

        notification = {
          ...notification,
          type: 'fail',
          message: `Gagal, ${errorSPKFile}`,
        };

        handleResultNotification(notification);
        return;
      }
    } else {
      handleSubmitBooking();
    }
  };

  const uploadDocument = (url, file, fileName, keyFile) => {
    apiS3
      .put(url, file)
      .then(() => {
        setSPKFile(file);
        setSPKFileKey(keyFile);
        setSPKFileUrl(url);
        setLoadingUploadSPK(false);
      })
      .catch((e) => {
        console.log('failed to upload document');
        handleResultNotification({
          status: true,
          type: 'failed',
          message: 'Gagal, mengunggah dokumen. coba sesaat lagi',
        });
        setLoadingUploadSPK(false);
      });
  };

  const validate = (isCreateB2BDraft = false) => {
    let status = true;
    let message = '';

    if (!allowSave) {
      let notification = {
        status: true,
        type: '',
        message: '',
      };

      notification = {
        ...notification,
        type: 'fail',
        message: 'Gagal, tidak ada akses untuk menyimpan data. hubungi admin.',
      };
      handleResultNotification(notification);
    }

    if (!selectedUser) {
      const msg = 'Customer harus diisi';
      setErrorUser(msg);
      message = msg;
      status = false;
    }

    if (!isECommerce) {
      if (!selectedCar) {
        const msg = 'No. Plat Mobil harus diisi';
        setErrorCar(msg);
        message = msg;
        status = false;
      }

      if (!selectedDate) {
        const msg = 'Tanggal harus diisi';
        setErrorDate(msg);
        message = msg;
        status = false;
      }

      if (!selectedTime) {
        const msg = 'Waktu harus diisi';
        setErrorTime(msg);
        message = msg;
        status = false;
      }

      if (!selectedWorkshop && !isCreateB2BDraft) {
        const msg = 'Bengkel harus dipilih';
        setErrorWorkshop(msg);
        message = msg;
        status = false;
      }

      if (selectedWorkshop?.operational_time?.is_closed) {
        const msg = 'Bengkel tutup, pilih bengkel lain';
        setErrorWorkshop(msg);
        message = msg;
        status = false;
      }
    }

    if (isECommerce) {
      if (isEmpty(selectedCar) && !isEmpty(selectedWorkshop)) {
        const msg = 'No. Plat Mobil harus diisi';
        setErrorCar(msg);
        message = msg;
        status = false;
      }

      if (!selectedDate && !isEmpty(selectedWorkshop)) {
        const msg = 'Tanggal harus diisi';
        setErrorDate(msg);
        message = msg;
        status = false;
      }

      if (isEmpty(selectedTime) && !isEmpty(selectedWorkshop)) {
        const msg = 'Waktu harus diisi';
        setErrorTime(msg);
        message = msg;
        status = false;
      }
    }

    if (isPUDO) {
      if (mapValue.latitude === monasLocation.lat && mapValue.longitude === monasLocation.lng) {
        const msg = 'Map harus dipilih';
        setErrorMap(msg);
        message = msg;
        status = false;
      }

      if (!detailAddress) {
        const msg = 'Rincian Alamat harus diisi';
        setErrorDetailAddress(msg);
        message = msg;
        status = false;
      }
    }

    if (!selectedBookingSource && !selectedUser?.b2b_customer) {
      const msg = 'Booking Source harus diisi';
      setErrorBookingSource(msg);
      message = msg;
      status = false;
    }

    validateCart(cart).then((cartStatus) => {
      if (!cartStatus) {
        message = 'Harus ada paket, produk atau jasa yang dipilih';
        status = false;
      }
    });

    if (selectedUser?.isB2B) {
      if (spkNumber.length > 100) {
        const msg = 'Maksimal karakter 100';
        message = msg;
        setErrorSPKNumber(msg);
        status = false;
      } else if (!spkNumber && spkFile?.name) {
        const msg = 'Nomor SPK harus diisi';
        message = msg;
        setErrorSPKNumber(msg);
        status = false;
      } else if (spkNumber && !spkFile?.name) {
        const msg = 'Dokumen SPK harus diisi';
        setErrorSPKFile(msg);
        message = msg;
        status = false;
      }

      if (spkFile && spkFile?.type) {
        validateSPKFile(spkFile).then((resp) => {
          if (!resp?.status) {
            message = resp?.msg;
            status = false;
          }
        });
      }
    }

    if (!isCreateB2BDraft) {
      if (cart?.product?.length > 0) {
        map(cart?.product, (item) => {
          if (item?.isSplitedTax == true && selectedWorkshop?.is_pkp == false) {
            status = false;
            message =
              'mohon edit produk custom karena terjadi perbedaan config pada splited tax dan config pada workshop pkp berbeda. atau ganti kembali pilihan workshop pada workshop pkp.';
          }
        });
      }

      if (cart?.service?.length > 0) {
        map(cart?.service, (item) => {
          if (item?.isSplitedTax == true && selectedWorkshop?.is_pkp == false) {
            status = false;
            message =
              'mohon edit jasa custom karena terjadi perbedaan config pada splited tax dan config pada workshop pkp berbeda. atau ganti kembali pilihan workshop pada workshop pkp.';
          }
        });
      }
    }

    return { status, message };
  };

  const findWorkshop = (data) => {
    const workshopsWithPackage = data.filter((workshop) => {
      return workshop.customer.items.some((item) => item.package_id);
    });

    let selectedWorkshop;

    if (workshopsWithPackage.length > 0) {
      selectedWorkshop = workshopsWithPackage.reduce((prev, current) => {
        return prev.customer.total_amount > current.customer.total_amount ? prev : current;
      });
    } else {
      selectedWorkshop = data.reduce((prev, current) => {
        return prev.customer.total_amount > current.customer.total_amount ? prev : current;
      });
    }

    if (!selectedWorkshop) {
      return null;
    }
    setTotalAmountB2B(selectedWorkshop.customer.price_before_tax);
    setTotalAmount(selectedWorkshop.customer.price_before_tax);
    return {
      workshop_id: selectedWorkshop.workshop_id,
      workshop_slug: selectedWorkshop.workshop_slug,
    };
  };

  const handleSubmitBookingB2B = (b2bDraft = false, bookingStatusCondition = 'review', updateChanges = false) => {
    let selectedTemporaryWorkshop = null;
    if (selectedUser?.isB2B && bookingStatusCondition !== 'Menunggu Kedatangan') {
      selectedTemporaryWorkshop = findWorkshop(multipleCalculateCartData);
      if (selectedTemporaryWorkshop !== null) {
        autoUpdateCart(selectedTemporaryWorkshop.workshop_id);
        setSelectedTemporaryWorkshop(selectedTemporaryWorkshop);
      }
    }

    setIsSubmitBooking(true);
    setIsSubmitB2BDraft(b2bDraft);
    setBookingStatusConditionSlug(bookingStatusCondition);
    setIsBookingUpdateChanges(updateChanges);
  };

  useEffect(() => {
    if (isSubmitBooking) {
      handleSubmitBooking(isSubmitB2BDraft, bookingStatusConditionSlug, isBookingUpdateChanges);
    }
  }, [isSubmitBooking]);

  const handleUpdateCartItemQty = () => {
    if (overrideQty !== undefined) {
      cart.product.forEach((prod) => {
        if (prod?.formType !== 'custom') {
          prod.qty = overrideQty[prod?.name?.product_id] || prod.qty;
        } else {
          prod.qty = overrideQty[prod?.name?.id] || prod.qty;
        }
      });

      cart.service.forEach((svc) => {
        if (svc?.formType !== 'custom') {
          svc.qty = overrideQty[svc?.name?.product_id] || svc.qty;
        } else {
          svc.qty = overrideQty[svc?.name?.id] || svc.qty;
        }
      });
    }
  };

  const handlePriceChange = (
    workshopId,
    productId,
    price = null,
    nettPrice = null,
    originalPrice = null,
    originalNettPrice = null,
    splitTax = false,
    nettPriceAfterTax = null,
    updateFor = 'nettPrice',
    uid = null
  ) => {
    setOverrideList((prevList) => {
      const updatedList = [...prevList];
      const existingIndex = updatedList.findIndex((item) => item.uid == uid);

      // Create a new object based on existing data if found
      const newOverrideObj =
        existingIndex > -1
          ? { ...updatedList[existingIndex] }
          : {
              workshop_id: workshopId,
              product_id: productId,
              originalPrice: originalPrice,
              originalNettPrice: originalNettPrice,
              uid: uid,
            };

      // Update the price and isOverrideSell only if price is not null
      if (updateFor == 'price') {
        if (price !== null && !isNaN(price) && price !== '') {
          newOverrideObj.price = parseInt(price);
          newOverrideObj.isOverrideSell = originalPrice !== parseInt(price); // Simplified
        } else {
          delete newOverrideObj.price;
          delete newOverrideObj.isOverrideSell;
        }
      } else if (updateFor == 'allPrice') {
        newOverrideObj.price = price;
        newOverrideObj.isOverrideSell = originalPrice !== price;
        newOverrideObj.nettPrice = {
          formatIsMoney: false,
          formatMoney: nettPrice,
          formatMoneyString: `IDR ${Helper.formatMoney(nettPrice)}`,
          isNoPriceAvailable: nettPrice < 0 ? true : false,
          value: nettPrice,
        };

        newOverrideObj.isOverrideNett = nettPrice !== originalNettPrice;
        newOverrideObj.isSplitedTax = splitTax;
        if (splitTax) {
          newOverrideObj.nettPriceAfterTax = nettPriceAfterTax;
        }
      } else {
        if (nettPrice !== null || !isNaN(nettPrice?.value)) {
          if (typeof nettPrice === 'string' && !isNaN(nettPrice) && nettPrice !== '') {
            newOverrideObj.nettPrice = {
              formatIsMoney: false,
              formatMoney: parseInt(nettPrice),
              formatMoneyString: `IDR ${Helper.formatMoney(nettPrice)}`,
              isNoPriceAvailable: nettPrice < 0 ? true : false,
              value: parseInt(nettPrice),
            };
            newOverrideObj.isOverrideNett = parseInt(nettPrice) !== originalNettPrice;
            newOverrideObj.isSplitedTax = splitTax;
            newOverrideObj.price = Helper.standardizeSellPriceCalculation(newOverrideObj?.nettPrice?.formatMoney ?? 0);
            newOverrideObj.isOverrideSell = true;
            if (splitTax) {
              // newOverrideObj.nettPriceAfterTax = nettPriceAfterTax
              // newOverrideObj.price = Helper.standardizeSellPriceCalculation(newOverrideObj?.nettPriceAfterTax ?? 0);
              // newOverrideObj.isOverrideSell = true;
            }
          } else if (typeof nettPrice !== 'string' && !isNaN(nettPrice?.value)) {
            newOverrideObj.nettPrice = nettPrice;
            newOverrideObj.isOverrideNett = nettPrice?.value !== originalNettPrice;
            newOverrideObj.isSplitedTax = splitTax;
            newOverrideObj.price = Helper.standardizeSellPriceCalculation(newOverrideObj?.nettPrice?.formatMoney ?? 0);
            newOverrideObj.isOverrideSell = true;
            if (splitTax) {
              // newOverrideObj.nettPriceAfterTax = nettPriceAfterTax
              // newOverrideObj.price = Helper.standardizeSellPriceCalculation(newOverrideObj?.nettPriceAfterTax ?? 0);
              // newOverrideObj.isOverrideSell = true;
            }
          } else {
            delete newOverrideObj.nettPrice;
            delete newOverrideObj.isOverrideNett;
            delete newOverrideObj.isSplitedTax;
            delete newOverrideObj.price;
            delete newOverrideObj.isOverrideSell;
            delete newOverrideObj.nettPriceAfterTax;
          }
        }
      }

      if (existingIndex > -1) {
        // Update existing object
        updatedList[existingIndex] = newOverrideObj;
      } else {
        // Add new object
        updatedList.push(newOverrideObj);
      }

      return updatedList;
    });
  };

  const handleSubmitBooking = async (b2bDraft = false, bookingStatusCondition = 'review', updateChanges = false) => {
    if (selectedUser?.isB2B && (selectedWorkshops.length > 1 || selectedWorkshops.length == 0) && !b2bDraft) {
      handleIsSelectedWorkshopPriceModal();
      setIsSubmitBooking(false);
    } else {
      setSubmitLoading(true);
      setIsSubmitB2BDraft(false);
      setIsSubmitBooking(false);

      let notification = {
        status: true,
        type: '',
        message: '',
      };

      const preSubmitValidation = validate(b2bDraft);
      if (!preSubmitValidation?.status) {
        notification = {
          ...notification,
          type: 'fail',
          message: preSubmitValidation?.message ?? 'Gagal, field mandatory harus diisi!',
        };
        handleResultNotification(notification);
        setSubmitLoading(false);
        return;
      }

      const hasChooseMap = mapValue?.latitude !== monasLocation?.lat && mapValue?.longitude !== monasLocation?.lng;
      const reformatDateTime = `${selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}${
        selectedTime ? ` ${selectedTime?.value}:00` : ''
      }`;

      handleUpdateCartItemQty();

      let payload = {
        booking_datetime: selectedDate && selectedTime ? reformatDateTime : '',
        use_otopoints: useOtopoints,
        note: internalNotes,
        customer_id: selectedUser?.id,
        user_car_id: selectedCar?.id,
        mileage: carMilleage?.formatMoney,
        is_ecommerce: isECommerce,
        fresdesk_ticket_link: freshDeskLink,
        booking_source_slug: selectedBookingSource?.slug ? selectedBookingSource?.slug : (selectedUser?.b2b_customer ? 'b2b' : null),
        spk_number: spkNumber,
        is_pudo: isPUDO,
        customer_settlement_price: totalAmount ?? 0,
        customer_location_address: {
          label: '',
          address1: hasChooseMap ? mapValue?.street_address : '',
          address2: detailAddress,
          latitude: hasChooseMap ? mapValue?.latitude : '',
          longitude: hasChooseMap ? mapValue?.longitude : '',
        },
        workshop_slug_name:
          selectedWorkshops.length > 1 && selectedTemporaryWorkshop !== null
            ? selectedTemporaryWorkshop.workshop_slug
            : selectedWorkshop?.slug,
        packages: mappingPackage('mappingSubmit').package,
        products: mappingPackage('mappingSubmit').product,
        services: mappingPackage('mappingSubmit').service,
        custom_products: mappingPackage('mappingSubmit').custom,
        booking_origin: 'Intools',
        tax: ppn,
        service_tax: pph,
        price_after_tax: amount,
        price_before_tax: dpp,
        rounding: rounding,
        grand_total: grandTotal,
        promo_codes: {
          whole_cart_promo: promoCodeResult?.promo_code,
        },
        booking_b2b_draft: {
          workshops: selectedWorkshops,
          cart: cart,
          payload: updatedCalculatePayload ?? calculatePricePayload,
        },
        // is_b2b_draft: b2bDraft,
      };

      if (selectedUser?.isB2B) {
        assign(payload, {
          is_b2b_draft: bookingStatusCondition === 'confirm' ? false : true,
          booking_status_slug: bookingStatusCondition,
        });

        payload.promo_codes.selected_items = [
          ...mappingPackage('mappingPromoSubmit').package,
          ...mappingPackage('mappingPromoSubmit').product,
          ...mappingPackage('mappingPromoSubmit').service,
        ];
      } else {
        payload.promo_codes.selected_packages = mappingPackage('mappingPromo').package;
      }

      if (spkFile?.name && spkFileKey)
        assign(payload, {
          documents: [
            {
              type: 'spk',
              label: spkFile?.name ?? '',
              value: spkFileKey,
            },
          ],
        });

      if (isFleetMode)
        assign(payload, {
          event_type: 'submit-estimation',
          booking_code: detailFleet?.booking_code,
          is_b2b_draft: false,
        });

      if (isB2BDraft) {
        assign(payload, {
          event_type: updateChanges ? 'update-changes' : 'submit-estimation',
          booking_code: b2bDraftData?.booking_code,
          booking_status_slug: bookingStatusCondition,
        });
      }

      // setOverrideList([]);
      // setOverrideQty();

      if (isFleetMode || isB2BDraft) {
        await api
          .put('/v2/intools/bookings/', payload)
          .then((res) => {
            handelResponseSubmit(res);
          })
          .catch((err) => {
            handleErrorSubmit(err);
          });
      } else {
        await api
          .post('/v2/intools/bookings/', payload)
          .then((res) => {
            handelResponseSubmit(res);
          })
          .catch((err) => {
            handleErrorSubmit(err);
          });
      }
    }
  };

  const handleSubmitB2BDraft = async (method = 'create') => {
    setSubmitLoading(true);

    let notification = {
      status: true,
      type: '',
      message: '',
    };

    const preSubmitValidation = validate(true);
    if (!preSubmitValidation?.status) {
      notification = {
        ...notification,
        type: 'fail',
        message: preSubmitValidation?.message ?? 'Gagal, field mandatory harus diisi!',
      };
      handleResultNotification(notification);
      setSubmitLoading(false);
      return;
    }

    const hasChooseMap = mapValue?.latitude !== monasLocation?.lat && mapValue?.longitude !== monasLocation?.lng;
    const reformatDateTime = `${selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}${
      selectedTime ? ` ${selectedTime?.value}:00` : ''
    }`;

    handleUpdateCartItemQty();

    let payload = {
      booking_code: b2bDraftData ? b2bDraftData?.booking_code : null,
      booking_datetime: selectedDate && selectedTime ? reformatDateTime : '',
      use_otopoints: useOtopoints,
      note: internalNotes,
      customer_id: selectedUser?.id,
      user_car_id: selectedCar?.id,
      mileage: carMilleage?.formatMoney,
      is_ecommerce: isECommerce,
      fresdesk_ticket_link: freshDeskLink,
      booking_source_slug: selectedBookingSource?.slug,
      spk_number: spkNumber,
      is_pudo: isPUDO,
      customer_settlement_price: totalAmount ?? 0,
      customer_location_address: {
        label: '',
        address1: hasChooseMap ? mapValue?.street_address : '',
        address2: detailAddress,
        latitude: hasChooseMap ? mapValue?.latitude : '',
        longitude: hasChooseMap ? mapValue?.longitude : '',
      },
      booking_b2b_draft: {
        workshops: selectedWorkshops,
        cart: cart,
        payload: updatedCalculatePayload ?? calculatePricePayload,
      },
    };

    if (spkFile?.name && spkFileKey)
      assign(payload, {
        documents: [
          {
            type: 'spk',
            label: spkFile?.name ?? '',
            value: spkFileKey,
          },
        ],
      });

    if (method === 'create') {
      await dispatch(createBookingB2BDraft(payload))
        .then((action) => {
          if (action.type === 'booking/createBookingB2BDraft/fulfilled') {
            handelResponseSubmit(action.payload?.data, true);
          } else if (action.type === 'booking/createBookingB2BDraft/rejected') {
            const err = action.payload || action.error.message;
            if (err?.response?.status === 400) {
              console.log('Error 400:', err);
              handleErrorSubmit(err, true);
            } else {
              console.log(err, 'err');
              handleErrorSubmit(err, true);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'Unexpected error');
          handleErrorSubmit(err);
        });
    } else {
      await dispatch(updateBookingB2BDraft(payload))
        .then((action) => {
          if (action.type === 'booking/updateBookingB2BDraft/fulfilled') {
            handelResponseSubmit(action.payload?.data, true);
          } else if (action.type === 'booking/updateBookingB2BDraft/rejected') {
            const err = action.payload || action.error.message;
            if (err?.response?.status === 400) {
              console.log('Error 400:', err);
              handleErrorSubmit(err, true);
            } else {
              console.log(err, 'err');
              handleErrorSubmit(err, true);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'Unexpected error');
          handleErrorSubmit(err);
        });
    }
  };

  const setTaxCalculation = (data) => {
    setItemPackages(data);
  };

  const handelResponseSubmit = (res, isB2BDraft = false) => {
    var data;
    if (isB2BDraft) {
      data = res;
    } else {
      data = res.data.data;
    }

    let notification = {
      status: true,
      type: 'success',
      message: 'Sukses',
    };
    handleResultNotification(notification).then(() => {
      setIsSuccessSubmit(true);
      resetField('', selectedUser);
      setTimeout(() => {
        setIsSuccessSubmit(false);
      }, 1000);

      setTimeout(() => {
        if (isDuplicateBooking) {
          window.open(`/bookings/${data.booking_code}`, '_blank');
        } else {
          history.push(`/bookings/${data.booking_code}`);
        }
      }, 1000);
    });

    setSubmitLoading(false);
  };

  const handleErrorSubmit = (err, isB2BDraft = false) => {
    var errRes;
    if (isB2BDraft) {
      console.log('failed create booking => ', err);
      errRes = err?.data?.error?.message;
    } else {
      console.log('failed create booking => ', err.response);
      errRes = err?.response?.data?.error?.message;
    }

    let notification = {
      status: true,
      type: 'fail',
      message: `Gagal, ${errRes}`,
    };

    handleResultNotification(notification);
    setSubmitLoading(false);
  };

  const handleOverrideList = () => {
    const overrideData = b2bDraftData?.b2b_draft?.tmp_override_price || [];

    setOverrideList((prevList) => {
      const updatedList = [...prevList];

      overrideData.forEach((item) => {
        const { workshop_id, product_id, price, nett_price, remarks, is_splited_tax, nett_price_after_tax, uid, workshop_price_after_tax } = item;
        const existingIndex = updatedList.findIndex(
          (existingItem) => existingItem.workshop_id === workshop_id && existingItem.uid === uid
        );

        const newOverrideObj =
          existingIndex > -1
            ? { ...updatedList[existingIndex] }
            : {
                uid: uid,
                workshop_id: workshop_id,
                product_id: product_id,
                prevPrice: updatedList[existingIndex]?.price || null,
                remarks: remarks || '',
              };

        if (price !== null && !isNaN(price)) {
          newOverrideObj.price = parseInt(price);
          newOverrideObj.isOverrideSell = typeof product_id === 'string' ? false : true;
        }

        const overrideNetValue = Number.isInteger(nett_price) ? nett_price : nett_price?.formatMoney || null;
        if ((nett_price !== null && !isNaN(nett_price)) || (overrideNetValue && Number.isInteger(overrideNetValue))) {
          newOverrideObj.nettPrice = {
            formatIsMoney: false,
            formatMoney: overrideNetValue,
            formatMoneyString: `IDR ${Helper.formatMoney(overrideNetValue)}`,
            isNoPriceAvailable: overrideNetValue < 0 ? true : false,
            value: overrideNetValue,
          };

          newOverrideObj.isSplitedTax = is_splited_tax;
          newOverrideObj.isOverrideNett = typeof product_id === 'string' ? false : true;
          if (is_splited_tax) newOverrideObj.nettPriceAfterTax = nett_price_after_tax ?? workshop_price_after_tax;
        }

        if (existingIndex > -1) {
          updatedList[existingIndex] = newOverrideObj;
        } else {
          updatedList.push(newOverrideObj);
        }
      });

      return updatedList;
    });
  };

  const resetField = (flag, user) => {
    loadBookingSource(user?.isB2B ? 'b2b' : 'default,otobuddy');
    setIsECommerce(false);
    setSelectedCar();
    setCarMilleage({
      formatIsMoney: false,
      formatMoney: 0,
      formatMoneyString: '0',
    });
    setIsPUDO(false);
    setMapValue({
      latitude: monasLocation.lat,
      longitude: monasLocation.lng,
      street_address: 'Monas, Jakarta Pusat',
    });
    setDetailAddress('');
    setSelectedBookingSource(null);
    setFreshDeskLink('');
    setSelectedDate();
    setSelectedTime();
    setUseOtopoints(false);
    setInternalNotes('');
    setPromo('');
    setPromoData({});
    setPromoCodeResult({});
    setPromoStatus();
    setIsDuplicateBooking(false);
    if (flag !== 'change user') loadCustomerDetails(user?.id);

    if (flag === 'change user') {
      setProcessRemoveCart(true);
      setCart();
      setSelectedWorkshop();
      setTimeout(() => {
        setProcessRemoveCart(false);
      }, 100);
    }
    setTempBookingData();
  };

  useEffect(() => {
    if (isB2BDraft && !isInitializedB2BDraftWorkshops && selectedUser) {
      setSelectedWorkshops(b2bDraftData?.b2b_draft?.workshops);
      setIsInitializedB2BDraftWorkshops(true);
      setInternalNotes(b2bDraftData?.booking_note ?? '');
      handleOverrideList();
    } else {
      setSelectedWorkshops(selectedWorkshops);
    }
  }, [selectedWorkshops, b2bDraftData, isB2BDraft, selectedUser]);

  // useEffect(() => {
  //   dispatch(setIsDisableCreateBookingButton(true));
  // }, [selectedWorkshops]);

  useEffect(() => {
    if (selectedWorkshops.length == 1) {
      setSelectedWorkshop(selectedWorkshops[0]);
    }
  }, [selectedWorkshops]);

  useEffect(() => {
    handleSetBookingSource();
  }, [isFleetMode, bookingSourceOptions, isB2BDraft]);

  useEffect(() => {
    if (detailFleet?.documents?.length > 0) {
      const file = {
        name: detailFleet?.documents[0]?.filename,
        url: detailFleet?.documents[0]?.url,
      };
      setSPKFile(file);
    }
    setSPKNumber(detailFleet?.spk_number ?? '');
  }, [isFleetMode]);

  useEffect(() => {
    if (b2bDraftData?.documents?.length > 0) {
      const SPKFile = b2bDraftData?.documents?.find((doc) => doc.document_type === 'spk');
      if (SPKFile) {
        const file = {
          name: SPKFile?.filename,
          url: SPKFile?.url,
        };
        setSPKFile(file);
        setSPKNumber(b2bDraftData?.spk_number ?? '');
      }
    }
  }, [isB2BDraft]);

  useEffect(() => {
    loadBookingSource(isECommerce ? 'ecommerce' : selectedUser?.isB2B ? 'b2b' : 'default,otobuddy');
  }, [isECommerce, selectedUser?.isB2B]);

  useEffect(() => {
    if (detailFleet && id) {
      handleDetailFleet();
    }
  }, [detailFleet]);

  useEffect(() => {
    if (b2bDraftData && id && b2bDraft) {
      handleDetailB2BDraft();
    }
  }, [b2bDraftData]);

  useEffect(() => {
    const tempPackages = {
      ...cart?.package[+cart?.package?.length - 1],
      source: 'edit',
    };
    calculatePromo(tempPackages);
  }, [cart?.package]);

  useEffect(() => {
    Geocode.setApiKey(GMAPS_API_KEY);
    if (!allowShow) {
      history.push('/404');
    }
    if (id && allowShow) {
      if (b2bDraft) {
        loadDetailB2BDraft(id);
      } else {
        loadDetailFleet(id);
      }
    }

    setTimeout(() => {
      setMapValue({
        latitude: monasLocation.lat,
        longitude: monasLocation.lng,
        street_address: 'Monas, Jakarta Pusat',
      });
    }, 200);
  }, []);

  useEffect(() => {
    if (isB2BDraft && !isInitializedB2BDraftCart) {
      handleB2BDraftAddCart(b2bDraftData?.b2b_draft?.cart);
      setIsInitializedB2BDraftCart(true);
      handleDetailB2BDraftBookingStatus();
    }
  }, [b2bDraftData, isB2BDraft]);

  if (loading) {
    return <LoadingWrapper />;
  }

  return (
    <>
      <Wrapper
        title="Buat Booking / Order"
        breadcrumbData={breadcrumb}
        showNotification={hasNotification}
        notification={notification}
        onCloseNotification={handleCloseNotification}
      >
        <Row>
          <Col lg={6}>
            <BookingCustomerDetailForms
              isB2BDraft={isB2BDraft}
              accountType={accountType}
              mapValue={mapValue}
              handleChangeUser={handleChangeUser}
              onClickCreateNewUser={onClickCreateNewUser}
              handleClickMap={handleClickMap}
              handleClickPUDO={handleClickPUDO}
              handleClickECommerce={handleClickECommerce}
              isPUDO={isPUDO}
              isECommerce={isECommerce}
              isFleetMode={isFleetMode}
              onLoadUserDetail={loadUserDetail}
              selectedUser={selectedUser}
              loadingSearchUser={loadingSearchUser}
              selectedCar={selectedCar}
              loadingSearchCar={loadingSearchCar}
              disableCar={disableCar}
              loadUserCar={loadUserCarOptions}
              handleChangeCar={handleChangeCar}
              disableAll={disableAll}
              disableTime={disableTime}
              defaultCarOptions={carOptions}
              bookingSourceOptions={bookingSourceOptions}
              handleChangeBookingSource={handleChangeBookingSource}
              selectedBookingSource={selectedBookingSource}
              disableECommerce={disableECommerce}
              handleChangeCarMilleage={handleChangeCarMilleage}
              carMilleage={carMilleage}
              errorCarMilleage={errorCarMilleage}
              handleChangeAddressDetail={handleChangeAddressDetail}
              detailAddress={detailAddress}
              handleChangeFreshDeskLink={handleChangeFreshDeskLink}
              freshDeskLink={freshDeskLink}
              handleChangeDate={handleChangeDate}
              selectedDate={selectedDate}
              handleChangeTime={handleChangeTime}
              selectedTime={selectedTime}
              errorUser={errorUser}
              errorCar={errorCar}
              errorDetailAddress={errorDetailAddress}
              errorBookingSource={errorBookingSource}
              errorDate={errorDate}
              errorTime={errorTime}
              errorMap={errorMap}
              isSuccessSubmit={isSuccessSubmit}
              isOpen={hasGMapModal}
              toggle={toggleGMapModal}
              lat={mapValue?.latitude}
              lng={mapValue?.longitude}
              handleMap={handleChangeMap}
              selectedWorkshop={selectedWorkshop}
            />

            <BookingWorkshop
              multipleCalculateCartData={multipleCalculateCartData}
              cart={cart}
              handleAddCart={handleAddCart}
              selectedWorkshops={selectedWorkshops}
              setSelectedWorkshops={setSelectedWorkshops}
              isECommerce={isECommerce}
              disableAll={disableAll}
              selectedWorkshop={selectedWorkshop}
              toggleHasModalChooseWorkshop={toggleHasModalChooseWorkshop}
              handleChangeWorkshop={handleChangeWorkshop}
              errorWorkshop={errorWorkshop}
            />

            <BookingDetailServices
              overrideList={overrideList}
              handlePriceChange={handlePriceChange}
              cart={cart}
              selectedWorkshops={selectedWorkshops}
              setMultipleWorkshopCart={setMultipleWorkshopCart}
              multipleWorkshopCart={multipleWorkshopCart}
              accountType={accountType}
              selectedUser={selectedUser}
              toggleHasModalChooseWorkshop={toggleHasModalChooseWorkshop}
              selectedWorkshop={selectedWorkshop}
              selectedCar={selectedCar}
              handleAddCart={handleAddCart}
              handleChangeSPKName={handleChangeSPKName}
              spkNumber={spkNumber}
              handleChangeSPKFile={handleChangeSPKFile}
              handleDeleteSPKFile={handleDeleteSPKFile}
              spkFile={spkFile}
              loadingUploadSPK={loadingUploadSPK}
              checkPromoCode={checkPromoCode}
              calculatePromo={calculatePromo}
              handleChangePromo={handleChangePromo}
              handleChangePromoPackageStatus={handleChangePromoPackageStatus}
              handleChangePromoPackageStatusEdit={handleChangePromoPackageStatusEdit}
              promo={promo}
              promoStatus={promoStatus}
              promoPackageStatus={promoPackageStatus}
              promoPackageStatusEdit={promoPackageStatusEdit}
              promoCodeResult={promoCodeResult}
              promoData={promoData}
              handleChangeOtopoints={handleChangeOtopoints}
              useOtopoints={useOtopoints}
              userOtopoints={userOtopoints}
              usedOtopoints={usedOtopoints}
              userTotalOtopointsAvailable={userTotalOtopointsAvailable}
              disableOtopoints={disableOtopoints}
              handleChangeInternalNotes={handleChangeInternalNotes}
              internalNotes={internalNotes}
              disableAll={disableAll}
              isECommerce={isECommerce}
              isFleetMode={isFleetMode}
              errorWorkshop={errorWorkshop}
              errorCart={errorCart}
              isSuccessSubmit={isSuccessSubmit}
              isUpdatingCart={isUpdatingCart}
              dataCart={cart}
              errorSPKNumber={errorSPKNumber}
              errorSPKFile={errorSPKFile}
              errorInternalNotes={errorInternalNotes}
              allowBookingCustomForm={allowBookingCustomForm}
              handleChangeWorkshop={handleChangeWorkshop}
              isDuplicateBooking={isDuplicateBooking}
              processRemoveCart={processRemoveCart}
              handleResultNotification={handleResultNotification}
              validateCart={validateCart}
              markUpNDiscount={{ markUpProduct, markUpService, discProduct, discService }}
              loadingCalculate={loadingCalculate}
            />
          </Col>
          <Col lg={6}>
            <BookingSummary
              overrideList={overrideList}
              b2bDraftDataPayload={b2bDraftData?.b2b_draft?.payload}
              calculatePricePayload={calculatePricePayload}
              setCalculatePricePayload={setCalculatePricePayload}
              selectedWorkshops={selectedWorkshops}
              handleAddMultipleCalculateCartData={handleAddMultipleCalculateCartData}
              isMultipleWorkshop={selectedWorkshops}
              fleetProps={{ isFleetMode, detailFleet }}
              datas={{
                is_b2b: selectedUser?.isB2B,
                booking_date_time: bookingDateTime,
                users: selectedUser,
                items: cart,
                workshops: selectedWorkshop,
                cars: selectedCar,
                otopoints: { useOtopoints, userOtopoints },
                promo: { promo: promoCodeResult?.promo_code, amount: promoCodeResult?.discount_amount },
                spk: {
                  file: spkFile,
                  number: spkNumber,
                },
              }}
              promoData={promoData}
              setTotalAmount={setTotalAmount}
              setAmount={setAmount}
              setPPN={setPPN}
              setPPH={setPPH}
              setDPP={setDPP}
              setRounding={setRounding}
              setGrandTotal={setGrandTotal}
              setUsedOtopoints={setUsedOtopoints}
              source="create"
              loadingCalculate={loadingCalculate}
              taxCalculation={setTaxCalculation}
              isB2BDraft={isB2BDraft}
            />
            <BookingWorkshopDetail dataWorkshop={selectedWorkshop} />
            {/* {selectedUser?.isB2B && (
              <div className="position-sticky" style={{ top: 80 }}>
                <BookingCreateDetailDataStudio />
              </div>
            )} */}
          </Col>
        </Row>

        <Row className="justify-content-center">
          {!isFleetMode && (
            <div
              style={{
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                gap: 18,
                padding: 8,
                borderRadius: 8,
                width: 'auto',
                position: 'fixed',
                right: '50px',
                bottom: '15px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
              }}
            >
              {selectedUser?.isB2B && (
                <ComparePriceModal
                  handlePriceChange={handlePriceChange}
                  setOverrideQty={setOverrideQty}
                  overrideQty={overrideQty}
                  b2bDraft={b2bDraftData}
                  overrideList={overrideList}
                  setOverrideList={setOverrideList}
                  setCalculatePricePayload={setCalculatePricePayload}
                  handleAddMultipleCalculateCartData={handleAddMultipleCalculateCartData}
                  calculatePricePayload={calculatePricePayload}
                  tempBookingData={tempBookingData}
                  selectedWorkshops={selectedWorkshops}
                  multipleCalculateCartData={multipleCalculateCartData}
                  cart={cart}
                  setSelectedWorkshop={setSelectedWorkshop}
                  handleMultipleWorkshopAddCart={handleMultipleWorkshopAddCart}
                  setSelectedWorkshops={setSelectedWorkshops}
                  setIsSelectedWorkshopPrice={setIsSelectedWorkshopPrice}
                  setUpdatedCalculatePayloadCart={setUpdatedCalculatePayload}
                />
              )}

              {!isB2BDraft && (
                <FormGroup check className="d-flex align-items-center justify-content-center">
                  <input
                    id="duplicate-check"
                    type="checkbox"
                    className="mr-2"
                    onClick={handleChangeDuplicateBooking}
                    value={isDuplicateBooking}
                    checked={isDuplicateBooking}
                  />
                  <Label className="text-purple" check>
                    Duplicate Booking
                  </Label>
                </FormGroup>
              )}

              <div style={{ position: 'relative', display: 'inline-block' }}>
                {selectedUser?.isB2B ? (
                  <Button
                    id="create-booking"
                    color="purple"
                    onClick={toggleCreateBookingButtonDropdown}
                    aria-haspopup="true"
                    aria-expanded={createBookingButtonDropdown}
                    disabled={bookingStore.isDisableCreateBookingButton || submitLoading}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Buat Booking
                    <span
                      style={{
                        marginLeft: '8px',
                        transform: createBookingButtonDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s',
                      }}
                    >
                      ▼
                    </span>
                  </Button>
                ) : (
                  <Button
                    id="create-booking"
                    color="purple"
                    onClick={handleSubmitBooking}
                    disabled={bookingStore.isDisableCreateBookingButton || submitLoading}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Buat Booking
                  </Button>
                )}

                {/* <Button
                  color="primary"
                  onClick={toggleCreateBookingButtonDropdown}
                  aria-haspopup="true"
                  aria-expanded={createBookingButtonDropdown}
                  disabled={bookingStore.isDisableCreateBookingButton || submitLoading}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Buat Booking / Order
                  <span
                    style={{
                      marginLeft: '8px',
                      transform: createBookingButtonDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                    }}
                  >
                    ▼
                  </span>
                </Button> */}
                <div className={`dropup-menu ${createBookingButtonDropdown ? 'open' : ''}`}>
                  {isB2BDraft ? (
                    <>
                      <button
                        className="dropdown-item dropdown-item-size"
                        style={{
                          height: '50px',
                          whiteSpace: 'normal',
                          padding: '0',
                          width: '100%',
                          overflow: 'hidden',
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          handleSubmitBookingB2B(true, b2bDraftData?.booking_status?.slug, true);
                          setCreateBookingButtonDropdown(false);
                        }}
                      >
                        Simpan Perubahan Data
                      </button>
                      <button
                        className="dropdown-item dropdown-item-size"
                        // disabled={!selectedWorkshop || selectedWorkshops.length <= 0 || selectedWorkshops > 1}
                        style={{
                          height: '50px',
                          whiteSpace: 'normal',
                          padding: '0',
                          width: '100%',
                          overflow: 'hidden',
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          bookingStatusCondition === 'Butuh Persetujuan'
                            ? handleSubmitBookingB2B(true, 'waiting')
                            : handleSubmitBookingB2B(false, 'confirm');
                          setCreateBookingButtonDropdown(false);
                        }}
                      >
                        {bookingStatusCondition}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="dropdown-item dropdown-item-size"
                        style={{
                          height: '50px',
                          whiteSpace: 'normal',
                          padding: '0',
                          width: '100%',
                          overflow: 'hidden',
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          // setBookingStatusCondition('Butuh Estimasi');
                          handleSubmitBookingB2B(true, 'review');
                          setCreateBookingButtonDropdown(false);
                        }}
                      >
                        Butuh Estimasi
                      </button>
                      <button
                        className="dropdown-item dropdown-item-size"
                        // disabled={!selectedWorkshop || selectedWorkshops.length <= 0 || selectedWorkshops > 1}
                        style={{
                          height: '50px',
                          whiteSpace: 'normal',
                          padding: '0',
                          width: '100%',
                          overflow: 'hidden',
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          // setBookingStatusCondition('Butuh Persetujuan');
                          handleSubmitBookingB2B(true, 'waiting');
                          setCreateBookingButtonDropdown(false);
                        }}
                      >
                        Butuh Persetujuan
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </Row>
      </Wrapper>

      <CustomModal modal={isSelecetedWorkshopPriceModalOpen} toggle={() => setIsSelecetedWorkshopPriceModalOpen(false)}>
        <div className="text-center">
          <p className="">Anda tidak dapat membuat booking jika belum memilih bengkel.</p>
          <Button
            color="primary"
            className="m-auto px-4 rounded-pill"
            size="lg"
            onClick={() => setIsSelecetedWorkshopPriceModalOpen(false)}
          >
            Ok Mengerti
          </Button>
        </div>
      </CustomModal>

      <CreateUserModal
        modal={modalCreate}
        toggle={onClickCreateNewUser}
        title={modalType == 'new-user' ? 'Tambah User Baru' : 'Tambah Mobil Baru'}
        onSubmit={handleModalSubmit}
        submitLoading={modalSubmitLoading}
        modalErrorColor={modalErrorColor}
        hasError={modalHasError}
        errorMessage={modalErrorMessage}
        submitModalSuccess={submitModalSuccess}
        modalType={modalType}
        selectedUser={selectedUser}
        phoneNumberInit={phoneNumberInit}
        isFleet={isFleetMode}
      />

      <ModalChooseWorkshop
        multipleCalculateCartData={multipleCalculateCartData}
        handleAddCart={handleAddCart}
        isOpen={hasModalChooseWorkshop}
        toggle={toggleHasModalChooseWorkshop}
        handleChangeWorkshop={handleChangeWorkshop}
        cart={cart}
        mapValue={mapValue}
        selectedUser={selectedUser}
        isPUDO={isPUDO}
        bookingDatetime={{ date: selectedDate, time: selectedTime?.value }}
        customerType={selectedUser?.isB2B ? 'b2b' : 'b2c'}
        selectedWorkshops={selectedWorkshops}
        setSelectedWorkshops={setSelectedWorkshops}
      />

      {isFleetMode && (
        <>
          <div
            style={{
              background: '#fff',
              display: 'flex',
              alignItems: 'center',
              gap: 18,
              padding: 8,
              borderRadius: 8,
              width: 'auto',
              position: 'fixed',
              right: '50px',
              bottom: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
            }}
          >
            {selectedUser?.isB2B && (
              <ComparePriceModal
                tempBookingData={tempBookingData}
                selectedWorkshops={selectedWorkshops}
                multipleCalculateCartData={multipleCalculateCartData}
                cart={cart}
                setSelectedWorkshop={setSelectedWorkshop}
                handleMultipleWorkshopAddCart={handleMultipleWorkshopAddCart}
                setSelectedWorkshops={setSelectedWorkshops}
                setIsSelectedWorkshopPrice={setIsSelectedWorkshopPrice}
              />
            )}

            <div style={{ position: 'relative', display: 'inline-block' }}>
              <Button
                id="create-estimation"
                color="purple"
                onClick={handleSubmitBooking}
                disabled={bookingStore.isDisableCreateBookingButton || submitLoading}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Buat Estimasi harga
              </Button>

              {/* <Button
                  color="primary"
                  onClick={toggleCreateBookingButtonDropdown}
                  aria-haspopup="true"
                  aria-expanded={createBookingButtonDropdown}
                  disabled={bookingStore.isDisableCreateBookingButton || submitLoading}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Buat Booking / Order
                  <span
                    style={{
                      marginLeft: '8px',
                      transform: createBookingButtonDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                    }}
                  >
                    ▼
                  </span>
                </Button> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BookingCreate;