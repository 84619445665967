import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Button } from 'reactstrap';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import PenIcon from '@assets/icons/pen.svg';
import InputField from '@components/field/InputField';
import DetailField from '@components/field/DetailField';
import { isEmpty } from 'lodash';
import NewTabIcon from '@assets/icons/new-tab.svg';
import NoImage from '@assets/images/no-image.jpeg';
import Lightbox from 'rhino-react-image-lightbox-rotate';


function BookingDetailCar(props) {
  const history = useHistory();
  const {
    id,
    isFleetMode,
    detailData,
    editDetail,
    editDocument,
    disabledGLobal,
    onEditModeCar,
    onProcessEditCar,
    onResultNotification,
  } = props;
  const api = new API('v2');
  const [formData, setFormData] = useState();
  const [editDisatance, setEditDistance] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [imageLightbox, setImageLightbox] = useState('');

  const customerType = detailData?.customer?.customer_type;

  const goToCarHealthInspection = () => {
    if (detailData?.inspection_id) {
      history.push(`/stnk/${detailData?.user_car?.id}/car-health/inspections/detail/${detailData?.inspection_id}`);
    } else {
      history.push(`/stnk/${detailData?.user_car?.id}/car-health/inspections/create/${detailData?.booking_code}`);
    }
  };

  const setDefaultForm = () => {
    const dataForm = {
      distance: detailData?.user_car?.mileage >= 0 ? detailData?.user_car?.mileage : '',
      error_distance: '',
    };

    setFormData(dataForm);
    setEditDistance(false);
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeDistance = (e) => {
    let value = e?.target?.value.replace(/[^0-9]/g, '');
    value.replace(/(?!^\+)\+/g, '');
    validateDistance(value);
  };

  const validateDistance = (value) => {
    const errorMsg = value?.length > 7 ? 'Format KM maksimal 7 karakter' : '';
    setChangeValueForm('distance', value, 'error_distance', errorMsg);
    return errorMsg ? false : true;
  };

  const handleEditDistance = () => {
    if (!disabled && !disabledGLobal) {
      setDefaultForm();
      setEditDistance(!editDisatance);
    }
  };

  const handleShowLightbox = (img) => {
    setShowLightbox(img ? true : false);
    setImageLightbox(img ?? '');
  };

  async function handleSubmit() {
    if (!disabled && !disabledGLobal) {
      const validDistance = editDisatance ? validateDistance(formData?.distance ?? '') : true;
      if (validDistance) {
        setIsProcess(true);
        const payload = { mileage: formData?.distance ?? '' };
        api
          .put(`v2/intools/bookings/${id}/booking-revise/`, payload)
          .then((response) => {
            handleResultNotification(true, 'success', 'Berhasil Update KM Mobil');
            setEditDistance(false);
          })
          .catch((e) => {
            const message = `API Error : ${e?.response?.data?.error?.message}`;
            setIsProcess(false);
            handleResultNotification(true, 'fail', 'Gagal Update KM Mobil ' + message);
          });
      }
    }
  }

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  useEffect(() => {
    setDisabled(isProcess);
    onProcessEditCar(isProcess);
  }, [isProcess]);

  useEffect(() => {
    if (editDetail || editDocument) {
      setDefaultForm();
    }
  }, [editDetail, editDocument]);

  useEffect(() => {
    onEditModeCar(editDisatance);
  }, [editDisatance]);

  useEffect(() => {
    setDefaultForm();
  }, [detailData]);

  return (
    <React.Fragment>
      {showLightbox && <Lightbox mainSrc={imageLightbox} onCloseRequest={() => setShowLightbox(false)} />}
      
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Mobil</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <img src={detailData?.user_car?.car_details?.car_model?.image_link ?? ''} className="user-car" />
              <DetailField
                title="Mobil "
                value={`${detailData?.user_car?.car_details?.car_model?.brand?.name ?? '-'} ${
                  detailData?.user_car?.car_details?.car_model?.model_name ?? ''
                }`}
              />
              <DetailField 
                  title="Plat Nomor" 
                  value={
                    <>
                      {detailData?.user_car?.license_plate || '-'}
                      {detailData?.user_car?.license_plate ?
                        <a
                          href={`/stnk/${detailData?.user_car?.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img className="button-pen ml-2" src={NewTabIcon} />
                        </a>
                        :
                        ''
                      }
                    </>
                  } 
              />
              <DetailField title="Nomor VIN" value={Helper.getCommonLabel(detailData?.user_car?.vin_no) || '-'} />
              <DetailField title="Transmisi" value={Helper.getCommonLabel(detailData?.user_car?.transmission) || '-'} />
              <DetailField title="Tahun" value={detailData?.user_car?.year || '-'} />
              <DetailField
                title="Bahan Bakar"
                value={Helper.getCommonLabel(detailData?.user_car?.car_details?.fuel) || '-'}
              />
              {isFleetMode && <DetailField title="Cabang" value={detailData?.user_car?.branch?.name || '-'} />}
              {detailData?.booking_source?.source_type !== 'ecommerce' && !isEmpty(detailData?.workshop) ? (
                <div>
                  <dl className="row align-items-center">
                    <div className="col-sm-3 text-sm-right">
                      <dt>KM</dt>
                    </div>
                    {!editDisatance && (
                      <>
                        <div className="col-sm-6 text-sm-left">
                          <dd className="mb-1">{formData?.distance >= 0 ? formData?.distance : '-'}</dd>
                        </div>
                        <div className="col-sm-3 text-sm-right">
                          <img
                            className="button-pen"
                            src={PenIcon}
                            onClick={() => {
                              handleEditDistance();
                            }}
                          />
                        </div>
                      </>
                    )}

                    {editDisatance && (
                      <>
                        <div className="col-sm-6 text-sm-left p-0">
                          <InputField
                            inputName="distance"
                            placeholder={'Masukkan KM Mobil'}
                            value={formData?.distance}
                            disabled={disabled || disabledGLobal}
                            invalid={formData?.error_distance ? true : false}
                            errorMessage={formData?.error_distance ?? ''}
                            onChange={(e) => handleChangeDistance(e)}
                          />
                        </div>
                        <div className="col-sm-3 text-sm-right section-action">
                          <Button
                            className="button-action no-width add"
                            disabled={disabled || disabledGLobal}
                            onClick={handleSubmit}
                          >
                            Simpan
                          </Button>
                          <Button
                            className="button-action no-width add"
                            disabled={disabled || disabledGLobal}
                            onClick={() => {
                              if (!disabled && !disabledGLobal) {
                                setDefaultForm();
                              }
                            }}
                          >
                            Batal
                          </Button>
                        </div>
                      </>
                    )}
                  </dl>
                </div>
              ) : null}
              <DetailField
                className="align-items-center"
                title="Membership"
                value={
                  <>
                    <Row>
                      {detailData?.user_car?.membership_info?.membership || '-'} (
                      {detailData?.user_car?.membership_info.membership_expired_at ?? '-'})
                      {detailData?.user_car?.membership_info?.is_expired && (
                        <span className="badge-custom badge-custom-membership" style={{ marginLeft: '10px' }}>
                          {'Expired'}
                        </span>
                      )}
                    </Row>
                  </>
                }
              />
              <DetailField
                className="align-items-center"
                title="Foto STNK"
                value={
                  <>
                    <Row>
                    <div className="galeri-container">
                      <div className="box mb-4">
                        <img
                          src={detailData?.user_car?.stnk?.url ? detailData?.user_car?.stnk?.url : NoImage}
                          onClick={() => handleShowLightbox(detailData?.user_car?.stnk?.url )}
                        />
                      </div>
                    </div>
                    </Row>
                  </>
                }
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="p-3">
          <Button color="purple" block onClick={goToCarHealthInspection}>
            <i className="mdi mdi-directions mr-2 mb-1"></i>
            <span>Inspeksi Mobil</span>
          </Button>
        </CardFooter>
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailCar;
