import React, { useState, useMemo, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Wrapper from '@components/wrapper/Wrapper';
import BookingBadges from '@components/badge/BookingBadges';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomModal from '@components/modal/CustomModal';
import ExportFinanceForm from '@components/booking/ExportFinanceForm';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Card, CardBody, Badge } from 'reactstrap';
import map from 'lodash/map';
import newTabIcon from '@assets/icons/new-tab.svg';

function Bookings() {
  const { generatePermission } = userPermission();
  const history = useHistory();

  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);

  const keys = [
    'customer_name',
    'phone_number',
    'workshop_name',
    'booking_code',
    'license_plate',
    'vin_no',
    'booking_status',
    'booking_type',
    'booking_origin',
    'promo_code',
    'start_date',
    'end_date',
    'freshdesk_ticket_link',
    'queue_type',
    'internal_notes',
    'created_at_from',
    'created_at_to',
    'page',
    'limit',
    'data',
  ];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [originOptions, setOriginOptions] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);
  const [ShowModalExportFinance, setShowModalExportFinance] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('booking', menu) || generatePermission('booking', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowAdd = allowPermission('all') || allowPermission('createall') || allowPermission('createshow');
  const allowShow = allowPermission('all') || allowPermission('detail');
  const allowExport = allowPermission('all') || allowPermission('export');
  const allowExportFinance = allowPermission('all') || allowPermission('financeexport');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const typeOptions = [
    { value: '', label: 'Semua' },
    { value: 'b2c', label: 'B2C' },
    { value: 'b2b', label: 'B2B' },
    { value: 'custom', label: 'Custom' },
  ];

  const paymentStatusOptions = [
    { value: '', label: 'Semua' },
    { value: 'cancel', label: 'Cancel' },
    { value: 'capture', label: 'Capture' },
    { value: 'deny', label: 'Deny' },
    { value: 'expire', label: 'Expire' },
    { value: 'pending', label: 'Pending' },
    { value: 'settlement', label: 'Settlement' },
  ];

  const queueTypeOptions = [
    { value: '', label: 'Semua Tipe Servis' },
    { value: 'priority', label: 'Prioritas' },
    { value: 'low-priority', label: 'Reguler' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_customer_name',
      type: 'text',
      name: 'customer_name',
      name_field: 'customer_name',
      value: tempFiltered?.customer_name ?? '',
      placeholder: 'Customer Name',
    },
    {
      id: 'filter_text_phone_number',
      type: 'text',
      name: 'phone_number',
      name_field: 'phone_number',
      value: tempFiltered?.phone_number ?? '',
      placeholder: 'Phone Number',
    },
    {
      id: 'filter_text_workshop_name',
      type: 'text',
      name: 'workshop_name',
      name_field: 'workshop_name',
      value: tempFiltered?.workshop_name ?? '',
      placeholder: 'Bengkel Name',
    },
    // {
    //   id: 'filter_text_promo_code_used',
    //   type: 'text',
    //   name: 'promo_code',
    //   name_field: 'promo_code',
    //   value: tempFiltered?.promo_code ?? '',
    //   placeholder: 'Promo Code',
    // },
    {
      id: 'filter_text_plate_number',
      type: 'text',
      name: 'license_plate',
      name_field: 'license_plate',
      value: tempFiltered?.license_plate ?? '',
      placeholder: 'Plate Number',
    },
    {
      id: 'filter_text_vin_number',
      type: 'text',
      name: 'vin_no',
      name_field: 'vin_no',
      value: tempFiltered?.vin_no ?? '',
      placeholder: 'Nomor VIN',
    },
    {
      id: 'filter_text_booking_code',
      type: 'text',
      name: 'booking_code',
      name_field: 'booking_code',
      value: tempFiltered?.booking_code ?? '',
      placeholder: 'Booking Code',
    },
    // {
    //   id: 'filter_text_freshdesk_ticket_link',
    //   type: 'text',
    //   name: 'freshdesk_ticket_link',
    //   name_field: 'freshdesk_ticket_link',
    //   value: tempFiltered?.freshdesk_ticket_link ?? '',
    //   placeholder: 'Freshdesk Ticket Link',
    // },
    {
      id: 'filter_date_created',
      type: 'date',
      name: 'created_date',
      name_field: 'created_date',
      date_range: true,
      value:
        tempFiltered?.created_at_from && tempFiltered?.created_at_to
          ? { start_date: tempFiltered?.created_at_from, end_date: tempFiltered?.created_at_to }
          : '',
      placeholder: 'Created At',
    },
    {
      id: 'filter_date_date',
      type: 'date',
      name: 'date',
      name_field: 'date',
      date_range: true,
      value:
        tempFiltered?.start_date && tempFiltered?.end_date
          ? { start_date: tempFiltered?.start_date, end_date: tempFiltered?.end_date }
          : '',
      placeholder: 'Booking Date Time',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'booking_status',
      name_field: 'booking_status',
      options: statusOptions,
      value: tempFiltered?.booking_status
        ? statusOptions.filter((type) => type?.value === tempFiltered?.booking_status)?.[0]
        : '',
      placeholder: 'Booking Status',
    },
    // {
    //   id: 'filter_select_booking_type',
    //   type: 'select',
    //   name: 'booking_type',
    //   name_field: 'booking_type',
    //   options: typeOptions,
    //   value: tempFiltered?.booking_type
        // ? typeOptions.filter((type) => type?.value === tempFiltered?.booking_type)?.[0]
    //     : '',
    //   placeholder: 'Booking Type',
    // },
    {
      id: 'filter_select_booking_origin',
      type: 'select',
      name: 'booking_origin',
      name_field: 'booking_origin',
      options: originOptions,
      value: tempFiltered?.booking_origin
        ? { label: tempFiltered?.booking_origin, value: tempFiltered?.booking_origin }
        : '',
      placeholder: 'Booking Origin',
    },
    {
      id: 'filter_select_queue_type',
      type: 'select',
      name: 'queue_type',
      name_field: 'queue_type',
      options: queueTypeOptions,
      value: tempFiltered?.queue_type
        ? queueTypeOptions.filter((type) => type?.value === tempFiltered?.queue_type)?.[0]
        : '',
      placeholder: 'Tipe Servis',
    },
    {
      id: 'filter_select_queue_type',
      type: 'select',
      name: 'payment_status',
      name_field: 'payment_status',
      options: paymentStatusOptions,
      value: '',
      placeholder: 'Payment Status',
    },
    {
      id: 'filter_text_internal_notes',
      type: 'text',
      name: 'internal_notes',
      name_field: 'internal_notes',
      value: tempFiltered?.internal_notes ?? '',
      placeholder: 'Internal Notes',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: '',
        selector: (row) =>
          allowShow ? (
            <Link to={`/bookings/${row?.booking_code}`} target="_blank">
              <img src={newTabIcon} height={23} className="icon-new-tab" />
            </Link>
          ) : (
            <img src={newTabIcon} height={23} className="icon-new-tab" />
          ),
        minWidth: '10px',
        width: '60px',
        wrap: true,
        sortable: false,
      },
      {
        name: 'Create At',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.booked_at ?? null).isValid() ? moment(row?.booked_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.booked_at ?? null).isValid() ? moment(row?.booked_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        minWidth: '120px',
        left: true,
      },
      {
        name: 'Booking Date Time',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.booking_datetime ?? null).isValid()
                ? moment(row?.booking_datetime).format('DD MMM, YYYY')
                : '-'}
            </span>
            <span className="time">
              {moment(row?.booking_datetime ?? null).isValid() ? moment(row?.booking_datetime).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        minWidth: '120px',
        left: true,
      },
      {
        name: 'Booking Code',
        selector: (row) => row?.booking_code ?? '-',
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Customer Name',
        selector: (row) => row?.customer?.name,
        minWidth: '180px',
        sortable: true,
      },
      {
        name: 'License Plate',
        selector: (row) => row?.car_details?.license_plate ?? '-',
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Nomor VIN',
        selector: (row) => row?.car_details?.vin_no ?? '-',
        minWidth: '160px',
        sortable: true,
      },
      {
        name: 'Customer Phone Number',
        minWidth: '180px',
        selector: (row) => row?.customer?.phone_number ?? '-',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Booking Status',
        selector: (row) => <BookingBadges status={row?.booking_status} isDraft={row?.is_draft_change} />,
        minWidth: '270px',
      },
      {
        name: 'Bengkel Name',
        selector: (row) => row?.workshop ?? '-',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Booking Origin',
        selector: (row) => row?.booking_origin ?? '-',
        minWidth: '170px',
      },
      {
        name: 'Tipe Servis',
        selector: (row) => (
          <Badge
            className={`ml-2 py-2 px-4 ${row?.service_type?.slug === 'low-priority' ? 'badge-info' : 'badge-success'}`}
          >
            {row?.service_type?.name ?? '-'}
          </Badge>
        ),

        minWidth: '170px',
      },
      {
        name: 'Payment Status',
        selector: (row) => row?.payment_status ?? '-',
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Payment Update At',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.payment_update_at ?? null).isValid()
                ? moment(row?.payment_update_at).format('DD MMM, YYYY')
                : '-'}
            </span>
            <span className="time">
              {moment(row?.payment_update_at ?? null).isValid()
                ? moment(row?.payment_update_at).format('HH:mm:ss')
                : '-'}
            </span>
          </div>
        ),
        minWidth: '170px',
        sortable: true,
      },
      {
        name: 'Total Price',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.total_price)}`,
        minWidth: '170px',
      },
      {
        name: 'Booking Internal Notes',
        selector: (row) => Helper.emptyStringRenderer(row?.internal_notes),
        wrap: true,
        minWidth: '500px',
        width: '1320px',
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchBookings() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/bookings/new-list/`, { params });
      const data = response?.data?.data;
      const dataPagination = response?.data?.pagination;
      setData(data ?? []);
      setTotalRows(dataPagination?.total_rows ?? 0);
      setTotalPages(dataPagination?.total_page ?? 0);
      validateCountPage(response);
      handleDataExport(data ?? []);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
    }
  }

  async function fetchBookingStatusOptions() {
    let listStatusOption = [{ value: '', label: 'Semua Booking Status' }];

    try {
      const response = await api.get('v2/intools/bookings/booking-status/b2c');
      if (response?.data?.data) {
        let resultStatusOptions = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name };
        });
        resultStatusOptions.splice(0, 0, { value: '', label: 'Semua Booking Status' });
        listStatusOption = resultStatusOptions;
      }
      setStatusOptions(listStatusOption);
    } catch (error) {
      setStatusOptions(listStatusOption);
    }
  }

  async function fetchBookingOriginOptions() {
    let listOriginOptions = [{ value: 'All', label: 'Semua Booking Origin' }];

    try {
      const response = await api.get('v2/intools/bookings/booking-origin-options/');
      if (response?.data?.data) {
        map(response?.data?.data, (item) => {
          if (item !== 'All') {
            listOriginOptions.push({ value: item, label: item });
          }
        });
      }
      setOriginOptions(listOriginOptions);
    } catch (error) {
      setOriginOptions(listOriginOptions);
    }
  }

  const handleChangeOptions = () => {
    if (filters.length) {
      console.log('filterssb2c', filters);
      filters[8].options = statusOptions;
      // if (tempFiltered?.booking_status)
      //   filters[8].value = statusOptions.filter((status) => status?.value === tempFiltered?.booking_status)?.[0];
      filters[9].options = originOptions;
      // filters[11].options = queueTypeOptions;
      setFilters([...filters]);
    }
  };

  const getParamData = () => {
    let params = {
      booking_code: filtered?.booking_code ?? null,
      license_plate: filtered?.license_plate ?? null,
      vin_no: filtered?.vin_no ?? null,
      booking_status: filtered?.booking_status ?? null,
      booking_type: 'b2c',
      booking_origin: filtered?.booking_origin ?? null,
      customer_name: filtered?.customer_name ?? null,
      customer_phone_number: filtered?.phone_number ?? null,
      workshop_name: filtered?.workshop_name ?? null,
      promo_code: filtered?.promo_code ?? null,
      start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 00:00:00') : null,
      end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 23:59:59') : null,
      freshdesk_ticket_link: filtered?.freshdesk_ticket_link ?? null,
      created_at_from: filtered?.created_at_from
        ? moment(filtered?.created_at_from).format('YYYY-MM-DD 00:00:00')
        : null,
      created_at_to: filtered?.created_at_to ? moment(filtered?.created_at_to).format('YYYY-MM-DD 23:59:59') : null,
      queue_type: filtered?.queue_type ?? null,
      internal_notes: filtered?.internal_notes ?? null,
      payment_status: filtered?.payment_status ?? null,
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleDataExport = (dataBooking) => {
    let arrDataExport = [];

    dataBooking.forEach((item) => {
      const objDataExport = {
        booking_code: item?.booking_code ?? '-',
        customer_name: item?.customer.name ?? '-',
        status: item?.booking_status?.name ?? '-',
        booking_date: item?.booking_date ?? '-',
        booking_time: item?.booking_time ?? '-',
        pay_method: item?.payment_method ?? '-',
        total_price: `Rp. ${Helper.formatMoney(item?.total_price ?? 0)}`,
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = [
      'booking_code',
      'customer_name',
      'status',
      'booking_date',
      'booking_time',
      'pay_method',
      'total_price',
    ];
    const headers = ['booking', 'customer', 'status', 'booking date', 'booking time', 'payment', 'price'];

    Helper.downloadCSV(dataExport, headers, keys);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const resetFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetFilter();
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchBookings();
  };

  const handleMethodfetchBookings = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchBookings();
    }
  };

  const resetBookings = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const createBooking = () => {
    history.push(`/bookings/create`);
  };

  const openBookingsDetail = (row) => {
    if (allowShow) {
      history.push(`/bookings/${row?.booking_code}`);
    }
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      queue_type: e?.queue_type?.value ?? '',
      booking_origin: e?.booking_origin?.value ?? '',
      booking_type: e?.booking_type?.value ?? '',
      payment_status: e?.payment_status?.value ?? '',
      booking_status: e?.booking_status?.value ?? '',
      start_date: e?.date?.start_date ? moment(e?.date?.start_date).format('YYYY-MM-DD 00:00:00') : '',
      end_date: e?.date?.end_date ? moment(e?.date?.end_date).format('YYYY-MM-DD 23:59:59') : '',
      created_at_from: e?.created_date?.start_date
        ? moment(e?.created_date?.start_date).format('YYYY-MM-DD 00:00:00')
        : '',
      created_at_to: e?.created_date?.end_date ? moment(e?.created_date?.end_date).format('YYYY-MM-DD 23:59:59') : '',
    };
    setFiltered(tempVal);
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchBookings();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 00:00:00') : '',
        end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 23:59:59') : '',
        created_at_from: filtered?.created_at_from
          ? moment(filtered?.created_at_from).format('YYYY-MM-DD 00:00:00')
          : '',
        created_at_to: filtered?.created_at_to ? moment(filtered?.created_at_to).format('YYYY-MM-DD 23:59:59') : '',
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/bookings/?${urlParam}`);
      fetchBookings();
    }
    resetBookings();
  }, [filtered]);

  useEffect(() => {
    handleChangeOptions();
    fetchBookings();
  }, [statusOptions, originOptions]);

  useEffect(() => {
    fetchBookingStatusOptions();
    fetchBookingOriginOptions();
    setFilters(dataFilter);
  }, []);

  useEffect(() => {
    if (tempFiltered?.start_date && tempFiltered?.end_date) {
      setFiltered({
        ...tempFiltered,
        date: {
          start_date: tempFiltered?.start_date,
          end_date: tempFiltered?.end_date,
        },
        start_date: tempFiltered?.start_date,
        end_date: tempFiltered?.end_date,
      });
    }
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  return (
    // <Wrapper title="" className="wrapperContent">
    //   <CustomModal modal={ShowModalExportFinance} modalWidth="500">
    //     <ExportFinanceForm onCloseModal={() => setShowModalExportFinance(false)} />
    //   </CustomModal>

    // </Wrapper>
    <Card>
      <CardBody>
        <Col sm={12} md={12} className="p-0 mt-0 section-action">
          {allowAdd && (
            <Button className="button-action add w-170" onClick={createBooking}>
              {'Create Booking'}
            </Button>
          )}
          {/* {allowExport && allowList && (
            <Button disabled={disabledExport || loading} className="button-action primary" onClick={handleDownloadCSV}>
              {'Export CSV'}
            </Button>
          )}
          {allowExportFinance && (
            <Button disabled={loading} className="button-action export" onClick={() => setShowModalExportFinance(true)}>
              {'Export Finance'}
            </Button>
          )} */}
        </Col>
        {filters?.length !== 0 && (
          <Col sm={12} md={12} className="p-0 mt-2">
            <ActionFilterCustom
              filters={filters}
              loading={loading}
              onApplyFilters={(e) => handleSetFiltered(e)}
              onResetFilters={handleResetFilters}
            ></ActionFilterCustom>
          </Col>
        )}
        <Col sm={12} md={12} className="p-0 mt-2">
          <DataTable
            persistTableHead
            highlightOnHover
            noHeader={true}
            progressPending={loading}
            data={data}
            columns={allowList ? columns : []}
            className="table dt-responsive table-custom"
            noDataComponent={noDataComponent}
            progressComponent={<Loading />}
            customStyles={customStylesHeaderTable}
            onRowClicked={(row) => openBookingsDetail(row)}
          />
        </Col>
        <Col sm={12} md={12} className="p-0 mt-2">
          {data?.length >= 1 && !loading && (
            <CustomPagination
              page={page}
              pageLimit={pageLimit}
              totalRows={data?.length ?? 0}
              totalAllRows={totalRows}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
              handleChangeRowPerPage={handleChangeRowPerPage}
            ></CustomPagination>
          )}
        </Col>
      </CardBody>
    </Card>
  );
}

export default Bookings;
