import React, { useState, useMemo, useEffect } from 'react';
import { Badge } from 'reactstrap';
import { useParams, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import ExportButton from '@components/export/ExportButton';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import Helper from '@helpers/Helper';
import ActionFilterPackage from '@components/filters/ActionFilterPackage';
import PackageAdd from '@components/packages/PackageAdd';
import Package from '@components/workshops/Package';

function Packages() {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [totalRows, setTotalRows] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);
  const [packageName, setPackageName] = useState();
  const [workshopName, setWorkshopName] = useState();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [status, setStatus] = useState();
  const [category, setCategory] = useState();
  const [hasModalAddPackage, setHasModalAddPackage] = useState(false);
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  
  const queryParams = useLocation().search;
  const activeproductid = new URLSearchParams(queryParams).get('productid');

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => Helper.shortenByDash(row?.name),
        wrap: true,
        sortable: true,
        minWidth: '275px',
      },
      {
        name: 'Category',
        selector: (row) => row?.category?.name ?? '-',
        width: '130px',
        wrap: true,
      },
      {
        name: 'Workshop Name',
        selector: (row) => row?.workshop?.name ?? '-',
        wrap: true,
        minWidth: '275px',
      },
      {
        name: 'Estimated Price',
        selector: (row) => Helper.formatRangePrice(row?.estimated_price),
        wrap: true,
        minWidth: '230px',
      },
      {
        name: 'Original Price',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.original_price)}`,
        selecteor: 'original_price',
        wrap: true,
        minWidth: '120px',
      },
      {
        name: 'Discount',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.discount_amount)}`,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: 'Price',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.price)}`,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: 'Status',
        width: '100px',
        selector: (row) =>
          row.is_active === 0 ? (
            <Badge className="p-2 badge-warning">Not active</Badge>
          ) : (
            <Badge className="p-2 badge-success">Active</Badge>
          ),
      },
      {
        name: 'Last Updated',
        selector: (row) => moment(row.updated_at).format('MMM D YYYY - h:mm:ss'),
        wrap: true,
        minWidth: '180px',
      },
    ],
    []
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleSearch = () => {
      if (page !== 1) {
        setResetPaginationToggle(true);
      } else {
        setResetPaginationToggle(false);
        loadPackages();
      }
    };

    return (
      <ActionFilterPackage
        packageName={packageName}
        workshopName={workshopName}
        category={category}
        status={status}
        onChangePackageName={(e) => setPackageName(e.target.value)}
        onChangeWorkshopName={(e) => setWorkshopName(e.target.value)}
        onChangeStatus={(e) => setStatus(e)}
        onChangeCategory={(e) => setCategory(e)}
        onSearch={handleSearch}
      />
    );
  }, [packageName, workshopName, status, category]);

  async function loadPackages() {
    setLoading(true);
    setDisabledExport(true);

    const params = {
      package_name: packageName,
      workshop_name: workshopName,
      category_slug: category?.value,
      is_active: status?.value,
      page: page,
      limit: pageLimit,
    };

    const response = await api.get(`v2/intools/packages/main/`, { params });
    setData(response?.data?.data ?? []);
    setTotalRows(response?.data?.pagination?.total_rows ?? 0);
    setLoading(false);

    handleDataExport(response?.data?.data ?? []);
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPageLimit, page) => {
    setPage(page);
    setPageLimit(newPageLimit);
  };

  const handleDataExport = (dataPackages) => {
    let arrDataExport = [];
    dataPackages.forEach((item) => {
      let status = '-';

      if (item?.is_active == 0) {
        status = 'Not Active';
      } else if (item?.is_active == 1) {
        status = 'Active';
      }

      const objDataExport = {
        name: Helper.shortenByDash(item?.name),
        category: item?.category?.name,
        workshop: item?.workshop?.name,
        estimated_price: Helper.formatRangePrice(item?.estimated_price),
        original_price: `Rp. ${Helper.formatMoney(item?.original_price)}`,
        discount: `Rp. ${Helper.formatMoney(item?.discount_amount)}`,
        price: `Rp. ${Helper.formatMoney(item?.price)}`,
        status: status,
        last_updated: moment(item.updated_at).format('MMM D YYYY - h:mm:ss'),
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const openPackageDetail = (dataPackage) => {
    if (generatePermission('package', 'detail')) {
      history.push(`/packages/${dataPackage.id}`);
    }
  };

  const handleAddPackage = () => setHasModalAddPackage(true);
  const toogleAddPackage = () => setHasModalAddPackage(!hasModalAddPackage);

  const handleSubmitAddPackage = (status, error) => {
    const errorMsg = error ? 'Gagal Add Package, ' + error : 'Gagal Add Package';
    if (status) {
      loadPackages();
    }

    setHasModalAddPackage(false);
    showResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Add Package' : errorMsg);
  };

  const showResultNotification = (status = false, type = '', message = '') => {
    handleResultNotification({
      status: status,
      type: type,
      message: message,
    });
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  useEffect(() => {
    if (page && pageLimit) {
      loadPackages();
    }
  }, [page, pageLimit]);

  return (
    <Wrapper
      title="List Package"
      className="wrapperContent"
      showNotification={hasNotif}
      notification={notification}
      onCloseNotification={handleCloseNotification}
    >
      <Package
        id={null}
        onResultNotification={handleResultNotification}
        onLoading={handleLoading}
        activeproductid={activeproductid}
      ></Package>

      {/* <PackageAdd modal={hasModalAddPackage} onSubmit={handleSubmitAddPackage} toggle={toogleAddPackage} />
      <DataTable
        persistTableHead
        highlightOnHover
        pagination
        subHeader
        progressPending={loading}
        data={data}
        columns={generatePermission('package', 'list') ? columns : []}
        className="table table-bordered dt-responsive"
        paginationServer
        paginationTotalRows={totalRows}
        paginationResetDefaultPage={resetPaginationToggle}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressComponent={generatePermission('package', 'list') ? <Loading /> : <></>}
        actions={
          <ExportButton
            data={dataExport}
            title="Export CSV"
            hasActionExport={generatePermission('package', 'export')}
            hasActionCreate={generatePermission('package', 'create')}
            hasDisableExport={disabledExport}
            onClickCreate={handleAddPackage}
            createTitle="Create New Package"
            keys={[
              'name',
              'category',
              'workshop',
              'estimated_price',
              'original_price',
              'discount',
              'price',
              'status',
              'last_updated',
            ]}
            header={[
              'Name',
              'Category',
              'Workshop Name',
              'Estimated Price',
              'Original Price',
              'Discount',
              'Price',
              'Status',
              'Last Updated',
            ]}
          />
        }
        subHeaderComponent={generatePermission('package', 'search') ? subHeaderComponentMemo : ''}
        onRowClicked={(row) => openPackageDetail(row)}
      /> */}
    </Wrapper>
  );
}

export default Packages;
