import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Input,
} from 'reactstrap';
import API from '@utils/API';
import moment from 'moment';
import { map } from 'lodash';
import { CAR_TRANSMISSION_OPTIONS } from '@utils/Constants';
import SelectAsyncField from '../field/SelectAsyncField';
import InputField from '../field/InputField';
import InputMask from 'react-input-mask';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import ChangePhotoModal from '@components/modal/ChangePhotoModal';
import NoImage from '@assets/images/no-image.jpeg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import TrashIcon from '@assets/icons/trash.svg';

const AddCarModal = (props) => {
  const { modal, toggle, userId, loadUserCar, handleResultNotification, allowListCar } = props;
  const api = new API('v2');

  const [carVariant, setCarVariant] = useState(null);
  const [carVariantError, setCarVariantError] = useState('');
  const [year, setYear] = useState('');
  const [yearError, setYearError] = useState('');
  const [carTransmission, setCarTransmission] = useState('');
  const [carTransmissionError, setCarTransmissionError] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [prefixPlate, setPrefixPlate] = useState('');
  const [numberPlate, setNumberPlate] = useState('');
  const [suffixPlate, setSuffixPlate] = useState('');
  const [errorPlateNumber, setErrorPlateNumber] = useState('');
  const [imageStnk, setImageStnk] = useState({});
  const [showLightbox, setShowLightbox] = useState(false);
  const [imageLightbox, setImageLightbox] = useState('');
  const [disabledForm, setDisabledForm] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const [allowVerification, setAllowVerification] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [hasModalImageSTNK, setHasModalImageSTNK] = useState(false);
  const [userCarVinNumber, setUserCarVinNumber] = useState(null);
  

  const fetchCarVariants = (userInputValue) => {
    if (userInputValue) {
      return api.get(`v2/intools/cars/search?q=${userInputValue}`).then((response) => {
        return response.data.data;
      });
    }
  };

  const resetPlateNumber = () => {
    setPrefixPlate('');
    setNumberPlate('');
    setSuffixPlate('');
    setErrorPlateNumber('');
  };

  const handleChangeCarVariantChange = (item) => {
    setCarVariant(item);
    carVariantValidation(item);

    setCarTransmission('');
    handleDeleteImageSTNK()
    setYear('');
    setLicensePlate('');
    resetPlateNumber();
  };

  const handleChangeYear = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');

    setYear(newValue);
    yearValidation(newValue);
  };

  const handleChangeTransmission = (value) => {
    setCarTransmission(value);
    carTransmissionValidation(value);
  };

  const carVariantValidation = (value) => {
    if (value?.id?.length < 1 || !value) {
      setCarVariantError('Mobil harus diisi');
      return false;
    }

    setCarVariantError('');

    return true;
  };

  const yearValidation = (value) => {
    const now = moment().format('YYYY');

    if (parseInt(value) > parseInt(now)) {
      setYearError('Tahun tidak boleh melebihi tahun sekarang');
      return false;
    }
    if (value?.length < 1 || !value) {
      setYearError('Tahun harus diisi');
      return false;
    }

    setYearError('');
    return true;
  };

  const carTransmissionValidation = (value) => {
    if (!value) {
      setCarTransmissionError('Transmisi harus diisi');
      return false;
    }

    setCarTransmissionError('');
    return true;
  };

  const plateNumberValidation = (prefix, number, suffix) => {
    let isPassValidatePlateNumber = (prefix || suffix) && !number ? false : true;
    
    if(userCarVinNumber){
      isPassValidatePlateNumber = true
    }

    setErrorPlateNumber(isPassValidatePlateNumber ? '' : 'Plat Nomor harus diisi');

    return isPassValidatePlateNumber;
  };

  const validate = (payload) => {
    let status = true;
    if (!carVariantValidation(payload?.variant_id)) {
      status = false;
    }

    if (!yearValidation(payload?.year)) {
      status = false;
    }

    if (!carTransmissionValidation(payload?.transmission)) {
      status = false;
    }

    if (!plateNumberValidation(prefixPlate, numberPlate, suffixPlate)) {
      status = false;
    }

    return status;
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);

    let notification = {
      status: true,
      type: '',
      message: '',
    };
    const payload = {
      license_plate: licensePlate,
      variant_id: carVariant?.id,
      transmission: carTransmission,
      vin_no: userCarVinNumber,
      stnk_image: generateStnkPayload(imageStnk),
      year,
    };

    if (!validate(payload)) {
      setIsLoading(false);
      return;
    }

    await api
      .post(`v2/intools/customers/customer-car/${userId}/`, payload)
      .then(() => {
        setIsLoading(false);
        toggle();
        if (allowListCar) loadUserCar();
        notification = {
          ...notification,
          type: 'success',
          message: 'Sukses',
        };
        handleResultNotification(notification);
      })
      .catch((err) => {
        setIsLoading(false);
        let errRes = err.response.data.error.message;

        notification = {
          ...notification,
          type: 'fail',
          message: `Gagal, ${errRes}`,
        };
        handleResultNotification(notification);
      });
  };

  const handleOnCancel = () => {
    toggle();
    handleResetAllField();
  };

  const handleResetAllField = () => {
    setCarVariant(null);
    setCarVariantError('');
    setCarTransmission(null);
    setCarTransmissionError('');
    handleDeleteImageSTNK()
    setYear(null);
    setYearError('');
    setLicensePlate(null);
    resetPlateNumber();
  };

  const handleShowLightbox = (img) => {
    // if (!disabledForm && !isChecking && allowEdit) {
      setShowLightbox(img ? true : false);
      setImageLightbox(img ?? '');
    // }
  };

  const handleUploadImage = (result) => {
    const isImageStnk = hasModalImageSTNK;
    const file = result?.file;
    const preview = result?.preview;
    const id = crypto.randomUUID();

    setDisabledForm(true);
    setHasModalImageSTNK(false);

    if (file?.name) {
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'user_cars');
      paramsImagedUpload.append('identifier', id);
      paramsImagedUpload.append('image', file);

      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            handleAddImages(keyFile, preview, isImageStnk);
            setDisabledForm(false);
          } else {
            handleFailUploadImage('Gagal upload image');
          }
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          handleFailUploadImage(message);
        });
    } else {
      handleFailUploadImage('Gagal upload image, file tidak diketahui');
    }
  };


  const generateStnkPayload = (imageStnk) => {
    let stnk_image = {
      key: imageStnk?.key ?? '',
      is_deleted: imageStnk?.is_deleted,
     }

     return stnk_image
  }

  const handleAddImages = (key, img, isImageStnk) => {
    console.log(isImageStnk)
    if (isImageStnk) {
      imageStnk.img = img;
      imageStnk.key = key;
      imageStnk.is_deleted = false;

      setImageStnk({ ...imageStnk });
    }
  };

  const handleDeleteImageSTNK = () => {
    imageStnk.img = '';
    imageStnk.key = '';
    imageStnk.is_deleted = true;

    setImageStnk({ ...imageStnk });

  };

  const handleFailUploadImage = (msg) => {
    setDisabledForm(false);
    showAlert('danger', msg);
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };

  const handleCarVinNumberChange = (e) => {
    const { name, value } = e.target;
    setUserCarVinNumber(value?.toUpperCase());
  };

  useEffect(() => {
    let plateNumber = prefixPlate ?? '';
    plateNumber += numberPlate ? (plateNumber ? ' ' + numberPlate : numberPlate) : '';
    plateNumber += suffixPlate ? (plateNumber ? ' ' + suffixPlate : suffixPlate) : '';

    setLicensePlate(plateNumber);
  }, [prefixPlate, numberPlate, suffixPlate]);

  useEffect(() => {
    if (!modal) handleResetAllField();
  }, [modal]);

  return (
    <Modal isOpen={modal} toggle={toggle} centered className="modal-add-car">
      {showLightbox && <Lightbox mainSrc={imageLightbox} onCloseRequest={() => setShowLightbox(false)} />}
      
      <ChangePhotoModal
          isValidation={true}
          sizeValidation={5120}
          modal={hasModalImageSTNK}
          title={'Tambahkan foto untuk STNK'}
          resultUploadFile={handleUploadImage}
          toggle={() => setHasModalImageSTNK(!hasModalImageSTNK)}
        />  
      
      <ModalHeader toggle={toggle}>Tambah Mobil Baru</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Mobil
              </Label>
              <SelectAsyncField
                colWidth={9}
                cacheOptions
                defaultOptions={[]}
                value={carVariant}
                getOptionLabel={(e) => `${e.car_model?.model_name} - ${e.variant}`}
                getOptionValue={(e) => e.id}
                loadOptions={fetchCarVariants}
                onChange={handleChangeCarVariantChange}
                menuPlacement="top"
                placeholder="Cari..."
                disabled={isLoading}
                errorMessage={carVariantError}
                invalid={!!carVariantError}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className="label-required">
                Year
              </Label>
              <InputField
                colWidth={9}
                title="Tahun"
                placeholder={`Ex: ${moment().format('YYYY')}`}
                onChange={handleChangeYear}
                value={year}
                disabled={isLoading}
                errorMessage={yearError}
                invalid={!!yearError}
              />
            </FormGroup>

            <FormGroup className="d-flex">
              <Label lg={3} className="label-required">
                Transmission
              </Label>
              <Col lg={9} className="px-2">
                <div className="mb-1">
                  {map(CAR_TRANSMISSION_OPTIONS, (transmission, index) => (
                    <Button
                      className={index > 0 ? 'ml-2' : ''}
                      color="purple"
                      outline={carTransmission === transmission.value}
                      onClick={() => handleChangeTransmission(transmission.value)}
                      disabled={isLoading}
                    >
                      {transmission.label}
                    </Button>
                  ))}
                </div>
                <span className="text-danger" style={{ fontSize: 11.28 }}>
                  {carTransmissionError}
                </span>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label lg={3}>Plat Nomor</Label>
              <Col sm={8} className="p-0">
                <Row className="mx-0">
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input valid`}
                      value={prefixPlate?.toUpperCase()}
                      mask={'**'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setPrefixPlate(e.target.value.toUpperCase());
                        plateNumberValidation(e.target.value.toUpperCase(), numberPlate, suffixPlate);
                      }}
                    >
                      <Input placeholder={'AB'}></Input>
                    </InputMask>
                  </Col>
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input ${errorPlateNumber ? 'invalid' : 'valid'}`}
                      value={numberPlate?.toUpperCase()}
                      aria-invalid={errorPlateNumber}
                      mask={'9999'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setNumberPlate(e.target.value.toUpperCase());
                        plateNumberValidation(prefixPlate, e.target.value.toUpperCase(), suffixPlate);
                      }}
                    >
                      <Input className="input-field" placeholder={'9999'} invalid={errorPlateNumber}></Input>
                    </InputMask>
                  </Col>
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input valid`}
                      value={suffixPlate?.toUpperCase()}
                      mask={'***'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setSuffixPlate(e.target.value.toUpperCase());
                        plateNumberValidation(prefixPlate, numberPlate, e.target.value.toUpperCase());
                      }}
                    >
                      <Input placeholder={'XYZ'}></Input>
                    </InputMask>
                  </Col>
                  <Col>
                    <div className="invalid-hour">{errorPlateNumber}</div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="required">
                Nomor VIN
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="carVinNumber"
                  onChange={handleCarVinNumberChange}
                  value={userCarVinNumber}
                  menuPlacement="top"
                  placeholder="Ketik nomor VIN..."
                />
              </Col>
            </FormGroup>
            <Row>
              <Label lg={3}>Foto STNK</Label>
              <Col lg={5}>
                <Row>
                  <div className="galeri-container">
                    <div className="box mb-4">
                      <img
                        src={imageStnk?.img ? imageStnk?.img : NoImage}
                        onClick={() => handleShowLightbox(imageStnk?.img)}
                      />
                      {imageStnk?.img && (
                        <img
                          className="icon-trash"
                          src={TrashIcon}
                          onClick={() => {
                            handleDeleteImageSTNK();
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Row>
                {!imageStnk?.img && (
                  <Row className="mb-4">
                    <div className="d-flex h-100 justify-content-center">
                      <div className="justify-content-center align-self-center">
                        <Button
                          color="primary"
                          size="lg"
                          className="btn-add-foto"
                          disabled={disabledForm || isChecking || !allowEdit}
                          onClick={() => setHasModalImageSTNK(true)}
                        >
                          <img src={PlusFill} className="mr-2" />
                          Browse
                        </Button>
                      </div>
                    </div>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="purple" outline onClick={handleOnCancel} disabled={isLoading}>
          Tutup
        </Button>{' '}
        <Button color="purple" onClick={handleOnSubmit} disabled={isLoading}>
          {isLoading ? <Spinner color="light" size="sm" className="mr-2" /> : null}
          Tambah
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddCarModal;
