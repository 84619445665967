import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button, Label, FormGroup, Input } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import InputMask from 'react-input-mask';
import TrashIcon from '@assets/icons/trash.svg';
import Wrapper from '@components/wrapper/Wrapper';
import NoImage from '@assets/images/no-image.jpeg';
import StnkBadge from '@components/badge/StnkBadge';
import SelectField from '@components/field/SelectField';
import SelectAsyncField from '@components/field/SelectAsyncField';
import InputField from '@components/field/InputField';
import userPermission from '@utils/userPermission';
import EditModal from '@components/modal/EditModal';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import AddReasonEditModal from '@components/modal/AddReasonEditModal';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import EditableInputField from '@components/field/EditableInputField';
import ChangePhotoModal from '@components/modal/ChangePhotoModal';
import {
  regexOnlyAllowNumberCharacter,
  regexOnlyAllowNumber,
  regexOnlyAllowNumberCharacterSpace,
  membershipOptions,
} from '@utils/Constants';

function StnkCarDetail() {
  const api = new API('v2');
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const [carVariant, setCarVariant] = useState(null);
  const [vin, setVin] = useState('');
  const [year, setYear] = useState('');
  const [membership, setMemberhip] = useState('');
  const [expiredMembership, setExpiredMembership] = useState('');
  const [imageStnk, setImageStnk] = useState({});
  const [imagesCar, setImagesCar] = useState([]);
  const [activeImagesCar, setActiveImagesCar] = useState(0);
  const [status, setStatus] = useState('');
  const [transmission, setTransmission] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [vinError, setVinError] = useState('');
  const [yearError, setYearError] = useState('');
  const [modalType, setModalType] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasModalImageSTNK, setHasModalImageSTNK] = useState(false);
  const [hasModalImagesCar, setHasModalImagesCar] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [imageLightbox, setImageLightbox] = useState('');
  const [saveType, setSaveType] = useState('save');
  const [disabledForm, setDisabledForm] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addReasonModal, setAddReasonModal] = useState(false);
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowVerification, setAllowVerification] = useState(false);
  const [prefixPlate, setPrefixPlate] = useState('');
  const [numberPlate, setNumberPlate] = useState('');
  const [suffixPlate, setSuffixPlate] = useState('');
  const [errorPlateNumber, setErrorPlateNumber] = useState('');
  const [tags, setTags] = useState([]);
  const [tagOption, setTagOption] = useState([]);
  const [inputTag, setInputTag] = useState('');
  const [customerData, setCustomerData] = useState({});

  const breadcrumb = [
    {
      id: 1,
      label: 'Detail Customer Car',
      active: false,
      url: `/stnk/${id}`,
    },
    {
      id: 2,
      label: 'Edit Customer Car',
      active: true,
      url: '',
    },
  ];

  const statusOptions = [
    {
      id: 1,
      label: 'Verified',
      value: 'verified',
    },
    {
      id: 2,
      label: 'Unverified',
      value: 'unverified',
    },
  ];

  const transmissionOptions = [
    {
      value: 'at',
      label: 'Automatic',
    },
    {
      value: 'mt',
      label: 'Manual',
    },
  ]

  const openStnkDetail = () => {
    if (generatePermission('customer', 'cardetail') || generatePermission('customer', 'carall')) {
      history.push(`/stnk/${id}`);
    }
  };

  const handleBack = () => {
    history.push(`/stnk/${id}`);
  };

  const permissionEditVerification = () => {
    const isEdit =
      (generatePermission('customer', 'caredit') || generatePermission('customer', 'carall')) && status == 'unverified'
        ? true
        : false;
    const isVerification =
      generatePermission('customer', 'carverification') || generatePermission('customer', 'carall');
    const editAllow = isEdit || isVerification ? true : false;

    setAllowEdit(editAllow);
    setAllowVerification(isVerification);
  };

  const handleChangeCarVariantChange = (item) => {
    setCarVariant(item);
  };

  const handleChangeStatus = (item) => {
    setStatus(item);
  };

  const handleChangeTransmission = (item) => {
    setTransmission(item)
  }

  async function fetchCarVariants(userInputValue) {
    if (userInputValue) {
      return await api.get(`v2/intools/cars/search?q=${userInputValue}`).then((response) => {
        return response.data.data;
      });
    }
  }

  async function loadStnkDetail() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/user-cars/${id}/`);
      const detailStnk = response?.data?.data ? response?.data?.data : {};
      setCustomerData(detailStnk?.customer);
      mappingData(detailStnk);
      setLoading(false);
    } catch (error) {
      mappingData({});
      setLoading(false);
    }
  }

  const mappingData = (detailStnk) => {
    const selectedMembership = {
      value: detailStnk?.membership,
      label: detailStnk?.membership,
    };
    const selectedStatus = {
      id: detailStnk?.stnk_status === 'verified' ? 1 : 2,
      label: detailStnk?.stnk_status === 'verified' ? 'Verified' : 'Unverified',
      value: detailStnk?.stnk_status,
    };
    const selectedCarVariant = {
      ...detailStnk?.car_specification,
      car_model: { ...detailStnk?.car_specification?.car_model, id: detailStnk?.car_specification?.car_model?.id },
      fuel: detailStnk?.fuel,
    };
    const tags = detailStnk?.tags?.map((item) => {
      return { value: item, label: item };
    });
    setCarVariant(selectedCarVariant);
    setYear(detailStnk?.year ?? '');
    setVin(detailStnk?.vin_no ?? '');
    setTransmission(detailStnk?.transmission)
    setStatus(selectedStatus);
    setMemberhip(detailStnk?.membership ? selectedMembership : '');
    setExpiredMembership(detailStnk?.membership_expired_at ?? '');
    mappingPlateNumber(detailStnk?.license_plate ?? '');
    mappingImageStnk(detailStnk);
    mappingImagesCar(detailStnk);
    setTags(detailStnk?.tags ? tags : []);
  };

  const mappingPlateNumber = (plateNumber) => {
    const regexNumb = new RegExp(regexOnlyAllowNumber);
    const cleanPlate = plateNumber ? plateNumber.replace(/  +/g, ' ').trim().toUpperCase() : '';
    const splitPlate = cleanPlate ? cleanPlate.split(' ') : [];

    let prefix = '';
    let number = '';
    let suffix = '';

    if (splitPlate?.length == 2) {
      if (regexNumb.test(splitPlate[0])) {
        number = splitPlate[0] ? splitPlate[0].substring(0, 4) : '';
        suffix = splitPlate[1] ? splitPlate[1].substring(0, 3) : '';
      } else {
        prefix = splitPlate[0] ? splitPlate[0].substring(0, 2) : '';
        number = splitPlate[1] ? splitPlate[1].substring(0, 4) : '';
      }
    } else if (splitPlate?.length == 1) {
      if (regexNumb.test(splitPlate[0])) {
        number = splitPlate[0] ? splitPlate[0].substring(0, 4) : '';
      } else {
        prefix = splitPlate[0] ? splitPlate[0].substring(0, 2) : '';
      }
    } else {
      prefix = splitPlate[0] ? splitPlate[0].substring(0, 2) : '';
      number = splitPlate[1] ? (!regexNumb.test(splitPlate[1]) ? '' : splitPlate[1].substring(0, 4)) : '';
      suffix = splitPlate[2] ? splitPlate[2].substring(0, 3) : '';
    }

    setPrefixPlate(prefix);
    setNumberPlate(number);
    setSuffixPlate(suffix);
  };

  const mappingImageStnk = (detailStnk) => {
    const stnkImage = {
      img: detailStnk?.stnk?.url,
      key: detailStnk?.stnk?.key,
      is_deleted: false,
    };

    setImageStnk(stnkImage);
  };

  const mappingImagesCar = (detailStnk) => {
    const carImages = map(detailStnk?.car_images, (item) => {
      return {
        id: item?.id,
        img: item?.url,
        key: item?.key,
        is_deleted: false,
      };
    });

    setImagesCar(carImages);
  };

  const validatePlateNumber = (prefix, number, suffix) => {
    const isPassValidatePlateNumber = (prefix || suffix) && !number ? false : true;
    setErrorPlateNumber(isPassValidatePlateNumber ? '' : 'Plat Nomor harus diisi');

    return isPassValidatePlateNumber;
  };

  const validation = () => {
    let status = true;
    const regexNumb = new RegExp(regexOnlyAllowNumber);
    const regexNumbChar = new RegExp(regexOnlyAllowNumberCharacter);
    const regexNumbCharSpace = new RegExp(regexOnlyAllowNumberCharacterSpace);

    if (!validatePlateNumber(prefixPlate, numberPlate, suffixPlate)) {
      status = false;
    }

    if (!regexNumb.test(year)) {
      setYearError('Format Year is wrong');
      status = false;
    } else if (year.length !== 4 && year.length !== 0) {
      setYearError('Format Year must 4 character');
      status = false;
    }

    if (!regexNumbChar.test(vin)) {
      setVinError('Format Vin No is wrong');
      status = false;
    } else if (vin.length > 20 && vin.length !== 0) {
      setVinError('Format Vin No max 20 character');
      status = false;
    }

    return status;
  };

  const validationReason = () => {
    let status = true;

    if (reason == '') {
      setReasonError('Reason is required');
      status = false;
    } else if (reason.length > 200) {
      setReasonError('Format Reason max 200 characters');
      status = false;
    }

    return status;
  };

  const handleUploadImage = (result) => {
    const isImageStnk = hasModalImageSTNK;
    const isImageCar = hasModalImagesCar;
    const file = result?.file;
    const preview = result?.preview;

    setDisabledForm(true);
    setHasModalImageSTNK(false);
    setHasModalImagesCar(false);

    if (file?.name) {
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'user_cars');
      paramsImagedUpload.append('identifier', id);
      paramsImagedUpload.append('image', file);

      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            handleAddImages(keyFile, preview, isImageStnk, isImageCar);
            setDisabledForm(false);
          } else {
            handleFailUploadImage('Gagal upload image');
          }
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          handleFailUploadImage(message);
        });
    } else {
      handleFailUploadImage('Gagal upload image, file tidak diketahui');
    }
  };

  const handleFailUploadImage = (msg) => {
    setDisabledForm(false);
    showAlert('danger', msg);
  };

  const handleAddImages = (key, img, isImageStnk, isImageCar) => {
    if (isImageStnk) {
      imageStnk.img = img;
      imageStnk.key = key;
      imageStnk.is_deleted = false;

      setImageStnk({ ...imageStnk });
    }

    if (isImageCar) {
      let imageCarData = {
        img: img,
        key: key,
        is_deleted: false,
      };

      setImagesCar([...imagesCar, imageCarData]);
    }
  };

  const handleDeleteImageSTNK = () => {
    if (!disabledForm && !isChecking) {
      imageStnk.img = '';
      imageStnk.key = '';
      imageStnk.is_deleted = true;

      setImageStnk({ ...imageStnk });
    }
  };

  const handleDeleteImagesCar = (item, index) => {
    if (!disabledForm && !isChecking) {
      if (item?.id) {
        imagesCar[index].is_deleted = true;
      } else {
        imagesCar.splice(index, 1);
      }

      setImagesCar([...imagesCar]);
    }
  };

  const handleChangeImageCars = () => {
    let countActiveImages = 0;
    imagesCar.forEach((item) => {
      if (!item?.is_deleted) {
        countActiveImages += 1;
      }
    });

    setActiveImagesCar(countActiveImages);
  };

  const handleShowLightbox = (img) => {
    if (!disabledForm && !isChecking && allowEdit) {
      setShowLightbox(img ? true : false);
      setImageLightbox(img ?? '');
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!validation()) {
      return;
    }
    setModalType('Simpan');
    setSaveType('save');
    setConfirmModal(true);
  };

  const handleVerified = () => {
    setModalType('Verified');
    setSaveType('verified');
    setConfirmModal(true);
  };

  const handleUnverified = () => {
    setModalType('Unverified');
    setSaveType('unverified');
    setReason('');
    setReasonError('');
    setAddReasonModal(true);
  };

  const getCarImages = () => {
    const carImages = map(imagesCar, (item) => {
      let newItem = {};
      if (item?.id) {
        newItem.id = item?.id;
      }
      (newItem.key = item?.key), (newItem.is_deleted = item?.is_deleted);
      return newItem;
    });

    return carImages;
  };

  const handleOnCofirmUnverified = (e) => {
    e.preventDefault();
    if (!validationReason()) {
      return;
    }
    handleOnConfirm();
  };

  const handleOnConfirm = () => {
    if (saveType == 'save') {
      let payload = {
        car_model: carVariant,
        license_plate: licensePlate ?? '',
        vin_no: vin ?? '',
        year: year ?? '',
        transmission: transmission?.value,
        membership: membership?.value ?? '',
        membership_expired_at: expiredMembership ?? '',
        stnk_image: {
          key: imageStnk?.key ?? '',
          is_deleted: imageStnk?.is_deleted,
        },
        car_images: getCarImages(),
        status: status,
        tags: tags ? tags.map((item) => item?.label) : [],
      };
      setConfirmModal(false);
      updateCarDetails(payload);
    }

    if (saveType == 'verified') {
      let payload = {
        is_verified: saveType === 'verified' ? true : false,
      };
      setConfirmModal(false);
      verificationCarDetails(payload);
    }

    if (saveType == 'unverified') {
      let payload = {
        is_verified: saveType === 'verified' ? true : false,
        reject_reason: reason,
      };
      setAddReasonModal(false);
      verificationCarDetails(payload);
    }
  };

  async function verificationCarDetails(payload) {
    setDisabledForm(true);

    try {
      await api.put(`v2/intools/user-cars/${id}/verification/`, payload);
      showAlert('success', `Berhasil update car details.`);
      setTimeout(() => {
        openStnkDetail();
      }, 500);
    } catch (error) {
      setDisabledForm(false);
      showAlert('danger', `Gagal update car details.`);
    }
  }

  async function updateCarDetails(payload) {
    setDisabledForm(true);

    try {
      await api.put(`v2/intools/user-cars/${id}/`, payload);
      showAlert('success', 'Berhasil update car details.');
      const payloadStatus = {
        is_verified: payload?.status?.id === 1 ? true : false,
      };
      verificationCarDetails(payloadStatus);
    } catch (error) {
      setDisabledForm(false);
      showAlert('danger', 'Gagal update car details.');
    }
  }

  const getTagOptions = () => {
    return api
      .get(`v2/intools/user-cars/customer/${customerData?.id}/car-tags`)
      .then((response) => {
        let result = map(response?.data?.data, (item) => {
          return { value: item, label: item ?? '-' };
        });
        setTagOption(result);
      })
      .catch((err) => {
        setTagOption();
        const error = err?.response?.data?.error?.message;
        showAlert('danger', 'Gagal mendapatkan Tags, ' + error);
      });
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };

  const minDate = () => {
    const today = new Date().toISOString().split('T')[0];
    return today;
  };

  const handleAddNewTag = () => {
    let data = { value: inputTag, label: inputTag };
    tags ? tags.push(data) : setTags([data]);
    setInputTag();
  };

  useEffect(() => {
    let plateNumber = prefixPlate ?? '';
    plateNumber += numberPlate ? (plateNumber ? ' ' + numberPlate : numberPlate) : '';
    plateNumber += suffixPlate ? (plateNumber ? ' ' + suffixPlate : suffixPlate) : '';

    setLicensePlate(plateNumber);
  }, [prefixPlate, numberPlate, suffixPlate]);

  useEffect(() => {
    handleChangeImageCars();
  }, [imagesCar]);

  useEffect(() => {
    permissionEditVerification();
    setIsChecking(status === 'checking' ? true : false);
  }, [status]);

  useEffect(() => {
    if (customerData?.fleet_customer) {
      getTagOptions();
    }
  }, [customerData?.fleet_customer]);

  useEffect(() => {
    loadStnkDetail(id);
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        {showLightbox && <Lightbox mainSrc={imageLightbox} onCloseRequest={() => setShowLightbox(false)} />}

        <ChangePhotoModal
          isValidation={true}
          sizeValidation={5120}
          modal={hasModalImageSTNK}
          title={'Tambahkan foto untuk STNK'}
          resultUploadFile={handleUploadImage}
          toggle={() => setHasModalImageSTNK(!hasModalImageSTNK)}
        />

        <ChangePhotoModal
          isValidation={true}
          sizeValidation={5120}
          modal={hasModalImagesCar}
          title={'Tambahkan foto untuk Mobil'}
          resultUploadFile={handleUploadImage}
          toggle={() => setHasModalImagesCar(!hasModalImagesCar)}
        />

        <Wrapper title="Edit Customer Car" breadcrumbData={breadcrumb}>
          <Card>
            <CardHeader className="bg-primary text-white mt-0">{'Edit Customer Car'}</CardHeader>
            <CardBody>
              <Row>
                <Col sm={12} md={12}>
                  {allowVerification ? (
                    <FormGroup row>
                      <Label sm={3}>Status</Label>
                      <SelectField
                        colWidth={5}
                        disabled={loading}
                        value={status}
                        options={statusOptions}
                        getOptionLabel={(e) => <StnkBadge status={e?.value} />}
                        getOptionValue={(e) => e.value}
                        onChange={handleChangeStatus}
                      />
                    </FormGroup>
                  ) : (
                    <FormGroup row>
                      <Label lg={3}>Status</Label>
                      <Col lg={5} className="pt-2">
                        <StnkBadge status={status} />
                      </Col>
                    </FormGroup>
                  )}

                  <FormGroup row>
                    <Label sm={3}>Mobil</Label>
                    <SelectAsyncField
                      colWidth={5}
                      cacheOptions
                      defaultOptions={[]}
                      value={carVariant}
                      getOptionLabel={(e) => `${e.car_model?.model_name} ${e?.variant} - ${e?.fuel}`}
                      getOptionValue={(e) => e.id}
                      loadOptions={fetchCarVariants}
                      onChange={handleChangeCarVariantChange}
                      menuPlacement="top"
                      placeholder="Cari..."
                      disabled={loading}
                    />
                  </FormGroup>

                  <FormGroup row>
                      <Label sm={3}>Transmisi</Label>
                      <SelectField
                        colWidth={5}
                        disabled={loading}
                        value={transmission}
                        options={transmissionOptions}
                        onChange={handleChangeTransmission}
                      />
                    </FormGroup>

                  {customerData?.fleet_customer && (
                    <FormGroup row>
                      <Label sm={3}>Tags</Label>
                      <Col sm={5} className="p-0">
                        <SelectField
                          placeholder={'Pilih Tags'}
                          isMulti={true}
                          disabled={disabledForm || isChecking || !allowEdit}
                          closeMenuOnSelect={true}
                          options={tagOption}
                          value={tags}
                          onChange={(e) => setTags(e)}
                          isCreate={inputTag?.length > 0 ? true : false}
                          textLabelCreate={'Tambah Tags'}
                          needInputChange={true}
                          isLowerCaseInputSearch={true}
                          onInputChange={(e) => setInputTag(e)}
                          onClickCreate={() => handleAddNewTag()}
                        />
                      </Col>
                    </FormGroup>
                  )}

                  <FormGroup row>
                    <Label sm={3}>License Plate</Label>
                    <Col sm={5} className="p-0">
                      <Row className="mx-0">
                        <Col lg={4} xs={4}>
                          <InputMask
                            className={`mask-input valid`}
                            value={prefixPlate?.toUpperCase()}
                            mask={'**'}
                            maskPlaceholder={null}
                            disabled={disabledForm || isChecking || !allowEdit}
                            onChange={(e) => {
                              setPrefixPlate(e.target.value.toUpperCase());
                              validatePlateNumber(e.target.value.toUpperCase(), numberPlate, suffixPlate);
                            }}
                          >
                            <Input placeholder={'AB'}></Input>
                          </InputMask>
                        </Col>
                        <Col lg={4} xs={4}>
                          <InputMask
                            className={`mask-input ${errorPlateNumber ? 'invalid' : 'valid'}`}
                            value={numberPlate?.toUpperCase()}
                            aria-invalid={errorPlateNumber}
                            mask={'9999'}
                            maskPlaceholder={null}
                            disabled={disabledForm || isChecking || !allowEdit}
                            onChange={(e) => {
                              setNumberPlate(e.target.value.toUpperCase());
                              validatePlateNumber(prefixPlate, e.target.value.toUpperCase(), suffixPlate);
                            }}
                          >
                            <Input className="input-field" placeholder={'9999'} invalid={errorPlateNumber}></Input>
                          </InputMask>
                        </Col>
                        <Col lg={4} xs={4}>
                          <InputMask
                            className={`mask-input valid`}
                            value={suffixPlate?.toUpperCase()}
                            mask={'***'}
                            maskPlaceholder={null}
                            disabled={disabledForm || isChecking || !allowEdit}
                            onChange={(e) => {
                              setSuffixPlate(e.target.value.toUpperCase());
                              validatePlateNumber(prefixPlate, numberPlate, e.target.value.toUpperCase());
                            }}
                          >
                            <Input placeholder={'XYZ'}></Input>
                          </InputMask>
                        </Col>
                        <Col>
                          <div className="invalid-hour">{errorPlateNumber}</div>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <EditableInputField
                    labelWidth={3}
                    colWidth={5}
                    title="Year"
                    inputName="year"
                    value={year}
                    placeholder="Year"
                    disabled={disabledForm || isChecking || !allowEdit}
                    onChange={(e) => {
                      setYear(e.target.value);
                      if (!e.target.validity.valid) {
                        setYearError('Format Year is wrong');
                      }
                      if (e.target.validity.valid && e.target.value.length !== 4 && e.target.value.length !== 0) {
                        setYearError('Format Year must 4 character');
                      }
                      if ((e.target.validity.valid && e.target.value.length == 4) || e.target.value.length == 0) {
                        setYearError();
                      }
                    }}
                    invalid={yearError ? true : false}
                    errorMessage={yearError}
                    inputPattern={regexOnlyAllowNumber}
                  />

                  <EditableInputField
                    labelWidth={3}
                    colWidth={5}
                    title="Vin No"
                    inputName="vin"
                    value={vin}
                    placeholder="Vin No"
                    disabled={disabledForm || isChecking || !allowEdit}
                    onChange={(e) => {
                      setVin(e.target.value);
                      if (!e.target.validity.valid) {
                        setVinError('Format Vin No is wrong');
                      }
                      if (e.target.validity.valid && e.target.value.length > 20 && e.target.value.length !== 0) {
                        setVinError('Format Vin No max 20 character');
                      }
                      if ((e.target.validity.valid && e.target.value.length <= 20) || e.target.value.length == 0) {
                        setVinError();
                      }
                    }}
                    inputPattern={regexOnlyAllowNumberCharacter}
                    invalid={vinError ? true : false}
                    errorMessage={vinError}
                  />

                  <FormGroup row>
                    <Label sm={3}>Membership</Label>
                    <Col sm={5} className="p-0">
                      <SelectField
                        placeholder={'Pilih Membership'}
                        disabled={disabledForm || isChecking || !allowEdit}
                        closeMenuOnSelect={true}
                        options={membershipOptions}
                        value={membership}
                        onChange={(e) => setMemberhip(e)}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label sm={3}>Expired Membership</Label>
                    <InputField
                      id="expired-membership"
                      colWidth={5}
                      inputType="date"
                      placeholder="Expired Membership"
                      disabled={disabledForm || isChecking || !allowEdit}
                      onChange={(e) => setExpiredMembership(e.target.value)}
                      value={expiredMembership}
                      inputMin={minDate()}
                    />
                  </FormGroup>

                  <Row>
                    <Label lg={3}>Foto STNK</Label>
                    <Col lg={5}>
                      <Row>
                        <div className="galeri-container">
                          <div className="box mb-4">
                            <img
                              src={imageStnk?.img ? imageStnk?.img : NoImage}
                              onClick={() => handleShowLightbox(imageStnk?.img)}
                            />
                            {imageStnk?.img && !isChecking && allowEdit && (
                              <img
                                className="icon-trash"
                                src={TrashIcon}
                                onClick={() => {
                                  handleDeleteImageSTNK();
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Row>
                      {!imageStnk?.img && !isChecking && allowEdit && (
                        <Row className="mb-4">
                          <div className="d-flex h-100 justify-content-center">
                            <div className="justify-content-center align-self-center">
                              <Button
                                color="primary"
                                size="lg"
                                className="btn-add-foto"
                                disabled={disabledForm || isChecking || !allowEdit}
                                onClick={() => setHasModalImageSTNK(true)}
                              >
                                <img src={PlusFill} className="mr-2" />
                                Browse
                              </Button>
                            </div>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Label lg={3}>Photo Mobil</Label>
                    <Col lg={9}>
                      <Row>
                        <div className="galeri-container">
                          {map(imagesCar, (item, index) => {
                            if (!item.is_deleted) {
                              return (
                                <div className="box mb-4">
                                  <img src={item?.img} onClick={() => handleShowLightbox(item?.img)} />
                                  {item?.img && !isChecking && allowEdit && (
                                    <img
                                      className="icon-trash"
                                      src={TrashIcon}
                                      onClick={() => {
                                        handleDeleteImagesCar(item, index);
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            }
                          })}
                          {activeImagesCar > 0 && activeImagesCar < 4 && !isChecking && allowEdit && (
                            <div className="box mb-4">
                              <img src={NoImage} />
                            </div>
                          )}
                          {activeImagesCar == 0 && (
                            <div className="box mb-4">
                              <img src={NoImage} />
                            </div>
                          )}
                        </div>
                      </Row>

                      {activeImagesCar < 4 && !isChecking && allowEdit && (
                        <Row>
                          <div className="d-flex h-100 justify-content-center">
                            <div className="justify-content-center align-self-center">
                              <Button
                                color="primary"
                                size="lg"
                                className="btn-add-foto"
                                disabled={disabledForm || isChecking || !allowEdit}
                                onClick={() => setHasModalImagesCar(true)}
                              >
                                <img src={PlusFill} className="mr-2" />
                                Browse
                              </Button>
                            </div>
                          </div>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <div className="container-btn-save-car-details mt-5">
                    {allowEdit && (
                      <div>
                        <Button
                          color="primary"
                          className="mr-2 mb-3 btn-save-car-details"
                          onClick={handleSave}
                          disabled={disabledForm}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {!allowEdit && (
                      <Button color="primary" className="mr-2 mb-3 btn-save-car-details" onClick={handleBack}>
                        Back
                      </Button>
                    )}
                  </div>

                  <Row className="mt-3">
                    <Col>
                      {submitStatus && (
                        <div className={`alert alert-${submitStatus?.color}`}>{submitStatus?.message}</div>
                      )}
                    </Col>
                  </Row>

                  <EditModal
                    modal={confirmModal}
                    isCancel={false}
                    headerTitle={`${modalType} Car Details?`}
                    toggle={() => setConfirmModal(!confirmModal)}
                    onConfirm={() => handleOnConfirm()}
                  >
                    <b>{modalType}</b> car details plat nomor <b>{licensePlate}</b>?
                  </EditModal>
                  <AddReasonEditModal
                    headerTitle="Alasan Unverified ?"
                    modal={addReasonModal}
                    toggle={() => {
                      setAddReasonModal(!addReasonModal);
                      setReason('');
                      setReasonError('');
                    }}
                    onConfirm={(e) => handleOnCofirmUnverified(e)}
                  >
                    <EditableInputField
                      labelWidth={0}
                      colWidth={12}
                      placeholder="Alasan"
                      inputName="reason"
                      inputType="textarea"
                      value={reason}
                      onChange={(e) => {
                        e.target.validity.valid ? setReason(e.target.value) : reason;
                        if (e.target.validity.valid && e.target.value.length > 200) {
                          setReasonError('Format Reason max 200 characters');
                        }
                        if (e.target.validity.valid && e.target.value.length <= 200) {
                          setReasonError('');
                        }
                      }}
                      disabledFormText={false}
                      formText={reason.length + ' / 200 characters'}
                      invalid={reasonError !== '' ? true : false}
                      errorMessage={reasonError}
                    />
                  </AddReasonEditModal>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default StnkCarDetail;
