import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import Tabs from '@components/tabs/Tabs';
import Information from '@components/workshops/Information';
import Tax from '@components/workshops/package/Tax';
import TakeRate from '@components/workshops/TakeRate';
import Operational from '@components/workshops/Operational';
import Bank from '@components/workshops/Bank';
import Stakeholder from '@components/workshops/Stakeholder';
import Location from '@components/workshops/Location';
import Gallery from '@components/workshops/Gallery';
import Inventory from '@components/workshops/Inventory';
import Account from '@components/workshops/Account';
import Package from '@components/workshops/Package';
import History from '@components/workshops/History';
import Agreement from '@components/workshops/Agreement';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import { find } from 'lodash';
import qs from 'query-string';

function WorkshopsDetail() {
  const history = useHistory();
  const queryParams = useLocation().search;
  const activetab = new URLSearchParams(queryParams).get('activetab') ?? '';
  const activeproductid = new URLSearchParams(queryParams).get('productid');
  const api = new API('v2');
  const { id } = useParams();
  const { generatePermission } = userPermission();

  const [control, setControl] = useState();
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [resultValidationForm, setResultValidationForm] = useState();
  const [isValidationForm, setIsValidationForm] = useState();
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  const [dataWorkshop, setDataWorkshop] = useState({});
  const [labelTitle, setLabelTitle] = useState('');

  const breadcrumb = [
    {
      id: 1,
      label: 'List Workshop',
      active: false,
      url: '/workshops',
    },
    {
      id: 2,
      label: 'Detail Workshop',
      active: false,
      url: '',
    },
    {
      id: 3,
      label: dataWorkshop?.name ?? '',
      active: true,
      url: '',
    },
  ];

  async function fetchWorkshopData() {
    setMainLoading(true);

    try {
      const response = await api.get(`v2/intools/workshops/${id}/general-info/`);
      setDataWorkshop(response?.data?.data ?? {});
      setMainLoading(false);
      handleGetWorkshop(response?.data?.data ?? {});
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataWorkshop({});
      setMainLoading(false);
      handleResultNotification({ status: true, type: 'fail', message: 'Fail get data workshop ' + errorMsg });
    }
  }

  const handleGetWorkshop = (data) => {
    if (!data?.id) {
      handleResultNotification({ status: true, type: 'fail', message: 'Workshop not found' });
    }
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };

  const allowGeneralInfo = allowPermission('generalinfoshow');
  const allowOperatingHours = allowPermission('operatinghourshow');
  const allowBankAccount = allowPermission('bankaccountshow');
  const allowStakeholder = allowPermission('stakeholdershow');
  const allowLocation = allowPermission('locationshow');
  const allowGallery =
    allowPermission('galleryall') ||
    allowPermission('galleryimageall') ||
    allowPermission('galleryimageshow') ||
    allowPermission('galleryvideoall') ||
    allowPermission('galleryvideoshow');
  const allowInventory = allowPermission('inventoryall') || allowPermission('inventorylist');
  const allowPackage = allowPermission('packageall') || allowPermission('packagelist');
  const allowLogHistory = allowPermission('loghistory');
  const allowFbo = allowPermission('otoklixservicefeeall') || allowPermission('otoklixservicefeelist');

  const isGMS = dataWorkshop?.is_gms;

  const tabsData = [
    {
      title: 'Informasi Bengkel',
      slug: 'information',
      active: false,
      edit_mode: false,
      allow_permission: allowGeneralInfo,
    },
    {
      title: 'Jam Operasional',
      slug: 'operational',
      active: false,
      edit_mode: false,
      allow_permission: allowOperatingHours,
    },
    {
      title: 'Rekening Bank',
      slug: 'bank',
      active: false,
      edit_mode: false,
      allow_permission: allowBankAccount,
    },
    {
      title: 'Stakeholder',
      slug: 'stakeholder',
      active: false,
      edit_mode: false,
      allow_permission: allowStakeholder,
    },
    {
      title: 'Lokasi',
      slug: 'location',
      active: false,
      edit_mode: false,
      allow_permission: allowLocation,
    },
    {
      title: 'Galeri',
      slug: 'gallery',
      active: false,
      edit_mode: false,
      allow_permission: allowGallery,
    },
    {
      title: 'Inventory',
      slug: 'inventory',
      active: false,
      edit_mode: false,
      allow_permission: allowInventory,
    },
    {
      title: 'Account',
      slug: 'account',
      active: false,
      edit_mode: false,
      allow_permission: allowPackage && isGMS,
    },
    // {
    //   title: 'Package',
    //   slug: 'package',
    //   active: false,
    //   edit_mode: false,
    //   allow_permission: allowPackage,
    // },
    {
      title: 'Log History',
      slug: 'history',
      active: false,
      edit_mode: false,
      allow_permission: allowLogHistory,
    },
  ];

  const tabsDataFbo = [
    {
      title: 'Informasi Bengkel',
      slug: 'information',
      active: false,
      edit_mode: false,
      allow_permission: allowGeneralInfo,
    },
    {
      title: 'Jam Operasional',
      slug: 'operational',
      active: false,
      edit_mode: false,
      allow_permission: allowOperatingHours,
    },
    {
      title: 'Rekening Bank',
      slug: 'bank',
      active: false,
      edit_mode: false,
      allow_permission: allowBankAccount,
    },
    {
      title: 'Stakeholder',
      slug: 'stakeholder',
      active: false,
      edit_mode: false,
      allow_permission: allowStakeholder,
    },
    {
      title: 'Lokasi',
      slug: 'location',
      active: false,
      edit_mode: false,
      allow_permission: allowLocation,
    },
    {
      title: 'Galeri',
      slug: 'gallery',
      active: false,
      edit_mode: false,
      allow_permission: allowGallery,
    },
    {
      title: 'Inventory',
      slug: 'inventory',
      active: false,
      edit_mode: false,
      allow_permission: allowInventory,
    },
    {
      title: 'Account',
      slug: 'account',
      active: false,
      edit_mode: false,
      allow_permission: allowPackage && isGMS,
    },
    {
      title: 'Package',
      slug: 'package',
      active: false,
      edit_mode: false,
      allow_permission: allowPackage,
    },
    {
      title: 'Log History',
      slug: 'history',
      active: false,
      edit_mode: false,
      allow_permission: allowLogHistory,
    },
    {
      title: 'FBO',
      slug: 'fbo',
      active: false,
      edit_mode: false,
      allow_permission: allowFbo,
    },
  ];

  const handleChangeActiveTab = (tab, restart) => {
    let params = qs.parse(history?.location?.search);

    params.activetab = tab?.slug;
    if (restart) {
      delete params.productid;
      delete params.category;
      delete params.package_name;
      delete params.status;
      delete params.activecategory;
      delete params.search;
      delete params.display_search;
      delete params.price_from;
      delete params.price_to;
      delete params.component;
      api.cancel();
    }

    setActiveTab(tab);
    history.push({ search: qs.stringify(params) });
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleChangeAction = (action, slug) => {
    let control = {
      action: action,
      slug: slug,
    };
    setControl(control);
  };

  const handleChangeValidationForm = () => {
    setIsValidationForm(true);
  };

  const handleResultValidationForm = (valid) => {
    setResultValidationForm(valid);
    setIsValidationForm(false);
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleChangeWorkshopInfo = (data) => {
    dataWorkshop.name = data?.name ?? '';
    setDataWorkshop({ ...dataWorkshop });
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  useEffect(() => {
    setLabelTitle(dataWorkshop?.name ? 'Detail Workshop (' + dataWorkshop?.name + ')' : 'Detail Workshop');
  }, [dataWorkshop]);

  useEffect(() => {
    fetchWorkshopData();
  }, []);

  const selectedTabs = find(dataWorkshop?.is_fbo ? tabsDataFbo : tabsData, { slug: activetab });

  const renderContent = () => {
    return (
      <React.Fragment>
        {mainLoading && (
          <Wrapper className="wrapperContent">
            <LoadingSpinner></LoadingSpinner>
          </Wrapper>
        )}
        {!mainLoading && (
          <Wrapper
            title={labelTitle}
            customContentClassName="wrapper-content"
            customTitleClassName="wrapper-title"
            breadcrumbData={breadcrumb}
            showNotification={hasNotif}
            notification={notification}
            onCloseNotification={handleCloseNotification}
          >
            {dataWorkshop?.id && (
              <>
                <Tabs
                  control={control}
                  loading={loading}
                  tabs={dataWorkshop?.is_fbo ? tabsDataFbo : tabsData}
                  initialActiveTabs={selectedTabs}
                  onShowModal={() => setResultValidationForm(false)}
                  resultValidationForm={resultValidationForm}
                  onChangeActiveTab={handleChangeActiveTab}
                  onChangeValidationForm={handleChangeValidationForm}
                ></Tabs>
                <Row className="mt-4">
                  <Col sm={12}>
                    {activeTab.slug == 'information' && (
                      <>
                        <Information
                          id={id}
                          isValidationForm={isValidationForm}
                          onChangeAction={handleChangeAction}
                          onResultValidationForm={handleResultValidationForm}
                          onResultNotification={handleResultNotification}
                          onChangeWorkshopInfo={handleChangeWorkshopInfo}
                          onLoading={handleLoading}
                        ></Information>
                        <TakeRate
                          id={id}
                          isValidationForm={isValidationForm}
                          onChangeAction={handleChangeAction}
                          onResultValidationForm={handleResultValidationForm}
                          onResultNotification={handleResultNotification}
                          onLoading={handleLoading}
                        />
                        <Tax id={id} handleResultNotification={handleResultNotification} />
                      </>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'operational' && (
                      <Operational
                        id={id}
                        isValidationForm={isValidationForm}
                        onResultValidationForm={handleResultValidationForm}
                        onChangeAction={handleChangeAction}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                      ></Operational>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'bank' && (
                      <Bank
                        id={id}
                        isValidationForm={isValidationForm}
                        onResultValidationForm={handleResultValidationForm}
                        onChangeAction={handleChangeAction}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                      ></Bank>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'stakeholder' && (
                      <Stakeholder
                        id={id}
                        isValidationForm={isValidationForm}
                        onResultValidationForm={handleResultValidationForm}
                        onChangeAction={handleChangeAction}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                      ></Stakeholder>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'location' && (
                      <Location
                        id={id}
                        isValidationForm={isValidationForm}
                        onChangeAction={handleChangeAction}
                        onResultValidationForm={handleResultValidationForm}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                      ></Location>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'inventory' && (
                      <Inventory
                        id={id}
                        onLoading={handleLoading}
                        dataWorkshop={dataWorkshop}
                        onResultNotification={handleResultNotification}
                      ></Inventory>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'account' && (
                      <Account
                        id={id}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                        activeproductid={activeproductid}
                      ></Account>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'package' && (
                      <Package
                        id={id}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                        activeproductid={activeproductid}
                      ></Package>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'gallery' && (
                      <Gallery
                        id={id}
                        isValidationForm={isValidationForm}
                        onChangeAction={handleChangeAction}
                        onResultValidationForm={handleResultValidationForm}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                      ></Gallery>
                    )}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'history' && <History id={id} onLoading={handleLoading}></History>}
                  </Col>
                  <Col sm={12}>
                    {activeTab.slug == 'fbo' && (
                      <Agreement
                        id={id}
                        isValidationForm={isValidationForm}
                        onChangeAction={handleChangeAction}
                        onResultValidationForm={handleResultValidationForm}
                        onResultNotification={handleResultNotification}
                        onLoading={handleLoading}
                      ></Agreement>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Wrapper>
        )}
      </React.Fragment>
    );
  };

  return renderContent();
}

export default WorkshopsDetail;
