import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, FormGroup, Label, Row, Col } from 'reactstrap';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import WarningIcon from '@assets/icons/warning.svg';
import SelectField from '../field/SelectField';
import API from '@utils/API';
import { map, findIndex } from 'lodash';
import Helper from '@helpers/Helper';
import PackageLineItems from './BookingPackageLineItems';
import SelectAsyncField from '../field/SelectAsyncField';
import SoldOut from '@assets/images/sold-out.png';
import InputField from '../field/InputField';
import { Link } from 'react-router-dom';
import newTabIcon from '@assets/icons/new-tab.svg';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getPackages } from '@store/booking';
import { addTemporaryCart } from '@store/booking';

const BookingDetailPackageForms = (props) => {
  const {
    detailOrder,
    categories,
    selectedWorkshop,
    selectedCar,
    selectedUser,
    accountType,
    handleChangeDetailOrder,
    disableAll,
    tab,
    isDuplicateBooking,
    calculatePromo,
    promoPackageStatus,
    handleChangePromoPackageStatus,
    selectedWorkshops,
  } = props;
  const api = new API('v2');

  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState();
  const [errorCategory, setErrorCategory] = useState('');
  const [selectedPackage, setSelectedPackage] = useState();
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [errorPackage, setErrorPackage] = useState('');
  const [packageOptions, setPackageOptions] = useState([]);
  const [packageQuery, setPackageQuery] = useState('');
  const [sellPrice, setSellPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: '0',
  });
  const [packageDetails, setPackageDetails] = useState([]);
  const [loadingPackageDetails, setLoadingPackageDetails] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoData, setPromoData] = useState({});
  const [packageId, setPackageId] = useState();
  const [b2bPackagePrice, setB2bPackagePrice] = useState('0');
  const [packageQty, setPackageQty] = useState('1');
  const [packageNetPrice, setPackageNetPrice] = useState('0');
  const [packageInformation, setPackageInformation] = useState();
  const [isOpen, setIsOpen] = useState(true);

  const fetchPackage = async (selectedCategories, query = '', source = '', callback) => {
    const workshopIds = selectedWorkshops.map((workshop) => workshop.id);
    const payload = {
      workshop_ids: workshopIds,
      variant_car_id: selectedCar?.car_details?.id,
      service_category_id: selectedCategories || null,
      query,
      customer_type: accountType,
      customer_id: selectedUser?.id,
    };
    let data = [];

    if (source === 'category-filter') setLoadingPackage(true);
    if (selectedWorkshops.length > 0) {
      dispatch(getPackages(payload))
        .then((res) => {
          const transformedData = map(res.payload.data, (item) => {
            return {
              value: item.id,
              label: item.name,
              ...item,
            };
          });

          data = transformedData;
          callback(data);
          setPackageOptions(data);
          if (source === 'category-filter') setLoadingPackage(false);
        })
        .catch((err) => {
          setPackageOptions(data);
          if (source === 'category-filter') setLoadingPackage(false);
        });
    } else {
      await api
        .post(`/v2/intools/workshops/${selectedWorkshop?.id}/packages/`, payload)
        .then((res) => {
          const transformedData = map(res.data.data, (item) => {
            return {
              value: item.id,
              label: item.name,
              ...item,
            };
          });

          data = transformedData;
          callback(data);
          setPackageOptions(data);
          if (source === 'category-filter') setLoadingPackage(false);
        })
        .catch((err) => {
          setPackageOptions(data);
          if (source === 'category-filter') setLoadingPackage(false);
        });
    }
    return data;
  };

  const getPackage = (query, callback) => {
    setPackageQuery(query);

    const res = fetchPackage(selectedCategory?.value, query, 'search-filter', callback);
    return res;
  };

  const getPackageWithCategory = (category) => {
    fetchPackage(category, '', 'category-filter');
  };

  const getPackageDetail = async (packageId) => {
    setLoadingPackageDetails(true);
    
    let url = `v2/intools/workshops/packages/${packageId}?variant_car_id=${selectedCar?.car_details?.id}&service_category_id=${selectedCategory?.value}&customer_type=${accountType}&customer_id=${selectedUser?.id}&workshop_id=${selectedWorkshops[0]?.id}`
    if (!selectedUser?.isB2B){
      url = `v2/intools/workshops/packages/${packageId}?variant_car_id=${selectedCar?.car_details?.id}&service_category_id=${selectedCategory?.value}&customer_type=${accountType}&customer_id=${selectedUser?.id}&workshop_id=${selectedWorkshop?.id}`
    }

    await api
      .get(
        url
      )
      .then((res) => {
        const data = res.data.data;
        setPackageId(+data.package_id);
        const transformedData = map(data.package_details, (item) => {
          let productValue;
          if (item.products.length < 2) productValue = item.products[0];
          return {
            value: productValue && productValue?.is_sellable ? productValue : undefined,
            ...item,
          };
        });
        setPackageDetails(transformedData);
        setSelectedPackage(value);
        setSellPrice({
          moneyValue: `IDR ${Helper.numberWithCommas(data.total_price ?? 0)}`,
          value: data.total_price ?? 0,
        });
        setLoadingPackageDetails(false);
      })
      .catch((err) => {
        console.log('failed get package detail => ', err.response);
        setLoadingPackageDetails(false);
      });
  };

  const getItemCalculation = async (itemCalculation) => {
    const payloadItemCalculation = {
      booking_type: accountType,
      items: itemCalculation,
      customer_id: selectedUser?.id,
      workshop_id: selectedWorkshops ? selectedWorkshops[0]?.id  : selectedWorkshop.id,
    };
    await api
      .post(`v2/intools/bookings/item_calculation/`, payloadItemCalculation)
      .then((res) => {
        const data = res.data.data;
        setB2bPackagePrice(data[0].total_price);
        setPackageNetPrice(data[0].total_nett_price);
      })
      .catch((err) => {
        console.log('failed get Item Calculation => ', err.response);
      });
  };

  const handleChangeCategories = (value) => {
    setSelectedCategory(value);
    getPackageWithCategory(value.value);

    setSelectedPackage();
    setSellPrice({
      formatIsMoney: false,
      formatMoney: 0,
      formatMoneyString: '0',
    });
    setPackageDetails([]);

    if (!value) {
      setErrorCategory('Kategori harus diisi!');
    } else {
      setErrorCategory('');
    }

    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatus({});
  };

  const handleChangePackage = (value) => {
    setSelectedPackage(value);
    getPackageDetail(value.id);
    setIsOpen(true);

    if (!value) {
      setErrorPackage('Paket harus diisi!');
    } else {
      setErrorPackage('');
    }

    const categoriesIndex = findIndex(categories, (val) => {
      return val?.value === value?.category_id;
    });

    if (categoriesIndex !== -1) {
      setSelectedCategory(categories[categoriesIndex]);
    }

    handleChangeSellPrice(value.price);
    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatus({});
  };

  const handleChangePackageDetail = (value, index) => {
    const tempPackageDetail = packageDetails;
    tempPackageDetail[index].value = value;
    handleItemCalculation(tempPackageDetail);
    setPackageDetails(tempPackageDetail);
  };

  const handleItemCalculation = (tempPackageDetail) => {
    const itemCalculation = [];
    for (let x in tempPackageDetail) {
      if (tempPackageDetail[x].value !== undefined) {
        let objt = { package_id: packageId, package_detail_id: tempPackageDetail[x].value.package_details_id };
        itemCalculation.push(objt);
      }
    }
    getItemCalculation(itemCalculation);
  };

  const handleChangeSellPrice = (value, hasQtyModifier) => {
    const pricePerItem = hasQtyModifier?.is_applied ? value / hasQtyModifier?.qty : value;

    try {
      const sellprice = Helper.formatMoneyOnField(pricePerItem);

      setSellPrice(sellprice);
    } catch {
      setSellPrice({
        formatIsMoney: false,
        formatMoney: pricePerItem,
        formatMoneyString: Helper.formatMoney(pricePerItem),
      });
    }
  };

  const handleDefaultLoadPackage = () => {
    if (tab === 'package' && selectedCar) {
      fetchPackage('', '', 'search-filter');
    } else {
      setPackageOptions([]);
    }
  };

  const handleChangePromoCode = (value) => {
    setPromoCode(value?.toUpperCase());
    setPromoData({});
    handleChangePromoPackageStatus({});
  };

  const handleChangePackageInformation = (value) => {
    setPackageInformation(value);
  };

  const handlePackageQty = (value) => {
    setPackageQty(Helper.numberWithCommas(value ?? 1));
  };


  const checkPromoCode = () => {
    if (!disableAll && promoData?.voucher_code != promoCode && promoCode && packageDetails?.length > 0) {
      let package_details = [];
      map(packageDetails, (item) => {
        package_details.push(Number(item?.value?.package_details_id));
      });

      const packages = {
        promo_code: promoCode,
        package_id: selectedPackage?.id,
        package_details: package_details,
        source: 'add',
      };
      calculatePromo(packages, 'package');
      getPromoDetail();
    }
  };

  const getPromoDetail = () => {
    if (!disableAll && promoData?.voucher_code != promoCode) {
      api
        .get(`v2/intools/campaigns/voucher-campaign/${promoCode}/`)
        .then((response) => {
          const data = response?.data?.data ?? {};
          const promo = {
            campaign_id: data?.campaign_id,
            campaign_name: data?.campaign_name,
            voucher_code: data?.voucher_code,
            status: 'success',
          };
          setPromoData(promo);
        })
        .catch((err) => {
          const errMessage = err?.response?.data?.error?.message ?? 'promo code may not be blank';
          const promo = {
            status: 'danger',
            message: errMessage,
          };
          setPromoData(promo);
        });
    }
  };

  const validate = () => {
    let status = true;
    if (!selectedPackage) {
      setErrorPackage('Paket harus diisi!');
      status = false;
    }

    // if (!selectedCategory) {
    //   setErrorCategory('Kategori harus diisi!');
    //   status = false;
    // }

    map(packageDetails, (item) => {
      if (!item?.value) status = false;
    });

    return status;
  };

  const handleSubmit = () => {
    if (!validate()) return;
    if (accountType === 'b2b') {
      for (let x in packageDetails) {
        packageDetails[x].note = packageInformation;
      }
    }

    const payload = {
      name: { ...selectedPackage, type: 'package' },
      package_details: packageDetails,
      categories: selectedCategory,
      sellPrice: { ...sellPrice, value: sellPrice?.formatMoney },
      type: 'package',
      workshop_id:
        selectedWorkshops.length <= 1 ? selectedWorkshop.id : selectedWorkshops.map((workshop) => workshop.id),
      promoData: promoPackageStatus?.color == 'success' ? promoData : {},
      promoStatus: promoPackageStatus?.color == 'success' ? promoPackageStatus : {},
      package_qty: parseInt(packageQty.replace(/,/g, '')) || 1,
    };
    handleChangeDetailOrder(payload);

    resetField();
  };

  const handleIsOpenModal = (packageDetails) => {
    if (packageDetails?.length > 0) {
      if (isOpen) {
        setIsOpen(false);
        handleItemCalculation(packageDetails);
      }
    }
  };

  const resetField = () => {
    setSelectedCategory();
    setSelectedPackage();
    setSellPrice({
      formatIsMoney: false,
      formatMoney: 0,
      formatMoneyString: '0',
    });
    handleDefaultLoadPackage();
    setB2bPackagePrice('0');
    setPackageNetPrice('0');
    setPackageInformation('');
    setPackageDetails([]);
    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatus({});
  };

  useEffect(() => {
    resetField();
  }, [selectedWorkshop, tab]);

  useEffect(() => {
    handleDefaultLoadPackage();
  }, [selectedCar]);

  return (
    <Card className="booking-create-form-items-card">
      <CardBody>
        {!selectedCar && (
          <Alert color="danger" className="d-flex align-items-center">
            <img src={WarningIcon} width={15} className="mr-1" />
            Pilih mobil untuk memilih paket
          </Alert>
        )}
        <Row>
          <Col>
            <Label className="cursor-pointer float-right mb-3" onClick={() => resetField()}>
              <u>Reset</u>
            </Label>
          </Col>
        </Row>
        <FormGroup row>
          <Label lg={3}>Kategori</Label>
          <SelectField
            id="package-category"
            colWidth={9}
            options={categories}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={handleChangeCategories}
            value={selectedCategory || ''}
            invalid={!selectedCategory}
            errorMessage={errorCategory}
            placeholder="Pilih Kategori..."
            disabled={disableAll || !selectedCar}
          />
        </FormGroup>

        <FormGroup row>
          <Label lg={3} className="label-required">
            Paket
          </Label>
          <SelectAsyncField
            id="package"
            colWidth={9}
            isDisabled={disableAll || !selectedCar || loadingPackage}
            value={selectedPackage || ''}
            defaultOptions={packageOptions}
            getOptionLabel={(e) => (
              <span>
                {e.is_fulfilled_by_otoklix == 1 ? (
                  <span className="dropdown-tag">FBO</span>
                ) : e.price_origin ? (
                  <span className="dropdown-tag">{e.price_origin}</span>
                ) : null}
                {e.label}
                {!e.is_compatible && <PackageLineItems items={e} />}
                {!e?.is_sellable && <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />}
              </span>
            )}
            getOptionValue={(e) => e.value}
            loadOptions={debounce(getPackage, 600)}
            needInputChange={true} //adjust note
            onInputChange={() => {
              //adjust note
            }}
            onChange={handleChangePackage}
            invalid={!selectedPackage}
            errorMessage={errorPackage}
            placeholder="Pilih Paket..."
            cacheOptions={false}
            loading={loadingPackage}
            isOptionDisabled={(e) => !e?.is_sellable}
          />
        </FormGroup>
        {loadingPackageDetails ? (
          <FormGroup row>
            <Label lg={9}>Loading package details ...</Label>
          </FormGroup>
        ) : packageDetails?.length > 0 ? (
          map(packageDetails, (item, index) => (
            <FormGroup row>
              <Label lg={3} className="label-required">
                {item?.component_label}
              </Label>
              <SelectField
                id={`package-detail-${index}`}
                colWidth={9}
                options={item?.products}
                getOptionLabel={(e) => (
                  <span>
                    {e.is_fbo && <span className="dropdown-tag">FBO</span>}
                    {e.name}
                    {!e.is_compatible && <PackageLineItems items={e} />}
                    {!e?.is_sellable && <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />}
                  </span>
                )}
                getOptionValue={(e) => e.product_id}
                onChange={(e) => handleChangePackageDetail(e, index)}
                value={item?.value}
                placeholder={item?.prompt}
                disabled={disableAll}
                isOptionDisabled={(e) => !e?.is_sellable}
              />
            </FormGroup>
          ))
        ) : null}
        <>{handleIsOpenModal(packageDetails)}</>
        {accountType === 'b2b' && (
          <>
            <FormGroup row>
              <Label lg={3}>Keterangan</Label>
              <InputField
                colWidth={9}
                placeholder="Keterangan"
                value={packageInformation}
                onChange={(e) => handleChangePackageInformation(e?.target?.value)}
                disabled={false}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Qty Paket</Label>
              <InputField
                colWidth={9}
                placeholder="Qty Paket"
                value={`${Helper.numberWithCommas(packageQty)}`}
                onChange={(e) => handlePackageQty(e?.target?.value)}
                disabled={false}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>B2B Package Price</Label>
              <InputField
                colWidth={9}
                placeholder="B2B Package Price"
                value={`IDR ${Helper.numberWithCommas(b2bPackagePrice ?? 0)}`}
                disabled={true}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Package Nett Price</Label>
              <InputField
                colWidth={9}
                placeholder="Package Nett Price"
                value={`IDR ${Helper.numberWithCommas(packageNetPrice ?? 0)}`}
                disabled={true}
              />
            </FormGroup>
          </>
        )}
        <FormGroup row>
          <Label lg={3}>Kode Promo</Label>
          <InputField
            colWidth={6}
            placeholder="promo code"
            value={promoCode}
            onChange={(e) => handleChangePromoCode(e?.target?.value)}
            disabled={disableAll || !selectedPackage}
            valid={!!promoPackageStatus && promoPackageStatus?.color == 'success'}
            invalid={!!promoPackageStatus && promoPackageStatus?.color == 'danger'}
            errorMessage={promoPackageStatus?.color == 'danger' ? promoPackageStatus?.message : ''}
          />
          <Col lg={3}>
            <Button
              id="create-booking"
              color="purple"
              onClick={checkPromoCode}
              disabled={disableAll || !selectedPackage}
            >
              Pakai
            </Button>
          </Col>
          <Col lg={12} className="mt-2">
            {promoData?.campaign_id && (
              <span>
                Detail campaign <span className="promo-name">{promoData?.campaign_name ?? '-'}</span> dapat diakses
                disini{' '}
                <span>
                  <Link to={`/campaigns/campaign/detail/${promoData?.campaign_id ?? ''}`} target="_blank">
                    <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                  </Link>
                </span>
              </span>
            )}
          </Col>
        </FormGroup>

        <Row className="justify-content-center">
          <Button
            id="package-add"
            color="primary"
            size="lg"
            className="btn-add-item"
            disabled={disableAll || loadingPackage || loadingPackageDetails}
            style={{ marginBottom: '10px' }}
            onClick={handleSubmit}
          >
            <img src={PlusFill} className="mr-2" />
            Tambah
          </Button>
        </Row>
      </CardBody>
    </Card>
  );
};

export default BookingDetailPackageForms;
