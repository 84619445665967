import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import { getActiveLink } from '@reducers/App';
import { resetAuth, getUsername } from '@reducers/Auth';
import userPermission from '@utils/userPermission';
import Logo from '@assets/images/logo.svg';
import CustomModal from '@components/modal/CustomModal';
import ChangePasswordForm from '@components/navbar/ChangePasswordForm';
import API from '@utils/API';
import config from '@utils/config';

function Dashboard() {
  const api = new API('v2');
  const dispatch = useDispatch();
  const activeLink = useSelector(getActiveLink);
  const username = useSelector(getUsername);
  const { showMainMenu, showSubMenu } = userPermission();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [ConfirmNewPassword, setConfirmNewPassword] = useState();
  const [errorMessageCurrent, setErrorMessageCurrent] = useState();
  const [errorMessageNew, setErrorMessageNew] = useState();
  const [errorMessageConfirm, setErrorMessageConfirm] = useState();
  const [disabledForm, setDisabledForm] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const showSystemAll = showSubMenu('system', 'all');

  const toggleSidebar = () => setHideSidebar((prevState) => !prevState);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('intools_tok_c');

    dispatch(resetAuth());
  };

  const hasActiveLink = (router) => {
    if (router === activeLink) {
      return 'active';
    }

    return 'inactive';
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    if (e.target.value.length < 1) {
      setErrorMessageCurrent('tidak boleh kosong');
    } else if (e.target.value.length < 6) {
      setErrorMessageCurrent('minimal character is 6');
    } else if (e.target.value.length > 5) {
      setErrorMessageCurrent();
    }
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length < 1) {
      setErrorMessageNew('tidak boleh kosong');
    } else if (e.target.value.length > 5) {
      if (ConfirmNewPassword !== e.target.value) {
        setErrorMessageConfirm('password tidak sesuai');
        setErrorMessageNew();
      } else if (ConfirmNewPassword === e.target.value) {
        setErrorMessageConfirm();
        setErrorMessageNew();
      }
    } else if (e.target.value.length < 6) {
      setErrorMessageNew('minimal character is 6');
    }
  };

  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
    if (e.target.value.length < 1) {
      setErrorMessageConfirm('tidak boleh kosong');
    } else if (e.target.value.length < 6) {
      setErrorMessageConfirm('minimal character is 6');
    } else if (e.target.value.length > 5) {
      if (newPassword !== e.target.value) {
        setErrorMessageConfirm('password tidak sesuai');
      } else if (newPassword === e.target.value) {
        setErrorMessageConfirm();
      }
    }
  };

  const resetForm = () => {
    setCurrentPassword();
    setNewPassword();
    setConfirmNewPassword();
    setErrorMessageCurrent();
    setErrorMessageNew();
    setErrorMessageConfirm();
  };

  const validationData = () => {
    let status = true;

    if (!currentPassword) {
      setErrorMessageCurrent('tidak boleh kosong');
      status = false;
    } else if (currentPassword.length < 6) {
      setErrorMessageCurrent('minimal character is 6');
      status = false;
    }

    if (!newPassword) {
      setErrorMessageNew('tidak boleh kosong');
      status = false;
    } else if (newPassword.length < 6) {
      setErrorMessageNew('minimal character is 6');
      status = false;
    }

    if (!ConfirmNewPassword) {
      setErrorMessageConfirm('tidak boleh kosong');
      status = false;
    } else if (ConfirmNewPassword.length < 6) {
      setErrorMessageConfirm('minimal character is 6');
      status = false;
    }

    if (newPassword !== ConfirmNewPassword) {
      setErrorMessageConfirm('password tidak sesuai');
      status = false;
    }

    return status;
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();
    if (!validationData()) {
      return;
    }
    handleOnConfirm();
  };

  const handleOnConfirm = () => {
    setDisabledForm(true);
    const payload = {
      current: currentPassword,
      new: newPassword,
      confirm: ConfirmNewPassword,
    };

    api
      .put(`v2/intools/users/change-password/`, payload)
      .then((response) => {
        showAlert('success', response?.message ?? 'Berhasil update password.');
        setTimeout(() => {
          setShowChangePass(false);
          setDisabledForm(false);
          resetForm();
        }, 2000);
      })
      .catch((error) => {
        showAlert('danger', error?.response?.data?.error?.message ?? 'Gagal update password.');
        setTimeout(() => {
          setDisabledForm(false);
        }, 2000);
      });
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };

  const topbarClass = config.app_environment === 'production' ? 'topbar-left-production' : 'topbar-left-staging';
  const navbarClass = config.app_environment === 'staging' ? 'navbar-custom navbar-custom-staging' : 'navbar-custom';
  const version = config.app_version || '1.0.0'; // Default to 1.0.0 if not set

  return (
    <>
      <CustomModal
        modal={showChangePass}
        modalWidth="500"
        backdrop="static"
        toggle={() => {
          setShowChangePass(false);
          resetForm();
        }}
      >
        <ChangePasswordForm
          currentPassword={currentPassword}
          newPassword={newPassword}
          ConfirmNewPassword={ConfirmNewPassword}
          errorMessageCurrent={errorMessageCurrent}
          errorMessageNew={errorMessageNew}
          errorMessageConfirm={errorMessageConfirm}
          toggleClose={() => {
            setShowChangePass(false);
            resetForm();
          }}
          disabledForm={disabledForm}
          onChangeCurrentPassword={handleCurrentPassword}
          onChangeNewPassword={handleNewPassword}
          onChangeConfirmNewPassword={handleConfirmNewPassword}
          onConfirm={(e) => handleSubmitNewPassword(e)}
          submitStatus={submitStatus}
        />
      </CustomModal>

      <div className={`left-sidenav ${hideSidebar ? '' : 'hidden-resp'}`}>
        <div className={topbarClass}>
          <a href="/" className="logo">
            <span>
              <img src={Logo} alt="logo-small" className="logo-sm" />
            </span>
            <div className="app-version">v{version}</div>
          </a>
        </div>

        <div className="leftbar-profile p-3 w-100">
          <div className="media position-relative">
            <div className="leftbar-user online">
              <img
                src="https://images.squarespace-cdn.com/content/v1/58f7904703596ef4c4bdb2e1/1502724307482-GNAIA1FMG8JWMO9CJXNU/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/no+avatar.png"
                className="thumb-md rounded-circle"
              />
            </div>
            <div className="media-body align-self-center text-truncate ml-3">
              <h5 className="mt-0 mb-1 font-weight-semibold">{username}</h5>
              <Row>
                <Col md={4}>
                  <Link to="#" onClick={() => handleLogout()} className="text-uppercase mb-0 font-11">
                    Logout
                  </Link>
                </Col>
                <Col md={4}>
                  <Link
                    to="#"
                    onClick={() => setShowChangePass(!showChangePass)}
                    className="text-uppercase invalid-hour mb-0 font-11"
                  >
                    Change Password
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ul className="left-sidenav-menu slimscroll">
          <li className="leftbar-menu-item mt-2">
            <Link to="/" onClick={() => toggleSidebar()} className={hasActiveLink('dashboard')}>
              <i className="mdi mdi-desktop-mac align-self-center vertical-menu-icon"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          {showMainMenu('booking') && (
            <li className="leftbar-menu-item">
              <Link to="/bookings" onClick={() => toggleSidebar()} className={hasActiveLink('booking')}>
                <i className="mdi mdi-book align-self-center vertical-menu-icon"></i>
                <span>Booking</span>
              </Link>
            </li>
          )}

          {/* {showMainMenu('subscriptions') && (
            <li className="leftbar-menu-item">
              <Link to="/subscriptions" onClick={() => toggleSidebar()} className={hasActiveLink('subscriptions')}>
                <i className="mdi mdi-creation align-self-center vertical-menu-icon"></i>
                <span>Subscriptions</span>
              </Link>
            </li>
          )} */}

          {showMainMenu('ratingreview') && (
            <li className="leftbar-menu-item">
              <Link to="/review-ratings" onClick={() => toggleSidebar()} className={hasActiveLink('reviewRatings')}>
                <i className="mdi mdi-certificate align-self-center vertical-menu-icon"></i>
                <span>Review & Rating</span>
              </Link>
            </li>
          )}

          {(showSubMenu('customer', 'carall') || showSubMenu('customer', 'carlist')) && (
            <li className="leftbar-menu-item">
              <Link to="/stnk" onClick={() => toggleSidebar()} className={hasActiveLink('stnk')}>
                <i className="mdi mdi-id-card align-self-center vertical-menu-icon"></i>
                <span>Customer Car</span>
              </Link>
            </li>
          )}

          {showMainMenu('workshop') && (
            <li className="leftbar-menu-item align-self-center">
              <Link to="/workshops" onClick={() => toggleSidebar()} className={hasActiveLink('workshops')}>
                <i className="mdi mdi-home-analytics align-self-center vertical-menu-icon"></i>
                <span>Workshop</span>
              </Link>
            </li>
          )}

          {/* {showMainMenu('package') && (
            <li className="leftbar-menu-item">
              <Link to="/packages" onClick={() => toggleSidebar()} className={hasActiveLink('packages')}>
                <i className="mdi mdi-package-variant-closed align-self-center vertical-menu-icon"></i>
                <span>Package</span>
              </Link>
            </li>
          )} */}

          {showMainMenu('car') && (
            <li className="leftbar-menu-item">
              <Link to="/cars" onClick={() => toggleSidebar()} className={hasActiveLink('cars')}>
                <i className="mdi mdi-car-side align-self-center vertical-menu-icon"></i>
                <span>Car</span>
              </Link>
            </li>
          )}

          {showMainMenu('customer') && showSubMenu('customer', 'list') && (
            <li className="leftbar-menu-item">
              <Link to="/customer" onClick={() => toggleSidebar()} className={hasActiveLink('customer')}>
                <i className="mdi mdi-account-multiple align-self-center vertical-menu-icon"></i>
                <span>Customer</span>
              </Link>
            </li>
          )}

          {showMainMenu('referral') && (
            <li className="leftbar-menu-item">
              <Link to="/referral" onClick={() => toggleSidebar()} className={hasActiveLink('referral')}>
                <i className="mdi mdi-arrow-decision align-self-center vertical-menu-icon"></i>
                <span>Referral</span>
              </Link>
            </li>
          )}

          {showMainMenu('campaign') && (
            <li className="leftbar-menu-item">
              <Link to="/campaigns" onClick={() => toggleSidebar()} className={hasActiveLink('campaigns')}>
                <i className="mdi mdi-sale align-self-center vertical-menu-icon"></i>
                <span>Campaign</span>
              </Link>
            </li>
          )}

          {(showMainMenu('pushnotification') || showMainMenu('all')) && (
            <li className="leftbar-menu-item">
              <Link
                to="/push-notifications"
                onClick={() => toggleSidebar()}
                className={hasActiveLink('pushNotifications')}
              >
                <i className="mdi mdi-bell align-self-center vertical-menu-icon"></i>
                <span>Push Notifications</span>
              </Link>
            </li>
          )}

          {(showMainMenu('acl') || showMainMenu('all')) && (
            <li className="leftbar-menu-item">
              <Link to="/acl" onClick={() => toggleSidebar()} className={hasActiveLink('acl')}>
                <i className="mdi mdi-account-key align-self-center vertical-menu-icon"></i>
                <span>User & Role</span>
              </Link>
            </li>
          )}

          {showMainMenu('master') && (
            <li className="leftbar-menu-item">
              <Link to="/masters" onClick={() => toggleSidebar()} className={hasActiveLink('masters')}>
                <i className="mdi mdi-database align-self-center vertical-menu-icon"></i>
                <span>Master</span>
              </Link>
            </li>
          )}

          {showMainMenu('package') && (
            <li className="leftbar-menu-item">
              <Link to="/packages" onClick={() => toggleSidebar()} className={hasActiveLink('packages')}>
                <i className="mdi mdi-database align-self-center vertical-menu-icon"></i>
                <span>Package</span>
              </Link>
            </li>
          )}

          {showMainMenu('invoice') && (
            <li className="leftbar-menu-item">
              <Link to="/invoice" onClick={() => toggleSidebar()} className={hasActiveLink('invoice')}>
                <i className="mdi mdi-database align-self-center vertical-menu-icon"></i>
                <span>Invoice</span>
              </Link>
            </li>
          )}
        </ul>
      </div>

      <div className="topbar">
        <nav className={navbarClass}>
          {showMainMenu('system') && (
            <ul className="list-unstyled topbar-nav mb-0">
              <Dropdown tag="li" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle tag="a" caret className="nav-link nav-user nav-transparent" role="button">
                  <span className="p-2 bg-soft-primary nav-user-name hidden-sm rounded">
                    System <i className="mdi mdi-chevron-down" />
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-lg p-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <div className="divider-custom mb-0">
                        <div className="divider-text bg-light">All Services</div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-6">
                              {(showSubMenu('system', 'lumos') || showSystemAll) && (
                                <a
                                  className="dropdown-item mb-2"
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://lumos.otoklix.com"
                                >
                                  Lumos
                                </a>
                              )}

                              {(showSubMenu('system', 'grafana') || showSystemAll) && (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  className="dropdown-item mb-2"
                                  href="https://grafana-openshift-monitoring.apps.ocp.cloud.otoklix.com/"
                                >
                                  Grafana
                                </a>
                              )}

                              {(showSubMenu('system', 'odoo') || showSystemAll) && (
                                <a target="_blank" rel="noreferrer" className="dropdown-item mb-2" href="/">
                                  CRM (Odoo)
                                </a>
                              )}

                              {(showSubMenu('system', 'openshift') || showSystemAll) && (
                                <a
                                  className="dropdown-item"
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://console-openshift-console.apps.ocp.cloud.otoklix.com/"
                                >
                                  Web Console
                                </a>
                              )}
                            </div>
                            <div className="col-6">
                              {(showSubMenu('system', 'intools') || showSystemAll) && (
                                <a
                                  className="dropdown-item mb-2"
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://intools.otoklix.com"
                                >
                                  InTools
                                </a>
                              )}

                              {(showSubMenu('system', 'web') || showSystemAll) && (
                                <a
                                  className="dropdown-item mb-2"
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://otoklix.com"
                                >
                                  Otoklix Web
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </ul>
          )}

          <ul className="list-unstyled topbar-nav mb-0 menu-resp">
            <li>
              <button className="button-menu-mobile nav-link" onClick={() => toggleSidebar()}>
                <i className="mdi mdi-menu align-self-center hamburger-menu" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Dashboard;
