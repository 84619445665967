import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import CustomBreadcrumb from '@components/breadcrumb/CustomBreadcrumb';
import PackageDataHeader from '@components/workshops/package/PackageDataHeader';
import PackageDataDetail from '@components/workshops/package/PackageDataDetail';
import {
  PACKAGE_BOOKING_MAXIMUM_DAY,
  PAYMENT_METHOD_TYPE,
  optionsAllowCustomerType,
  PACKAGE_ALLOWED,
  TRANSMISSION_TYPE_MAP,
} from '@utils/Constants';
import { find } from 'lodash';

function PackageDetail(props) {
  const { id, packageId, handleClickList, handleResultNotification, onLoading } = props;
  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [loading, setLoading] = useState(true);
  const [firstLoadHeader, setFirstLoadHeader] = useState(true);
  const [firstLoadDetail, setFirstLoadDetail] = useState(true);
  const [loadingPackageHeader, setLoadingPackageHeader] = useState(false);
  const [loadingPackageDetail, setLoadingPackageDetail] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [componentOptions, setComponentOptions] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [packageHeader, setPackageHeader] = useState({});
  const [packageDetail, setPackageDetail] = useState([]);
  const [detailPrice, setDetailPrice] = useState();
  const [defaultHeaderPackageAndDiscount, setDefaultHeaderPackageAndDiscount] = useState();
  const [percentageDiscountB2C, setPercentageDiscountB2C] = useState();
  const [percentageDiscountB2B, setPercentageDiscountB2B] = useState();
  const [packagePriceB2C, setPackagePriceB2C] = useState();
  const [packagePriceB2B, setPackagePriceB2B] = useState();
  const [breadcrumbActive, setBreadcrumbActive] = useState('Detail Package');
  const [carCompatibilityOptions, setCarCompatibilityOptions] = useState([]);
  const [customerFilterOptions, setCustomerFilterOptions] = useState([]);
  const [workshopFilterOptions, setWorkshopFilterOptions] = useState([]);
  const [productGroupOptions, setProductGroupOptions] = useState([]);
  const [isEditHeader, setIsEditHeader] = useState(false);
  const [isEditDetail, setIsEditDetail] = useState(false);
  const [isUpdateAllowedCustomerType, setIsUpdateAllowedCustomerType] = useState(false);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Package',
      active: false,
    },
    {
      id: 2,
      label: breadcrumbActive,
      active: true,
    },
  ];

  const handleOnClickBreadcrumb = (item) => {
    if (item?.id == 1) {
      handleClickList();
    }
  };

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'packageall'));
  };

  const allowPackageHeader = allowPermission('packageheadershow') || allowPermission('packageheaderall');
  const allowPackageDetail = allowPermission('packagedetailshow') || allowPermission('packagedetailall');

  async function fetchCategories() {
    let listCategory = [];

    try {
      const response = await api.get(`v2/intools/categories/list-categories-all/`);
      if (response?.data?.data) {
        let resultListCategory = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name, id: item?.id };
        });
        listCategory = resultListCategory;
      }

      setCategoryOptions(listCategory);
    } catch (error) {
      setCategoryOptions(listCategory);
    }
  }

  async function fetchCarCompatibility() {
    let listCarCompatibility = [];

    try {
      const response = await api.get(`v2/intools/packages/car-compatibilities/`);
      if (response?.data?.data) {
        let resultListCarCompatibility = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name, carID: item?.car_details_id };
        });
        listCarCompatibility = resultListCarCompatibility;
      }

      setCarCompatibilityOptions(listCarCompatibility);
    } catch (error) {
      setCarCompatibilityOptions(listCarCompatibility);
    }
  }

  async function fetchCustomers() {
      let listCustomer = [{ value: '', label: 'Any' }];
  
      try {
        const response = await api.get(`v2/intools/customers/?is_enterprise=Y`);
        if (response?.data?.data?.customers) {
          let resultListCustomer = map(response?.data?.data?.customers, (item) => {
            const customerGroupName = item?.customer_group ? item?.customer_group : '';
            return { value: `${item?.id}`, label: `${item?.name} ${customerGroupName ? `- ${customerGroupName}` : ''} (${item?.id})` };
          });
          resultListCustomer.splice(0, 0, { value: 0, label: 'Semua' });
          listCustomer = resultListCustomer;
        }
  
        setCustomerFilterOptions(listCustomer);
      } catch (error) {
        setCustomerFilterOptions(listCustomer);
      }
    }
  
    async function fetchWorkshops() {
      let listWorkshop = [{ value: 0, label: 'Semua' }];
  
      try {
        const response = await api.get(`v2/intools/workshops/?status=activate&no_limit=1`);
        if (response?.data?.data?.workshops) {
          let resultListWorkshop = map(response?.data?.data?.workshops, (item) => {
            return { value: item?.id, label: `${item?.name} (${item?.id})` };
          });
          // resultListWorkshop.splice(0, 0, { value: 0, label: 'Semua' });
          listWorkshop = resultListWorkshop;
        }
  
        setWorkshopFilterOptions(listWorkshop);
      } catch (error) {
        setWorkshopFilterOptions(listWorkshop);
      }
    }
  

  async function fetchProductGroup() {
    let listProductGroup = [];

    try {
      const response = await api.get(`v2/intools/packages/${packageId}/products/`);
      if (response?.data?.data) {
        let resultListProductGroup = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name, id: item?.id };
        });
        listProductGroup = resultListProductGroup;
        listProductGroup.push({ value: null, label: 'No Product Group', id: null });
      }

      setProductGroupOptions(listProductGroup);
    } catch (error) {
      setProductGroupOptions(listProductGroup);
    }
  }

  async function fetchMasterDataComponent() {
    let listComponent = [];
    let listProductType = [];

    try {
      const response = await api.get(`v2/intools/packages/components`);
      if (response?.data?.data) {
        let resultListComponent = map(response?.data?.data?.components, (item) => {
          return { value: item?.id, label: item?.name, placeholder: item?.name_placeholder, prompt: item?.prompt };
        });
        let resultListProductType = map(response?.data?.data?.product_type, (item) => {
          return { value: item?.is_single, label: item?.name };
        });

        listComponent = resultListComponent;
        listProductType = resultListProductType;
      }
      setComponentOptions(listComponent);
      setProductTypeOptions(listProductType);
    } catch (error) {
      setComponentOptions(listComponent);
      setProductTypeOptions(listProductType);
    }
  }

  async function fetchPackageHeader() {
    setLoadingPackageHeader(true);
    try {
      const response = await api.get(`v2/intools/packages/${packageId}/header/`);
      const headerData = response?.data?.data ? mappingPackageHeader(response?.data?.data) : {};
      setPackageHeader(headerData);
      setLoadingPackageHeader(false);
      setFirstLoadHeader(false);
    } catch (error) {
      setPackageHeader({});
      setLoadingPackageHeader(false);
      setFirstLoadHeader(false);
    }
  }

  const mappingPackageHeader = (packageHeader) => {
    let transformedData = packageHeader;
    transformedData.category.value = transformedData?.category?.slug;
    transformedData.category.label = transformedData?.category?.name;
    delete transformedData?.category?.slug;
    delete transformedData?.category?.name;
    // delete transformedData?.category?.id;
    transformedData.sku = transformedData.sku ?? '';
    transformedData.discount_amount = transformedData.discount_amount ?? 0;
    transformedData.is_active = transformedData.is_active == 1 ? true : false;
    transformedData.is_fbo = transformedData.is_fbo == 1 ? true : false;
    transformedData.name = transformedData?.name ? Helper.shortenByDash(transformedData?.name) : '';
    transformedData.product_group.value = transformedData?.product_group?.slug
      ? transformedData?.product_group?.slug
      : null;
    transformedData.product_group.label = transformedData?.product_group?.name
      ? transformedData?.product_group?.name
      : 'No Product Group';
    delete transformedData?.product_group?.slug;
    delete transformedData?.product_group?.name;
    let listCarCompatibility = map(transformedData?.car_compatibility, (item) => {
      return {
        value: item?.slug,
        label: item?.name,
        id: item?.id,
        is_deleted: item?.is_deleted,
        carID: item?.car_details_id,
      };
    });
    let listCustomers = map(transformedData?.customers, (item) => {
      return {
        value: item?.customer_id,
        label: `${item?.customer_name} (${item?.customer_id})`,
        package_config_id: item?.package_config_id
      };
    });
    let listWorkshops = map(transformedData?.workshop, (item) => {
      return {
        value: item?.workshop_id,
        label: `${item?.workshop_name} (${item?.workshop_id})`,
        workshop_id: item?.workshop_id,
        workshop_name: item?.workshop_name,
        package_config_id: item?.package_config_id
      };
    });
    transformedData.car_compatibility = listCarCompatibility;
    transformedData.customers = listCustomers;
    transformedData.workshops = listWorkshops;
    transformedData.transmission = {
      value: transformedData?.transmission,
      label: TRANSMISSION_TYPE_MAP[transformedData?.transmission],
    }

    const defaultPaymentMethod = transformedData?.allowed_customer_type == PACKAGE_ALLOWED.B2C ? 1 : 2;


    transformedData.payment_method_type = transformedData?.allowed_payment_method
      ? find(PAYMENT_METHOD_TYPE, (x) => x?.value === transformedData?.allowed_payment_method)
      : PAYMENT_METHOD_TYPE[defaultPaymentMethod];
    transformedData.booking_day = transformedData?.day_of_booking
      ? find(PACKAGE_BOOKING_MAXIMUM_DAY, (x) => x?.value === transformedData?.day_of_booking)
      : PACKAGE_BOOKING_MAXIMUM_DAY[0];

    return transformedData;
  };

  async function fetchPackageDetail() {
    setLoadingPackageDetail(true);

    try {
      const response = await api.get(`v2/intools/packages/${packageId}/detail/`);
      const detailData = mappingPackageDetail(response?.data?.data ?? []);
      setPackageDetail(detailData);
      setDefaultHeaderPackageAndDiscount({
        b2c_disc: response?.data?.data?.disc_price_percentage ?? 0,
        b2b_disc: response?.data?.data?.disc_price_b2b_percentage ?? 0,
        b2c_package_price: response?.data?.data?.total_price_after_disc ?? 0,
        b2b_package_price: response?.data?.data?.total_price_b2b_after_disc ?? 0,
      });
      setLoadingPackageDetail(false);
      setFirstLoadDetail(false);
    } catch (error) {
      setPackageDetail([]);
      setDefaultHeaderPackageAndDiscount();
      setLoadingPackageDetail(false);
      setFirstLoadDetail(false);
    }
  }

  const mappingPackageDetail = (packageDetails) => {
    let components = [];
    packageDetails?.components?.length &&
      packageDetails?.components?.map((component) => {
        component.is_single = false;
        let product_data = null;
        let original_price = Helper.formatMoneyOnField('' + 0, 'Rp ');
        let original_price_b2b = Helper.formatMoneyOnField('' + 0, 'Rp ');
        let product_price = Helper.formatMoneyOnField('' + 0, 'Rp ');
        let product_price_b2b = Helper.formatMoneyOnField('' + 0, 'Rp ');
        let nett_price = Helper.formatMoneyOnField('' + 0, 'Rp ');
        let active_product = true;
        let products = [];
        let is_removable = component?.is_single ? false : component?.is_removable ?? false;

        component?.products?.length &&
          component?.products?.map((product, idxProduct) => {
            let product_selected = {
              value: product?.product_id ?? null,
              label: product?.name ?? '',
              inventory_id: product?.workshop_inventory_id ?? null,
            };

            const original_price_param =
              packageDetails?.allowed_customer_type == PACKAGE_ALLOWED.B2C
                ? product?.original_price ?? 0
                : product?.price ?? 0;
            const original_price_b2b_param =
              packageDetails?.allowed_customer_type !== PACKAGE_ALLOWED.B2C ? product?.price_b2b ?? 0 : 0;
            const product_price_param =
              packageDetails?.allowed_customer_type == PACKAGE_ALLOWED.B2C
                ? product?.price ?? 0
                : product?.price_after_disc ?? 0;
            const product_price_b2b_param =
              packageDetails?.allowed_customer_type !== PACKAGE_ALLOWED.B2C ? product?.price_b2b_after_disc ?? 0 : 0;
            const nett_price_param = product?.nett_price ?? 0 

              let customerPriceConfig = []

              product?.customer_configured_price?.map((val, idx) => {
                let customerPrice = {
                  key_name: `${val?.customer_name} (${val?.customer_id})`,
                  price: Helper.formatMoneyOnField('' + val?.value, 'Rp '),
                  support_data: {
                    customer_id: val?.customer_id,
                    package_config_id: val?.package_config_id
                  }
                }
                customerPriceConfig.push(customerPrice)
              })

              let workshopPriceConfig = []

              product?.workshop_configured_nett_price?.map((val, idx) => {
                let workshopPrice = {
                  key_name: `${val?.workshop_name} (${val?.workshop_id})`,
                  price: Helper.formatMoneyOnField('' + val?.value, 'Rp '),
                  support_data: {
                    workshop_id: val?.workshop_id,
                    workshop_name: val?.workshop_name,
                    package_config_id: val?.package_config_id,
                    workshop_inventory_id: val?.workshop_inventory_id
                  }
                }
                workshopPriceConfig.push(workshopPrice)
              })

            if (!component?.is_single) {
              let productObj = {
                product: product_selected,
                product_label: product?.name ?? '',
                original_price: Helper.formatMoneyOnField('' + original_price_param, 'Rp '),
                original_price_b2b: Helper.formatMoneyOnField('' + original_price_b2b_param, 'Rp '),
                product_price: Helper.formatMoneyOnField('' + product_price_param, 'Rp '),
                product_price_b2b: Helper.formatMoneyOnField('' + product_price_b2b_param, 'Rp '),
                customer_price_config: customerPriceConfig,
                workshop_price_config: workshopPriceConfig,
                qty: product?.qty ?? null,
                nett_price: Helper.formatMoneyOnField('' + nett_price_param, 'Rp '),
                apps_name: product?.show_in_app_as ?? '',
                is_show_customer_price_config: true,
                is_show_workshop_price_config: true,
                is_show: true,
                is_active: product?.is_active ?? false,
                is_deleted: false,
              };
              products.push(productObj);
            } else {
              product_data = idxProduct == 0 ? product_selected : product_data;
              original_price =
                idxProduct == 0 ? Helper.formatMoneyOnField('' + original_price_param, 'Rp ') : original_price;
              original_price_b2b =
                idxProduct == 0 ? Helper.formatMoneyOnField('' + original_price_b2b_param, 'Rp ') : original_price_b2b;
              product_price =
                idxProduct == 0 ? Helper.formatMoneyOnField('' + product_price_param, 'Rp ') : product_price;
              product_price_b2b =
                idxProduct == 0 ? Helper.formatMoneyOnField('' + product_price_b2b_param, 'Rp ') : product_price_b2b;
              nett_price = idxProduct == 0 ? Helper.formatMoneyOnField('' + nett_price_param, 'Rp ') : nett_price;
              active_product = idxProduct == 0 ? product?.is_active ?? false : active_product;
            }
          });

        let componentObj = {
          is_single: component?.is_single ?? false,
          package_details_id: component?.package_details_id ?? null,
          component_id: component?.component_id ?? null,
          component_label: component?.component_label ?? '',
          description: component?.description ?? '',
          component_placeholder: component?.is_single ? null : component?.name_placeholder ?? null,
          component_prompt: component?.is_single ? null : component?.prompt ?? null,
          component_name: component?.name ?? '',
          first_load_product_options: true,
          loading_product_options: true,
          product_options: [],
          product: product_data,
          original_price: original_price,
          original_price_b2b: original_price_b2b,
          product_price: product_price,
          product_price_b2b: product_price_b2b,
          nett_price: nett_price,
          prompt_text: component?.is_single ? null : component?.prompt ?? '',
          is_active: component?.is_single ? active_product : true,
          is_show: true,
          is_deleted: false,
          is_removable: is_removable,
          products: products,
          qty: component?.qty,
        };
        components.push(componentObj);
      });
    return components;
  };

  const resetHeaderPackageAndDiscount = () => {
    setPercentageDiscountB2C(defaultHeaderPackageAndDiscount?.b2c_disc ?? 0);
    setPercentageDiscountB2B(defaultHeaderPackageAndDiscount?.b2b_disc ?? 0);
    setPackagePriceB2C(defaultHeaderPackageAndDiscount?.b2c_package_price ?? 0);
    setPackagePriceB2B(defaultHeaderPackageAndDiscount?.b2b_package_price ?? 0);
  };

  const handleChangeDetailPrice = (value) => {
    setDetailPrice(value);
  };

  const handleUpdatePackageHeader = (status, error) => {
    if (status) {
      fetchPackageHeader();
      fetchPackageDetail();
    }
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil Update Package Header' : error ?? ''
    );
  };

  const handleUpdatePackageDetail = (status, error) => {
    if (status) {
      fetchPackageHeader();
      fetchPackageDetail();
      fetchProductGroup();
    }
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status
        ? 'Berhasil Update Package Detail, mohon menunggu maksimal 5 menit untuk menampilkan data terupdate'
        : error ?? ''
    );
  };

  const handleUpdateAllowedCustomerType = () => {
    setIsUpdateAllowedCustomerType(true);
    setTimeout(() => {
      setIsUpdateAllowedCustomerType(false);
    }, 10);
  };

  const handleCancelPackageHeader = () => {
    resetHeaderPackageAndDiscount();
  };

  const handleOnChangeDiscountB2C = (discount, package_price) => {
    setPackagePriceB2C(package_price);
    setPercentageDiscountB2C(discount);
  };

  const handleOnChangeDiscountB2B = (discount, package_price) => {
    setPackagePriceB2B(package_price);
    setPercentageDiscountB2B(discount);
  };

  const handleOnEditHeader = (status) => {
    setIsEditHeader(status);
  };

  const handleOnEditDetail = (status) => {
    setIsEditDetail(status);
  };

  useEffect(() => {
    if (packageHeader?.name) {
      setBreadcrumbActive('Detail Package (' + packageHeader?.name + ')');
    } else {
      setBreadcrumbActive('Detail Package');
    }
  }, [packageHeader?.name]);

  useEffect(() => {
    if (!firstLoadHeader && !firstLoadDetail) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [firstLoadHeader, firstLoadDetail]);

  useEffect(() => {
    if (defaultHeaderPackageAndDiscount) {
      resetHeaderPackageAndDiscount();
    }
  }, [defaultHeaderPackageAndDiscount]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    fetchCategories();
    fetchMasterDataComponent();
    fetchPackageHeader();
    fetchPackageDetail();
    fetchCarCompatibility();
    fetchCustomers();
    fetchWorkshops();
    fetchProductGroup();
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <>
          <Card className="card-custom">
            <CardBody className="p-0">
              <CustomBreadcrumb breadcrumbData={breadcrumb} onClick={handleOnClickBreadcrumb}></CustomBreadcrumb>
              <Row>
                {packageHeader?.id && allowPackageHeader && (
                  <Col sm={10} md={10}>
                    <PackageDataHeader
                      isEditDetail={isEditDetail}
                      defaultValuesHeader={packageHeader}
                      packageDetail={packageDetail}
                      packageCategoryOptions={categoryOptions}
                      carCompatibilityOptions={carCompatibilityOptions}
                      customerFilterOptions={customerFilterOptions}
                      workshopFilterOptions={workshopFilterOptions}
                      customerTypeOptions={optionsAllowCustomerType}
                      productGroupOptions={productGroupOptions}
                      detailPrice={detailPrice}
                      loadingPackageHeader={loadingPackageHeader}
                      onSubmit={handleUpdatePackageHeader}
                      onUpdateAllowedCustomerType={handleUpdateAllowedCustomerType}
                      onEditHeader={handleOnEditHeader}
                      onCancelPackageHeader={handleCancelPackageHeader}
                      onChangeDiscountB2C={handleOnChangeDiscountB2C}
                      onChangeDiscountB2B={handleOnChangeDiscountB2B}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                {allowPackageDetail && packageHeader?.allowed_customer_type && (
                  <Col sm={10} md={10}>
                    <PackageDataDetail
                      isEditHeader={isEditHeader}
                      workshopId={id}
                      packageId={packageId}
                      defaultValuesDetail={packageDetail}
                      defaultValuesHeader={packageHeader}
                      packageComponentOptions={componentOptions}
                      packageProductTypeOptions={productTypeOptions}
                      loadingPackageDetail={loadingPackageDetail}
                      percentageDiscountB2C={percentageDiscountB2C}
                      percentageDiscountB2B={percentageDiscountB2B}
                      packagePriceB2C={packagePriceB2C}
                      packagePriceB2B={packagePriceB2B}
                      isUpdateAllowedCustomerType={isUpdateAllowedCustomerType}
                      onChangeDetailPrice={handleChangeDetailPrice}
                      onSubmit={handleUpdatePackageDetail}
                      onEditDetail={handleOnEditDetail}
                    />
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </React.Fragment>
  );
}

export default PackageDetail;
