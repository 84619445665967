import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Select from 'react-select';
import config from '@utils/config';
import AsyncSelect from 'react-select/async';
import Divider from '@components/divider/Divider';
import API from '@utils/API';
import map from 'lodash/map';
import { UserType } from '@utils/Constants';
import InputMask from 'react-input-mask';
import SelectField from '@components/field/SelectField';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import ChangePhotoModal from '@components/modal/ChangePhotoModal';
import NoImage from '@assets/images/no-image.jpeg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import TrashIcon from '@assets/icons/trash.svg';

const CreateUserModal = (props) => {
  const {
    modal,
    toggle,
    title,
    onSubmit,
    submitLoading,
    modalErrorColor,
    hasError,
    errorMessage,
    submitModalSuccess,
    modalType,
    selectedUser,
    phoneNumberInit,
    isFleet,
  } = props;
  const apiv2 = new API('v2');

  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userPhoneNumberSuccess, setUserPhoneNumberSuccess] = useState(false);
  const [userPhoneNumberError, setUserPhoneNumberError] = useState('');
  const [userPhoneNumberLoading, setUserPhoneNumberLoading] = useState(false);
  const [userCarVariant, setUserCarVariant] = useState(null);
  const [userCarTranmission, setUserCarTranmission] = useState(null);
  const [userCarVinNumber, setUserCarVinNumber] = useState(null);
  const [userCarTag, setUserCarTag] = useState(null);
  const [disableUserInput, setDisableUserInput] = useState(false);
  const [isNameNotValid, setIsNameNotValid] = useState(false);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState('');
  const [defaultCarVariantOptions, setDefaultCarVariantOptions] = useState([]);
  const [carTagOptions, setCarTagOptions] = useState([]);
  const [prefixPlate, setPrefixPlate] = useState('');
  const [numberPlate, setNumberPlate] = useState('');
  const [suffixPlate, setSuffixPlate] = useState('');
  const [errorPlateNumber, setErrorPlateNumber] = useState('');
  const [inputTag, setInputTag] = useState('');
  const [hasModalImageSTNK, setHasModalImageSTNK] = useState(false);
  const [imageStnk, setImageStnk] = useState({});
  const [showLightbox, setShowLightbox] = useState(false);
  const [imageLightbox, setImageLightbox] = useState('');
  const [disabledForm, setDisabledForm] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const [allowVerification, setAllowVerification] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  

  const [forms, setForms] = useState({
    fullname: '',
    phonenumber: phoneNumberInit || '',
    email: '',
    userType: 'general',
    carYear: null,
    carVariant: null,
    carLicense: '',
    carVinNumber:'',
  });

  useEffect(() => {
    if (submitModalSuccess) {
      setForms({
        fullname: '',
        phonenumber: '',
        email: '',
        carYear: null,
        carVariant: null,
        carLicense: '',
        carVinNumber:'',
      });
      handleDeleteImageSTNK()
      resetCarOptions();
    }
  }, [submitModalSuccess]);

  useEffect(() => {
    let plateNumber = prefixPlate ?? '';
    plateNumber += numberPlate ? (plateNumber ? ' ' + numberPlate : numberPlate) : '';
    plateNumber += suffixPlate ? (plateNumber ? ' ' + suffixPlate : suffixPlate) : '';

    setForms({ ...forms, carLicense: plateNumber });
  }, [prefixPlate, numberPlate, suffixPlate]);

  useEffect(() => {
    if (modalType === 'new-car') {
      setDisableUserInput(true);
      setUserPhoneNumber(get(selectedUser, 'phone_no', ''));
      setUserPhoneNumberSuccess(true);
      setForms({
        // ...forms,
        fullname: selectedUser?.username,
        phonenumber: selectedUser?.phone_no,
        email: selectedUser?.email,
        userType: selectedUser?.user_type,
        carYear: null,
        carVariant: null,
        carLicense: '',
        carVinNumber:'',
        tags: null,
      });
      handleDeleteImageSTNK()
      resetCarOptions();
      if (selectedUser?.isB2B) fetchTagOptions(selectedUser?.id);
    } else if (modalType === 'new-user') {
      setDisableUserInput(false);

      setUserPhoneNumber(phoneNumberInit || '');
      setForms({
        fullname: '',
        phonenumber: phoneNumberInit || '',
        email: '',
        userType: 'general',
        carYear: null,
        carVariant: null,
        carLicense: '',
        carVinNumber:'',
      });
      resetCarOptions();
      setUserPhoneNumberSuccess(false);
      if (phoneNumberInit) {
        validatePhoneNumber(phoneNumberInit);
      }
    }
  }, [modalType]);

  const resetCarOptions = () => {
    setUserCarTranmission(null);
    setUserCarVariant(null);
    if (!isFleet) setUserCarTag([]);
    setCarTagOptions([]);
    resetPlateNumber();
  };

  const resetPlateNumber = () => {
    setPrefixPlate('');
    setNumberPlate('');
    setSuffixPlate('');
    setErrorPlateNumber('');
  };

  async function fetchTagOptions(cust_id) {
    let listTag = [];
    try {
      const response = await apiv2.get(`v2/intools/user-cars/customer/${cust_id}/car-tags`);
      if (response?.data?.data) {
        listTag = map(response?.data?.data, (item) => {
          return {
            value: item,
            label: item,
          };
        });
        setCarTagOptions(listTag);
      }
    } catch (error) {
      setCarTagOptions(listTag);
    }
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });

    if (name === 'fullname') {
      const regexName = /^[a-zA-Z\s]*$/;
      const isInputCorrect = regexName.test(value);
      if (isInputCorrect) {
        setIsNameNotValid(false);
        setUserNameErrorMessage('');
      } else {
        setIsNameNotValid(true);
        setUserNameErrorMessage('Name is empty or invalid format');
      }
      setForms({ ...forms, [name]: value });
    } else {
      setForms({ ...forms, [name]: value });
    }
  };

  const handleOnYearChange = (e) => {
    const { name, value } = e.target;
    let newValue = value.replace(/[^0-9+]/g, '');
    if (value.length < 5) {
      setForms({ ...forms, [name]: newValue });
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    let isValidated = true;
    if (phoneNumber.indexOf('+62') !== 0) {
      isValidated = false;
      setUserPhoneNumberError('Format not following +62');
    }
    if (isValidated) {
      setUserPhoneNumberLoading(true);
      apiv2
        .get(`${config.api_url_v2}v2/intools/user-accounts/check-user-number/?phone_number=${phoneNumber}`)
        .then((response) => {
          setUserPhoneNumberError('');
          setUserNameErrorMessage('');
          setUserPhoneNumberSuccess(true);
          setForms((currentValues) => {
            currentValues['phonenumber'] = phoneNumber;
            return currentValues;
          });
        })
        .catch((e) => {
          setUserPhoneNumberError(e.response?.data?.error?.message);
        })
        .finally((e) => {
          setUserPhoneNumberLoading(false);
        });
    }
  };

  const handler = useCallback(debounce(validatePhoneNumber, 400), []);

  const changePhoneNumber = (e) => {
    let newValue = e.target.value.replace(/[^0-9+]/g, '');
    newValue = newValue.replace(/(?!^\+)\+/g, '');
    if (newValue !== userPhoneNumber) {
      setUserPhoneNumber(newValue);
      setUserPhoneNumberError('');
      setUserPhoneNumberSuccess(false);
      handler(newValue);
    }
  };

  const fetchCarVariants = (userInputValue) => {
    if (userInputValue) {
      const url = `${config.api_url_v2}v2/intools/cars/search?q=${userInputValue}`;
      return apiv2.get(url).then((response) => {
        return response.data.data;
      });
    }
  };

  const handleCarTagChange = (item) => {
    setUserCarTag(item);
    setForms({ ...forms, tags: item ? item.map((item) => item?.label) : [] });
  };

  const handleCarTransmissionChange = (item) => {
    setUserCarTranmission(item);
    setForms({ ...forms, carTransmission: item.value });
  };

  const handleCarVinNumberChange = (e) => {
    const { name, value } = e.target;
    setUserCarVinNumber(value?.toUpperCase());
    setForms({ ...forms, [name]: value?.toUpperCase() });
  };

  const handleCarVariantChange = (item) => {
    setUserCarVariant(item);
    setUserCarTranmission(null);
    setForms({ ...forms, carVariant: item.id, carLicense: '', carYear: '', carVinNumber: '' ,carTransmission: '' });
    resetPlateNumber();
  };

  const validatePlateNumber = (prefix, number, suffix) => {
    let isPassValidatePlateNumber = (prefix  || suffix ) && number ? true : false;

    if(userCarVinNumber){
      isPassValidatePlateNumber = true
    }

    setErrorPlateNumber(isPassValidatePlateNumber ? '' : 'Plat nomor atau VIN tidak boleh kosong');

    return isPassValidatePlateNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePlateNumber(prefixPlate, numberPlate, suffixPlate)) {
      onSubmit(modalType, forms, userCarVariant);
    }
  };

  const handleCloseModal = (params) => {
    resetCarOptions();
    toggle(params);
  };

  const handleAddNewTag = () => {
    let data = { value: inputTag, label: inputTag };
    userCarTag ? userCarTag.push(data) : setUserCarTag([data]);
    setForms({ ...forms, tags: userCarTag ? userCarTag.map((item) => item?.label) : [] });
    setInputTag();
  };

  const hasErrorForm = () => {
    let hasEmptyField = false;
    let isCarLicenseExist = false;
    let isCarVinNumberExist = false;
    Object.entries(forms).forEach(([key, value]) => {
      if (value) {
        // pass

        if (key === 'carLicense') {
          isCarLicenseExist = true;
        }

        if (key === 'carVinNumber') {
          isCarVinNumberExist = true;
        }

      } else {
        if (key !== 'email' && key != 'carLicense' && key != 'carVinNumber' && key !== 'tags') {
          hasEmptyField = true;
        }

        if (
          modalType === 'new-car' &&
          key !== 'phonenumber' &&
          key !== 'carYear' &&
          key !== 'carVariant' &&
          key !== 'carTransmission' &&
          key !== 'carLicense' &&
          key !== 'tags'
        ) {
          hasEmptyField = false;
        }
      }
    });

    if (!hasEmptyField && !isCarLicenseExist && !isCarVinNumberExist) {
      hasEmptyField = true
    }

    return hasEmptyField;
  };



  const handleShowLightbox = (img) => {
    if (!disabledForm && !isChecking && allowEdit) {
      setShowLightbox(img ? true : false);
      setImageLightbox(img ?? '');
    }
  };

  const handleUploadImage = (result) => {
    const isImageStnk = hasModalImageSTNK;
    const file = result?.file;
    const preview = result?.preview;
    const id = crypto.randomUUID();

    setDisabledForm(true);
    setHasModalImageSTNK(false);

    if (file?.name) {
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'user_cars');
      paramsImagedUpload.append('identifier', id);
      paramsImagedUpload.append('image', file);

      apiv2
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            handleAddImages(keyFile, preview, isImageStnk);
            setDisabledForm(false);
          } else {
            handleFailUploadImage('Gagal upload image');
          }
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          handleFailUploadImage(message);
        });
    } else {
      handleFailUploadImage('Gagal upload image, file tidak diketahui');
    }
  };


  const generateStnkPayload = (imageStnk) => {
    let stnk_image = {
      key: imageStnk?.key ?? '',
      is_deleted: imageStnk?.is_deleted,
     }

     return stnk_image
  }

  const handleAddImages = (key, img, isImageStnk) => {
    console.log(isImageStnk)
    if (isImageStnk) {
      imageStnk.img = img;
      imageStnk.key = key;
      imageStnk.is_deleted = false;

      setImageStnk({ ...imageStnk });
      setForms({ ...forms, stnk_image: generateStnkPayload(imageStnk)});
    }
  };

  const handleDeleteImageSTNK = () => {
    imageStnk.img = '';
    imageStnk.key = '';
    imageStnk.is_deleted = true;

    setImageStnk({ ...imageStnk });
    setForms({ ...forms, stnk_image: generateStnkPayload(imageStnk)});

  };

  const handleFailUploadImage = (msg) => {
    setDisabledForm(false);
    showAlert('danger', msg);
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };


  const isButtonDisabled =
    hasErrorForm() ||
    submitLoading ||
    userPhoneNumberLoading ||
    (modalType === 'new-user' ? !userPhoneNumberSuccess : false) ||
    isNameNotValid;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggle('close')}>
      {showLightbox && <Lightbox mainSrc={imageLightbox} onCloseRequest={() => setShowLightbox(false)} />}
      
      <ChangePhotoModal
          isValidation={true}
          sizeValidation={5120}
          modal={hasModalImageSTNK}
          title={'Tambahkan foto untuk STNK'}
          resultUploadFile={handleUploadImage}
          toggle={() => setHasModalImageSTNK(!hasModalImageSTNK)}
        />

      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <Form>
          <Divider title="User" />

          <FormGroup row>
            <Label sm={4} className="required">
              Nomor Telepon
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="phonenumber"
                placeholder="Ex: +628 111 222 333"
                value={userPhoneNumber}
                onChange={changePhoneNumber}
                disabled={disableUserInput}
                required
              />
              {userPhoneNumberError && <div className="pt-1 text-danger">{userPhoneNumberError}</div>}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Nama Lengkap
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="fullname"
                placeholder="Ex: John Doe"
                value={forms.fullname}
                onChange={handleOnChange}
                disabled={disableUserInput}
                invalid={isNameNotValid}
                required
              />
              {isNameNotValid && <div className="pt-1 text-danger">{userNameErrorMessage}</div>}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4}>Email</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="email"
                placeholder="Ex: johndoe@email.com"
                value={forms.email}
                onChange={handleOnChange}
                disabled={disableUserInput}
                required
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Tipe User
            </Label>
            {disableUserInput && (
              <Col sm={8}>
                <Input
                  type="text"
                  name="userType"
                  value={forms?.userType || '-'}
                  onChange={handleOnChange}
                  disabled={disableUserInput}
                  required
                />
              </Col>
            )}

            {!disableUserInput && (
              <Col sm={8} className="col-form-label text-left">
                <FormGroup tag="fieldset">
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="userType"
                        value="general"
                        onChange={handleOnChange}
                        checked={forms?.userType == 'general'}
                      />{' '}
                      {get(UserType, 'general', 'Undefined')}
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="userType"
                        value="ucd"
                        onChange={handleOnChange}
                        checked={forms?.userType == 'ucd'}
                      />{' '}
                      {get(UserType, 'ucd', 'Undefined')}
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            )}
          </FormGroup>

          <Divider title="Mobil" />

          <FormGroup row>
            <Label sm={4} className="required">
              Mobil
            </Label>
            <Col sm={8}>
              <AsyncSelect
                cacheOptions
                defaultOptions={defaultCarVariantOptions}
                value={userCarVariant}
                getOptionLabel={(e) => `${e.car_model?.model_name} ${e?.variant} - ${e?.fuel}`}
                getOptionValue={(e) => e.id}
                loadOptions={fetchCarVariants}
                onChange={handleCarVariantChange}
                menuPlacement="top"
                placeholder="Pilih..."
              />
            </Col>
          </FormGroup>

          {selectedUser?.isB2B && modalType === 'new-car' && (
            <FormGroup row>
              <Label sm={4}>Tags</Label>
              <Col sm={8} className="p-0">
                <SelectField
                  placeholder={'Pilih Tags'}
                  isMulti={true}
                  closeMenuOnSelect={true}
                  options={carTagOptions}
                  value={userCarTag}
                  onChange={handleCarTagChange}
                  isCreate={inputTag?.length > 0 ? true : false}
                  textLabelCreate={'Tambah Tags'}
                  needInputChange={true}
                  isLowerCaseInputSearch={true}
                  onInputChange={(e) => setInputTag(e)}
                  onClickCreate={() => handleAddNewTag()}
                />
              </Col>
            </FormGroup>
          )}

          <FormGroup row>
            <Label sm={4}>Plat Nomor</Label>
            <Col sm={8} className="p-0">
              <Row className="mx-0">
                <Col lg={4} xs={4}>
                  <InputMask
                    className={`mask-input valid`}
                    value={prefixPlate?.toUpperCase()}
                    mask={'**'}
                    maskPlaceholder={null}
                    onChange={(e) => {
                      setPrefixPlate(e.target.value.toUpperCase());
                      validatePlateNumber(e.target.value.toUpperCase(), numberPlate, suffixPlate);
                    }}
                  >
                    <Input placeholder={'AB'}></Input>
                  </InputMask>
                </Col>
                <Col lg={4} xs={4}>
                  <InputMask
                    className={`mask-input ${errorPlateNumber ? 'invalid' : 'valid'}`}
                    value={numberPlate?.toUpperCase()}
                    aria-invalid={errorPlateNumber}
                    mask={'9999'}
                    maskPlaceholder={null}
                    onChange={(e) => {
                      setNumberPlate(e.target.value.toUpperCase());
                      validatePlateNumber(prefixPlate, e.target.value.toUpperCase(), suffixPlate);
                    }}
                  >
                    <Input className="input-field" placeholder={'9999'} invalid={errorPlateNumber}></Input>
                  </InputMask>
                </Col>
                <Col lg={4} xs={4}>
                  <InputMask
                    className={`mask-input valid`}
                    value={suffixPlate?.toUpperCase()}
                    mask={'***'}
                    maskPlaceholder={null}
                    onChange={(e) => {
                      setSuffixPlate(e.target.value.toUpperCase());
                      validatePlateNumber(prefixPlate, numberPlate, e.target.value.toUpperCase());
                    }}
                  >
                    <Input placeholder={'XYZ'}></Input>
                  </InputMask>
                </Col>
                <Col>
                  <div className="invalid-hour">{errorPlateNumber}</div>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Transmisi
            </Label>
            <Col sm={8}>
              <Select
                onChange={handleCarTransmissionChange}
                value={userCarTranmission}
                options={[
                  {
                    value: 'at',
                    label: 'Automatic',
                  },
                  {
                    value: 'mt',
                    label: 'Manual',
                  },
                ]}
                menuPlacement="top"
                placeholder="Pilih..."
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Tahun
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="carYear"
                placeholder="Ketik tahun .."
                value={forms.carYear}
                onChange={handleOnYearChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Nomor VIN
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="carVinNumber"
                onChange={handleCarVinNumberChange}
                value={forms.carVinNumber}
                menuPlacement="top"
                placeholder="Ketik nomor VIN..."
              />
            </Col>
          </FormGroup>
          <Row>
            <Label lg={3}>Foto STNK</Label>
            <Col lg={5}>
              <Row>
                <div className="galeri-container">
                  <div className="box mb-4">
                    <img
                      src={imageStnk?.img ? imageStnk?.img : NoImage}
                      onClick={() => handleShowLightbox(imageStnk?.img)}
                    />
                    {imageStnk?.img && (
                      <img
                        className="icon-trash"
                        src={TrashIcon}
                        onClick={() => {
                          handleDeleteImageSTNK();
                        }}
                      />
                    )}
                  </div>
                </div>
              </Row>
              {!imageStnk?.img && (
                <Row className="mb-4">
                  <div className="d-flex h-100 justify-content-center">
                    <div className="justify-content-center align-self-center">
                      <Button
                        color="primary"
                        size="lg"
                        className="btn-add-foto"
                        disabled={disabledForm || isChecking || !allowEdit}
                        onClick={() => setHasModalImageSTNK(true)}
                      >
                        <img src={PlusFill} className="mr-2" />
                        Browse
                      </Button>
                    </div>
                  </div>
                </Row>
              )}
            </Col>
          </Row>
          {hasError && <div className={`alert alert-${modalErrorColor}`}>{errorMessage}</div>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" size="lg" onClick={() => handleCloseModal('close')}>
          Tutup
        </Button>{' '}
        <Button color="primary" size="lg" disabled={isButtonDisabled} onClick={handleSubmit}>
          Tambah
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateUserModal;
