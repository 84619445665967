import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Alert, Button, FormGroup, Label, Col } from 'reactstrap';
import WarningIcon from '@assets/icons/warning.svg';
import SelectField from '../field/SelectField';
import API from '@utils/API';
import { map, findIndex } from 'lodash';
import Helper from '@helpers/Helper';
import PackageLineItems from './BookingPackageLineItems';
import SelectAsyncField from '../field/SelectAsyncField';
import SoldOut from '@assets/images/sold-out.png';
import InputField from '../field/InputField';
import { Link } from 'react-router-dom';
import newTabIcon from '@assets/icons/new-tab.svg';

const BookingDetailPackageEditForms = (props) => {
  const {
    selectedData,
    modal,
    toggle,
    categories,
    selectedWorkshop,
    selectedCar,
    selectedUser,
    disableAll,
    handleChangeDetailOrder,
    calculatePromo,
    promoPackageStatusEdit,
    handleChangePromoPackageStatusEdit,
  } = props;

  const api = new API('v2');
  const [selectedCategory, setSelectedCategory] = useState();
  const [errorCategory, setErrorCategory] = useState('');
  const [selectedPackage, setSelectedPackage] = useState();
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [errorPackage, setErrorPackage] = useState('');
  const [packageOptions, setPackageOptions] = useState([]);
  const [sellPrice, setSellPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: '0',
  });
  const [packageDetails, setPackageDetails] = useState([]);
  const [loadingPackageDetails, setLoadingPackageDetails] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promostatus, setPromostatus] = useState({});
  const [promoData, setPromoData] = useState({});
  const [updatePromoStatus, setUpdatePromoStatus] = useState(false);
  const [b2bPackagePrice, setB2bPackagePrice] = useState('0');
  const [packageNetPrice, setPackageNetPrice] = useState('0');
  const [packageId, setPackageId] = useState();
  const [packageQty, setPackageQty] = useState('1');
  const [packageInformation, setPackageInformation] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const fetchPackage = async (selectedCategories, query = '', source = '') => {
    const payload = {
      variant_car_id: selectedCar?.car_details?.id,
      service_category_id: selectedCategories,
      customer_id: selectedUser?.id,
      query,
    };
    let data = [];

    if (source === 'category-filter') setLoadingPackage(true);

    await api
      .post(`/v2/intools/workshops/${selectedWorkshop?.id}/packages/`, payload)
      .then((res) => {
        const transformedData = map(res.data.data, (item) => {
          return {
            value: item.id,
            label: item.name,
            ...item,
          };
        });

        data = transformedData;
        setPackageOptions(data);
        if (source === 'category-filter') setLoadingPackage(false);
      })
      .catch((err) => {
        setPackageOptions(data);
        if (source === 'category-filter') setLoadingPackage(false);
      });

    return data;
  };

  const getPackage = (query) => {
    const res = fetchPackage(selectedCategory?.value, query, 'search-filter');
    return res;
  };

  const getPackageWithCategory = (category) => {
    fetchPackage(category, '', 'category-filter');
  };

  const getPackageDetail = async (packageId) => {
    setLoadingPackageDetails(true);
    await api
      .get(
        `v2/intools/workshops/packages/${packageId}?variant_car_id=${selectedCar?.car_details?.id}&service_category_id=${selectedCategory?.value}&customer_id=${selectedUser?.id}&workshop_id=${selectedWorkshop?.id}`
      )
      .then((res) => {
        const data = res.data.data;
        setPackageId(+data.package_id);
        const transformedData = map(data.package_details, (item) => {
          let productValue;
          if (item.products.length < 2) productValue = item.products[0];
          return {
            value: productValue ?? undefined,
            ...item,
          };
        });
        setPackageDetails(transformedData);
        setSelectedPackage(value);
        setSellPrice({
          moneyValue: `IDR ${Helper.numberWithCommas(data.total_price ?? 0)}`,
          value: data.total_price ?? 0,
        });
        setLoadingPackageDetails(false);
      })
      .catch((err) => {
        console.log('failed get package detail => ', err.response);
        setLoadingPackageDetails(false);
      });
  };

  const handleChangeCategories = (value) => {
    setSelectedCategory(value);
    getPackageWithCategory(value.value);

    setSelectedPackage();
    setSellPrice({
      formatIsMoney: false,
      formatMoney: 0,
      formatMoneyString: '0',
    });
    setPackageDetails([]);

    if (!value) {
      setErrorCategory('Kategori harus diisi!');
    } else {
      setErrorCategory('');
    }

    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatusEdit({});
    setUpdatePromoStatus(true);
  };

  const handleChangePackage = (value) => {
    setSelectedPackage(value);
    getPackageDetail(value.id);

    if (!value) {
      setErrorPackage('Paket harus diisi!');
    } else {
      setErrorPackage('');
    }

    const categoriesIndex = findIndex(categories, (val) => {
      return val?.value === value?.category_id;
    });

    if (categoriesIndex !== -1) {
      setSelectedCategory(categories[categoriesIndex]);
    }

    handleChangeSellPrice(value.price);
    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatusEdit({});
    setUpdatePromoStatus(true);
  };

  const handleIsOpenModal = () => {
    if (isOpen) {
      setIsOpen(false);
      handleItemCalculation(packageDetails);
    }
  };

  const handleChangePackageDetail = (value, index) => {
    let tempPackageDetail = [...packageDetails];
    tempPackageDetail[index].value = value;
    handleItemCalculation(tempPackageDetail);
    setPackageDetails(tempPackageDetail);
  };

  const handleChangeSellPrice = (value, hasQtyModifier) => {
    const pricePerItem = hasQtyModifier?.is_applied ? value / hasQtyModifier?.qty : value;

    try {
      const sellprice = Helper.formatMoneyOnField(pricePerItem);

      setSellPrice(sellprice);
    } catch {
      setSellPrice({
        formatIsMoney: false,
        formatMoney: pricePerItem,
        formatMoneyString: Helper.formatMoney(pricePerItem),
      });
    }
  };

  const cancel = () => {
    toggle();
  };

  const handleChangePromoCode = (value) => {
    setPromoCode(value?.toUpperCase());
    setPromoData({});
    setPromostatus({});
    handleChangePromoPackageStatusEdit({});
  };

  const checkPromoCode = () => {
    if (!disableAll && promoData?.voucher_code != promoCode && promoCode && packageDetails?.length > 0) {
      let package_details = [];
      map(packageDetails, (item) => {
        package_details.push(Number(item?.value?.package_details_id));
      });

      const packages = {
        promo_code: promoCode,
        package_id: selectedPackage?.id,
        package_details: package_details,
        source: 'edit',
      };
      calculatePromo(packages, 'package');
      getPromoDetail();
      setUpdatePromoStatus(true);
    }
  };

  const getPromoDetail = () => {
    if (!disableAll && promoData?.voucher_code != promoCode) {
      api
        .get(`v2/intools/campaigns/voucher-campaign/${promoCode}/`)
        .then((response) => {
          const data = response?.data?.data ?? {};
          const promo = {
            campaign_id: data?.campaign_id,
            campaign_name: data?.campaign_name,
            voucher_code: data?.voucher_code,
            status: 'success',
          };
          setPromoData(promo);
        })
        .catch((err) => {
          const errMessage = err?.response?.data?.error?.message ?? 'promo code may not be blank';
          const promo = {
            status: 'danger',
            message: errMessage,
          };
          setPromoData(promo);
        });
    }
  };

  const handleItemCalculation = (tempPackageDetail) => {
    const itemCalculation = [];
    for (let x in tempPackageDetail) {
      if (tempPackageDetail[x].value !== undefined) {
        let objt = { package_id: packageId, package_detail_id: tempPackageDetail[x].value.package_details_id };
        itemCalculation.push(objt);
      }
    }
    getItemCalculation(itemCalculation);
  };

  const getItemCalculation = async (itemCalculation) => {
    const payloadItemCalculation = {
      booking_type: selectedUser?.isB2B ? 'b2b' : 'b2c',
      items: itemCalculation,
      customer_id: selectedUser?.id,
      workshop_id: selectedWorkshop?.id,
    };
    await api
      .post(`v2/intools/bookings/item_calculation/`, payloadItemCalculation)
      .then((res) => {
        const data = res.data.data;
        setB2bPackagePrice(data[0].total_price);
        setPackageNetPrice(data[0].total_nett_price);
      })
      .catch((err) => {
        console.log('failed get Item Calculation => ', err.response);
      });
  };

  const handleChangePackageInformation = (value) => {
    setPackageInformation(value);
  };

  const resetField = () => {
    setSelectedCategory();
    setSelectedPackage();
    setSellPrice({
      formatIsMoney: false,
      formatMoney: 0,
      formatMoneyString: '0',
    });
    setPackageDetails([]);
    setPromoCode('');
    setB2bPackagePrice('0');
    setPackageNetPrice('0');
    setPromoData({});
    setPromostatus({});
    handleChangePromoPackageStatusEdit({});
  };

  const setDefaultData = () => {
    const selectedPackageDetails = selectedData?.package_details ?? [];
    setPackageId(selectedData?.name?.id);
    setPackageQty(selectedData?.package_qty ? String(selectedData.package_qty) : '1');
    const transformedData = map(selectedPackageDetails, (item) => {
      setPackageInformation(item?.note);
      return {
        value: item.value,
        ...item,
      };
    });

    setSelectedCategory(selectedData?.categories ?? null);
    setPackageDetails(transformedData);
    setSelectedPackage(selectedData?.name ?? null);
    fetchPackage(selectedData?.categories?.value ?? null, '', 'category-filter');
    handleChangePromoCode(selectedData?.promoData?.voucher_code ?? '');
    setPromoCode(selectedData?.promoData?.voucher_code ?? '');
    setPromoData(selectedData?.promoData);
    setPromostatus(selectedData?.promoStatus);
    setUpdatePromoStatus(false);
    setIsOpen(true);
  };

  const validate = () => {
    let status = true;
    if (!selectedPackage) {
      setErrorPackage('Paket harus diisi!');
      status = false;
    }

    map(packageDetails, (item) => {
      if (!item?.value) status = false;
    });

    return status;
  };

  const handlePackageQty = (value) => {
    setPackageQty(Helper.numberWithCommas(value ?? 1));
  };

  const handleSubmit = () => {
    if (!validate()) return;
    for (let x in packageDetails) {
      packageDetails[x].note = packageInformation;
    }
    const payload = {
      name: { ...selectedPackage, type: 'package' },
      package_details: packageDetails,
      categories: selectedCategory,
      sellPrice: { ...sellPrice, value: sellPrice?.formatMoney },
      type: 'package',
      workshop_id: selectedWorkshop.id,
      promoData: promostatus?.color == 'success' ? promoData : {},
      promoStatus: promostatus?.color == 'success' ? promostatus : {},
      package_qty: parseInt(packageQty.replace(/,/g, '')) || 1,
    };

    handleChangeDetailOrder(payload);
    toggle();

    resetField();
  };

  useEffect(() => {
    if (updatePromoStatus) {
      setPromostatus(promoPackageStatusEdit);
    }
  }, [updatePromoStatus, promoPackageStatusEdit]);

  useEffect(() => {
    if (modal) {
      setDefaultData();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Edit Package</div>
        <div className="content">
          {!selectedCar && (
            <Alert color="danger" className="d-flex align-items-center">
              <img src={WarningIcon} width={15} className="mr-1" />
              Pilih mobil untuk memilih paket
            </Alert>
          )}
          <FormGroup row>
            <Label lg={4}>Kategori</Label>
            <SelectField
              id="package-category-edit"
              colWidth={8}
              options={categories}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              onChange={handleChangeCategories}
              value={selectedCategory || ''}
              invalid={!selectedCategory}
              errorMessage={errorCategory}
              placeholder="Pilih Kategori..."
              disabled={disableAll || !selectedCar}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={4} className="label-required">
              Paket
            </Label>
            <SelectAsyncField
              id="package-edit"
              colWidth={8}
              isDisabled={disableAll || !selectedCar || loadingPackage}
              value={selectedPackage || ''}
              defaultOptions={packageOptions}
              getOptionLabel={(e) => (
                <span>
                  {e.is_fulfilled_by_otoklix == 1 ? (
                    <span className="dropdown-tag">FBO</span>
                  ) : e.price_origin ? (
                    <span className="dropdown-tag">{e.price_origin}</span>
                  ) : null}
                  {e.label}
                  {!e.is_compatible && <PackageLineItems items={e} />}
                  {!e?.is_sellable && <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />}
                </span>
              )}
              getOptionValue={(e) => e.value}
              loadOptions={getPackage}
              onChange={handleChangePackage}
              invalid={!selectedPackage}
              errorMessage={errorPackage}
              placeholder="Pilih Paket..."
              cacheOptions={false}
              loading={loadingPackage}
              isOptionDisabled={(e) => !e?.is_sellable}
            />
          </FormGroup>
          <>{handleIsOpenModal()}</>
          {loadingPackageDetails ? (
            <FormGroup row>
              <Label lg={8}>Loading package details ...</Label>
            </FormGroup>
          ) : packageDetails?.length > 0 ? (
            map(packageDetails, (item, index) => (
              <FormGroup row>
                <Label lg={4} className="label-required">
                  {item?.component_label}
                </Label>
                <SelectField
                  id={`package-detail-${index}-edit`}
                  colWidth={8}
                  options={item?.products}
                  getOptionLabel={(e) => (
                    <span>
                      {e.is_fbo && <span className="dropdown-tag">FBO</span>}
                      {e.name}
                      {!e.is_compatible && <PackageLineItems items={e} />}
                    </span>
                  )}
                  getOptionValue={(e) => e.product_id}
                  onChange={(e) => handleChangePackageDetail(e, index)}
                  value={item?.value}
                  placeholder={item?.prompt}
                  disabled={disableAll}
                />
              </FormGroup>
            ))
          ) : null}
          {selectedUser?.isB2B && (
            <>
              <FormGroup row>
                <Label lg={4}>Keterangan</Label>
                <InputField
                  colWidth={8}
                  placeholder="Keterangan"
                  value={packageInformation}
                  onChange={(e) => handleChangePackageInformation(e?.target?.value)}
                  disabled={false}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Qty Paket</Label>
                <InputField
                  colWidth={8}
                  placeholder="Qty Paket"
                  value={`${Helper.numberWithCommas(packageQty)}`}
                  onChange={(e) => handlePackageQty(e?.target?.value)}
                  disabled={false}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>B2B Package Price</Label>
                <InputField
                  colWidth={8}
                  placeholder="B2B Package Price"
                  value={`IDR ${Helper.numberWithCommas(b2bPackagePrice ?? 0)}`}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Package Nett Price</Label>
                <InputField
                  colWidth={8}
                  placeholder="Package Nett Price"
                  value={`IDR ${Helper.numberWithCommas(packageNetPrice ?? 0)}`}
                  disabled={true}
                />
              </FormGroup>
            </>
          )}
          <FormGroup row>
            <Label lg={4}>Kode Promo</Label>
            <InputField
              colWidth={6}
              placeholder="promo code"
              value={promoCode}
              onChange={(e) => handleChangePromoCode(e?.target?.value)}
              disabled={disableAll || !selectedPackage}
              valid={!!promostatus && promostatus?.color == 'success'}
              invalid={!!promostatus && promostatus?.color == 'danger'}
              errorMessage={promostatus?.color == 'danger' ? promostatus?.message : ''}
            />
            <Col lg={2}>
              <Button
                id="create-booking"
                color="purple"
                onClick={checkPromoCode}
                disabled={disableAll || !selectedPackage}
                className="w-100"
              >
                Pakai
              </Button>
            </Col>
            <Col lg={12} className="mt-2">
              {promoData?.campaign_id && (
                <span>
                  Detail campaign <span className="promo-name">{promoData?.campaign_name ?? '-'}</span> dapat diakses
                  disini{' '}
                  <span>
                    <Link to={`/campaigns/campaign/detail/${promoData?.campaign_id ?? 411}`} target="_blank">
                      <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                    </Link>
                  </span>
                </span>
              )}
            </Col>
          </FormGroup>
        </div>
        <div className="action mb-3 mt-3">
          <Button
            id="package-edit-cancel"
            className="button-cancel"
            onClick={cancel}
            disabled={disableAll || loadingPackage || loadingPackageDetails}
          >
            Batal
          </Button>
          <Button
            id="package-edit-save"
            className="button-action"
            onClick={handleSubmit}
            disabled={disableAll || loadingPackage || loadingPackageDetails}
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BookingDetailPackageEditForms;
